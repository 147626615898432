import React from "react";
// import PropTypes from "prop-types";
import loadingAnim from "../assets/animations/loading.gif";

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(255,255,255,0.8)",
        position: "fixed",
        zIndex: 9999,
      }}
      data-testid="loading"
    >
      <img src={loadingAnim} alt="" style={{}} />
    </div>
  );
};

// Loading.propTypes = {};
// Loading.defaultProps = {};

export default Loading;
