import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Styles from "./ChangesPreview.module.scss"
import { useTranslation } from 'react-i18next'
import { Button } from './LinkButton'
import { useLocation, useNavigate, unstable_usePrompt } from 'react-router-dom'

const ChangesPreview = ({ open, setOpen, data, changes, setChanges, i18n, onSave }) => {
    const { t } = useTranslation();
    const location = useLocation()
    const navigate = useNavigate()

    const [viewChanges, setViewChanges] = useState([])
    const [isDisabled, setIsDisabled] = useState(true)

    // unstable_usePrompt({
    //     message: "Are you sure?",
    //     when: ({ currentLocation, nextLocation }) =>
    //       "test" === 0 &&
    //       currentLocation.pathname !== nextLocation.pathname,
    //   });

    useEffect(() => {
        if (Object.keys(data).length > 0 && Object.keys(changes).length > 0) {
            const _changes = []
            for (let s in changes) {
                if (typeof changes[s] === "object") {
                    for (let k in changes[s]) {
                        if (changes[s][k] !== data[s][k]) {
                            _changes.push({ label: s + "." + k, old: data[s][k], new: changes[s][k] })
                        }
                    }
                } else {
                    if (changes[s] !== data[s]) {
                        _changes.push({ label: s, old: data[s], new: changes[s] })
                    }
                }
            }
            setViewChanges(_changes)
        }

    }, [data, changes])
    
    useEffect(() => {
        if (viewChanges.length === 0) {
            setOpen(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewChanges])

    useEffect(() => {
        if (open) {
            const timeout = setTimeout(() => {
                setIsDisabled(false)
            }, 1000)
            return () => clearTimeout(timeout)
        } else {
            setIsDisabled(true)
        }
    }, [open])

    const handleRevertChange = (change) => {
        setChanges({ field: change.field, value: change.old })
    }

    if (!open || viewChanges.length === 0) return <React.Fragment />
    else
        return (
            <React.Fragment>
                <div className={Styles.modalBackground}>
                    <div className={Styles.modalContainer}>
                        <div className={Styles.modalHeader}>
                            <p className={Styles.title}>{t("frm-unsaved-changes")}</p>
                            <p className={Styles.desscription}>{t("frm-unsaved-changes-warning-prompt")}</p>
                        </div>
                        <div className={Styles.changesContainer}>
                            {viewChanges.map((change, index) => (
                                <div key={index} className={Styles.changeContainer}>
                                    <div className={Styles.changeHeader}>
                                        <p className={Styles.label}>{t(i18n[change.label])}</p>
                                        <p onClick={() => handleRevertChange({ field: change.label, old: change.old })} className={Styles.revertChange}>{t("frm-revert-change")}</p>
                                    </div>
                                    <div className={Styles.change}>
                                        <p className={Styles.oldValue}>{change.old || "X"}</p>
                                        <i className="fa-solid fa-arrow-right-long text-primary" />
                                        <p className={Styles.newValue}>{change.new}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={Styles.footerMenu}>
                            {onSave && (
                                <Button onClick={() => onSave(changes)} disabled={isDisabled}>{t("frm-save-changes")}</Button>
                            )}
                            <Button onClick={() => setOpen(false)} variant={"outline"}>{t("frm-return-back")}</Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
}

ChangesPreview.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    changes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    setChanges: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    onSave: PropTypes.func,
}

export default ChangesPreview
