import React, { useContext, useState } from "react";
// import PropTypes from 'prop-types';
import LayoutMujUcet from "../Layouts/LayoutMujUcet.js";
import { useNavigate } from "react-router-dom";
// import { Button } from "../Components/LinkButton.js";
import UserContext from "../UserContext.js";
import { HttpPost } from "../HTTP.js";
import { tokenizeString } from "../Helpers/string-tokenizer.js";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Styles from "./PasswordChange.module.scss";
import { isEmpty } from "lodash";

const EmailChange = () => {
  const user = useContext(UserContext);
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [isValidating, setIsValidating] = useState(false);
  const [askValidationCode, setAskValidationCode] = useState(false);
  const [email, setEmail] = useState("");
  const [verification, setVerification] = useState("");
  const username = user.personalNumber;
  const currentEmail = user.email;

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleEmailChange();
    }
  };

  const handleEmailChange = () => {
    setIsValidating(true);

    HttpPost("changeEmail", { username, email }, false).then((resp) => {
      setIsValidating(false);

      if (resp.error_code !== 200) {
        MySwal.fire({
          text: t(`err-${tokenizeString(resp.error || "unknown")}`),
          icon: "error",
          confirmButtonText: t("login-error-ok-button"),
        });

        return false;
      }

      setAskValidationCode(true);
    });
  };

  const handleEmailChangeVerify = () => {
    if (isEmpty(verification)) {
      return false;
    }

    setIsValidating(true);

    HttpPost("changeEmail", { username, email, verification }, false)
      .then((resp) => {
        setIsValidating(false);

        if (resp.error_code !== 200) {
          MySwal.fire({
            text: t(`err-${tokenizeString(resp.error || "unknown")}`),
            icon: "error",
            confirmButtonText: t("login-error-ok-button"),
          });
          return false;
        }

        navigate("/muj-ucet");
        MySwal.fire({
          title: t("email-change-ok"),
          icon: "success",
          timer: 2000,
          toast: true,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      })
      .catch((_err) => {});
  };

  return (
    <LayoutMujUcet pageTitle={t("email-change-title")}>
      <section className={Styles.wrap}>
        <section className={Styles.passwordForm}>
          <section className={Styles.header}>
            <h1 className="title">{t("email-change-title")}</h1>
          </section>

          <div className="field">
            <label className="label">{t("email-change-current-address")}</label>
            <div className={"control has-icons-left"}>
              <input
                className="input"
                readOnly
                disabled
                type="text"
                value={currentEmail}
              />
              <span className="icon is-small is-left">
                <i className="fa-regular fa-envelope" />
              </span>
            </div>
          </div>

          <div className="field">
            <label className="label">{t("email-change-new-address")}</label>
            <div className={"control has-icons-left"}>
              <input
                className="input"
                disabled={isValidating || askValidationCode}
                type="text"
                value={email}
                placeholder={t("email-change-new-address")}
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={onInputKeyDown}
              />
              <span className="icon is-small is-left">
                <i className="fa-regular fa-envelope" />
              </span>
            </div>
          </div>

          {askValidationCode && (
            <div className="field">
              <label className="label">
                {t("email-change-validation-code")}
              </label>
              <div className={"control has-icons-left"}>
                <input
                  className="input"
                  disabled={isValidating}
                  type="text"
                  value={verification}
                  placeholder={t("email-change-validation-code")}
                  onChange={(e) => setVerification(e.target.value)}
                  onKeyDown={onInputKeyDown}
                />
                <span className="icon is-small is-left">
                  <i className="fa-regular fa-lock-alt" />
                </span>
              </div>
            </div>
          )}

          <div className="loginButton">
            <button
              className={`button is-rounded ${isValidating && " is-loading"}`}
              disabled={isValidating}
              onClick={
                askValidationCode ? handleEmailChangeVerify : handleEmailChange
              }
            >
              {t(
                askValidationCode
                  ? "login-buttonChangeEmailVerify"
                  : "login-buttonChangeEmail"
              )}
            </button>
          </div>
        </section>
      </section>
    </LayoutMujUcet>
  );
};

// EmailChange.propTypes = {}
// EmailChange.defaultProps = {}

export default EmailChange;
