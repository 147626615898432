import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

const LinkButton = ({
  isActive,
  isBlock,
  isSmall,
  children,
  to,
  externalLink,
  disabled,
}) => {
  if (externalLink) {
    return (
      <a
        key={`lbtn-ext-${to}`}
        href={to}
        target="_blank"
        rel="noreferrer"
        className={`button is-rounded${isBlock ? " is-block" : ""}`}
        data-testid="link-button-external"
        disabled={disabled}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      key={`lbtn-${to}`}
      to={to}
      className={`button is-rounded ${isBlock ? "is-block" : ""} ${isActive ? "is-active" : ""
        } ${isSmall ? "is-small" : ""}`}
      data-testid="link-button"
      disabled={disabled}
    >
      {children}
    </Link>
  );
};

LinkButton.propTypes = {
  isActive: PropTypes.bool,
  isSmall: PropTypes.bool,
  isBlock: PropTypes.bool,
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  externalLink: PropTypes.bool,
  disabled: PropTypes.bool,
};
LinkButton.defaultProps = {
  isActive: false,
  isSmall: false,
  isBlock: false,
  externalLink: false,
  disabled: false,
};

// eslint-disable-next-line react/prop-types
const Button = ({ isBlock, onClick, children, disabled, type, style, variant, className }) => {
  const variants = {
    filled: "button",
    outline: "button outline-button"
  }
  return (
    <button
      className={`is-rounded${isBlock ? " is-block" : ""} ${variants[variant]} ${className}`}
      style={{ fontWeight: "normal", ...style }}
      data-testid="basic-button"
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  isBlock: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]).isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(["submit", "button"]),
  variant: PropTypes.oneOf(["filled", "outlined"]),
  className: PropTypes.string,
};
Button.defaultProps = {
  isBlock: false,
  onClick: null,
  disabled: false,
  type: "button",
  variant: "filled",
  className: "",
};

export default LinkButton;
export { LinkButton, Button };
