import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import UserContext from "../UserContext.js";
import Styles from "./CardPayment.module.scss";

const CardPayment = () => {
  const { t } = useTranslation();
  const refForm = useRef();
  const { personalNumber, email, showAlert, showConfirm } =
    useContext(UserContext);
  const [cisloDa, setCisloDa] = useState("11111111");
  const [payAmmount, setPayAmmount] = useState("");
  const [payName, setPayName] = useState("");
  const [payEmail, setPayEmail] = useState("");
  const [isRepeatOrder, setIsRepeatOrder] = useState(null);
  const [isRepeatOrderConfirm, setIsRepeatOrderConfirm] = useState(false);
  const [error, setError] = useState({ field: null, msg: null });
  const { data: cardPaymentCfg } = useSWR(
    "valueStore/platba_min,platba_max,gpe_gw"
  );

  useEffect(() => {
    if (personalNumber) {
      setCisloDa(personalNumber);
    }

    if (email) {
      setPayEmail(email);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setError({ field: null, msg: null });

    if (!cisloDa) {
      setError({
        field: "cisloDa",
        msg: t("card-payment-error-cislo-da-empty"),
      });
      return;
    }

    if (!payAmmount) return;

    if (payAmmount < parseInt(cardPaymentCfg?.platba_min, 10)) {
      setError({
        field: "payAmmount",
        msg: t("card-payment-error-payAmmount-to-low"),
      });
      return;
    }

    if (payAmmount > parseInt(cardPaymentCfg?.platba_max, 10)) {
      setError({
        field: "payAmmount",
        msg: t("card-payment-error-payAmmount-to-high"),
      });
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cisloDa, payAmmount]);

  const handleCisloDaChange = (e) => {
    const newValue = e.target.value.replace(/[^\d]/g, "");
    setCisloDa(newValue);
  };

  const handlePayAmmountChange = (e) => {
    const newValue = e.target.value.replace(/[^\d]/g, "");
    setPayAmmount(newValue);
  };

  const handlePayNameChange = (e) => {
    const newValue = e.target.value;
    setPayName(newValue);
  };

  const handlePayEmailChange = (e) => {
    const newValue = e.target.value;
    setPayEmail(newValue);
  };

  const handleSetRepeatOrder = () => {
    setIsRepeatOrder(true);
    setIsRepeatOrderConfirm(false);
  };

  const handleSetOnceOrder = () => {
    setIsRepeatOrder(false);
  };

  const handleSubmit = (e) => {
    if (isRepeatOrder !== true && isRepeatOrder !== false) {
      showAlert(t("card-payment-error-isRepeatOrder-not-selected"));
      e.preventDefault();
      return;
    }

    if (cisloDa < 1000 || cisloDa > 9999999999) {
      showAlert(t("card-payment-error-cislo-da-empty"));
      e.preventDefault();
      return;
    }

    if (payAmmount < parseInt(cardPaymentCfg?.platba_min, 10)) {
      showAlert(t("card-payment-error-payAmmount-to-low"));
      e.preventDefault();
      return;
    }

    if (payAmmount > parseInt(cardPaymentCfg?.platba_max, 10)) {
      showAlert(t("card-payment-error-payAmmount-to-high"));
      e.preventDefault();
      return;
    }

    if (isRepeatOrder === true && payName === "") {
      showAlert(t("card-payment-error-payName-empty"));
      e.preventDefault();
      return;
    }

    if (isRepeatOrder === true && payEmail === "") {
      showAlert(t("card-payment-error-payEmail-empty"));
      e.preventDefault();
      return;
    }

    if (!isRepeatOrderConfirm && isRepeatOrder === true) {
      // show popup

      showConfirm(
        t("card-payment-repeatOrder-confirm"),
        () => {
          setIsRepeatOrderConfirm(true);
          setTimeout(() => {
            refForm.current.submit();
          }, 50);
        },
        "question"
      );

      e.preventDefault();
    }
  };

  return (
    <div className={Styles.wrap}>
      <form
        ref={refForm}
        onSubmit={handleSubmit}
        action={cardPaymentCfg?.gpe_gw}
        method="post"
      >
        <div className={Styles.paymentTypeSelect}>
          <label className="label">{t("card-payment-type")}</label>

          <label className="checkbox">
            <input
              type="checkbox"
              name="IsRepeatOrder"
              value="True"
              checked={isRepeatOrder === true}
              onChange={handleSetRepeatOrder}
            />
            {t("card-payment-type-recurrent")}
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              name="IsRepeatOrder"
              value="False"
              checked={isRepeatOrder === false}
              onChange={handleSetOnceOrder}
            />
            {t("card-payment-type-once")}
          </label>
        </div>

        <div className={Styles.fieldsBlock}>
          {(isRepeatOrder === true || isRepeatOrder === false) && (
            <div className={`field ${Styles.field}`}>
              <label className="label">{t("andelske-cislo")}</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  className={`input ${
                    error?.field === "cisloDa" ? "is-danger" : ""
                  }`}
                  name="AngelNumber"
                  type="number"
                  placeholder={t("andelske-cislo")}
                  value={cisloDa}
                  onChange={handleCisloDaChange}
                />
                <span className="icon is-small is-left">
                  <i className="fa-regular  fa-user"></i>
                </span>
              </div>

              {error?.field === "cisloDa" ? (
                <p className="help is-danger">{error?.msg}</p>
              ) : (
                <></>
              )}
            </div>
          )}

          {(isRepeatOrder === true || isRepeatOrder === false) && (
            <div className={`field ${Styles.field}`}>
              <label className="label">{t("card-payment-amount")}</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  className={`input ${
                    error?.field === "payAmmount" ? "is-danger" : ""
                  }`}
                  name="Amount"
                  type="number"
                  placeholder={t("card-payment-amount")}
                  value={payAmmount}
                  onChange={handlePayAmmountChange}
                />
                <span className="icon is-small is-left">
                  <i className="fa-regular  fa-money-bill-wave"></i>
                </span>
                <span className="icon is-small is-right">{t("currency")}</span>
              </div>
              {error?.field === "payAmmount" ? (
                <p className="help is-danger">{error?.msg}</p>
              ) : (
                <p className="help">&nbsp;</p>
              )}
            </div>
          )}
        </div>

        <div className={Styles.fieldsBlock}>
          {isRepeatOrder === true && (
            <div className={`field ${Styles.field}`}>
              <label className="label">{t("card-payment-name")}</label>
              <div className="control has-icons-left">
                <input
                  className={`input ${
                    error?.field === "payName" ? "is-danger" : ""
                  }`}
                  name="CardholderName"
                  type="text"
                  placeholder={t("card-payment-name")}
                  value={payName}
                  onChange={handlePayNameChange}
                />
                <span className="icon is-small is-left">
                  <i className="fa-regular  fa-address-card"></i>
                </span>
              </div>
              {error?.field === "payName" ? (
                <p className="help is-danger">{error?.msg}</p>
              ) : (
                <p className="help">&nbsp;</p>
              )}
            </div>
          )}

          {isRepeatOrder === true && (
            <div className={`field ${Styles.field}`}>
              <label className="label">{t("card-payment-email")}</label>
              <div className="control has-icons-left">
                <input
                  className={`input ${
                    error?.field === "payEmail" ? "is-danger" : ""
                  }`}
                  name="CardholderEmail"
                  type="email"
                  placeholder={t("card-payment-email")}
                  value={payEmail}
                  onChange={handlePayEmailChange}
                />
                <span className="icon is-small is-left">
                  <i className="fa-regular  fa-envelope"></i>
                </span>
              </div>
              {error?.field === "payEmail" ? (
                <p className="help is-danger">{error?.msg}</p>
              ) : (
                <p className="help">&nbsp;</p>
              )}
            </div>
          )}
        </div>

        <input type="hidden" name="Currency" value="203" />

        <div className={Styles.submit}>
          <div className="control">
            <button className="button is-rounded" type="submit">
              {t("card-payment-submit")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CardPayment;
