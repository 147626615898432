import React, { useState } from "react";
//import PropTypes from "prop-types";
import LayoutClean from "../Layouts/LayoutClean.js";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { HttpUpload } from "../HTTP.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormJson from "../Components/FormJson.js";
import Loading from "../Components/Loading.js";
import { Button } from "../Components/LinkButton.js";
import { selfUrl, webLink } from "../appConfig.js";
import Styles from "./RecipientRequestForm.module.scss";

const RecipientRequestFormEdit = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const { data: formFilesCategories } = useSWR(`recipientRequestFormFiles`);
  const { data: recipientFormId, isValidating: recipientFormIdLoading } =
    useSWR(`recipientRequestFormGetId/${id}`);

  // console.log({ formFilesCategories, recipientFormId, recipientFormIdLoading });

  const handleSubmit = (_data, files) => {
    if (files.length < 1) {
      MySwal.fire({
        title: t("frm-notification-save-error"),
        text: t(`recipient-request-form-save-error-no-files`),
        icon: "error",
        timer: 30000,
        position: "center",
        timerProgressBar: true,
        showConfirmButton: true,
      });
      return false;
    }

    setLoading(true);

    HttpUpload(`recipientRequestFormFiles/${recipientFormId}`, files)
      .then((resp) => {
        const hasError = resp?.error || false;
        // console.log("upload", { recipientFormId, resp });

        setLoading(false);

        if (hasError) {
          MySwal.fire({
            title: t("frm-notification-save-error"),
            text: t(`recipient-request-form-save-error-${hasError}`),
            icon: "error",
            timer: 30000,
            position: "center",
            timerProgressBar: true,
            showConfirmButton: true,
          });
          return false;
        }

        MySwal.fire({
          title: t("recipient-request-form-saved"),
          icon: "success",
          timer: 2000,
          toast: true,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        setSubmitted(true);
      })
      .catch((err) => {});
  };

  // console.log({ params, id, recipientFormId });

  if (recipientFormIdLoading) {
    return (
      <div style={{ height: "100vh" }}>
        <Loading />
      </div>
    );
  }

  if (!recipientFormId) {
    return (
      <LayoutClean pageTitle={t("recipient-request-form-edit")}>
        <div className={Styles.wrap}>
          <div style={{ textAlign: "center" }}>
            {t("recipient-request-form-edit-expired")}
          </div>

          <div className={Styles.successBtn}>
            <Button
              onClick={() => {
                window.location.href = webLink;
              }}
            >
              {t("header-backToWeb")}
            </Button>
          </div>
        </div>
      </LayoutClean>
    );
  }

  if (isSubmitted) {
    const editLink = `${selfUrl}dotaznik-editace/${id ? id : ""}`;

    return (
      <LayoutClean pageTitle={t("recipient-request-form-edit")}>
        <div className={Styles.wrap}>
          <section>{t("recipient-request-form-thank-you")}</section>
          <section style={{ margin: "2em 0", textAlign: "center" }}>
            <a href={editLink} target="_blank" rel="noreferrer">
              <code style={{ padding: "1em" }}>{editLink}</code>
            </a>
          </section>

          <div className={Styles.successBtn}>
            <Button
              onClick={() => {
                window.location.href = webLink;
              }}
            >
              {t("header-backToWeb")}
            </Button>
          </div>
        </div>
      </LayoutClean>
    );
  }

  return (
    <LayoutClean pageTitle={t("recipient-request-form-edit")}>
      <div className={Styles.wrap}>
        <section>
          <FormJson
            blueprint={[]}
            allowFiles={true}
            fileCategories={formFilesCategories || null}
            allowedFileTypes={[
              "image/jpeg",
              "image/png",
              "image/x-ms-bmp",
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ]}
            allowedFileSize={5 * 1024 * 1024}
            onSubmit={handleSubmit}
            disableSubmit={isLoading}
          />
        </section>
      </div>
    </LayoutClean>
  );
};

RecipientRequestFormEdit.propTypes = {};

export default RecipientRequestFormEdit;
