import React, { useEffect } from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import LoadingIcon from "./LoadingIcon.js";

const PaymentsSent = ({ limit, hasMore }) => {
  const { data, isValidating } = useSWR("getSentPayments/");
  const { t, i18n } = useTranslation();
  // console.log({ data });

  useEffect(() => {
    if (data && limit) {
      const dataSize = data.length;

      if (dataSize > limit) {
        if (hasMore) hasMore(true);
      }
    }
  }, [data, limit, hasMore]);

  if (isValidating) {
    return (
      <div>
        <LoadingIcon />
        {t("loading")}
      </div>
    );
  }

  return (
    <div className="table-container">
      <table className="table is-striped is-hoverable is-narrow">
        <thead>
          <tr>
            <th className="has-text-right">
              {t("payment-redistributionDate")}
            </th>
            <th>{t("payment-ammount")}</th>
            <th></th>
            <th>{t("payment-recipientName")}</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              if (limit > 0 && index >= limit) return false;

              return (
                <tr key={index}>
                  <th className="has-text-right">
                    {dayjs(item.RedistributionDate).format(t("date-full"))}
                  </th>
                  <td className="has-text-right">
                    {Intl.NumberFormat(i18n && i18n.language, {
                      style: "currency",
                      currency: "CZK",
                    }).format(item.TotalAmount.Amount)}
                  </td>

                  <td>
                    {item.TotalAmount.Amount < 0 && (
                      <span
                        className="paymentIcon tooltip top"
                        data-tooltip={t("paymentRemovedReason")}
                      >
                        <i className="fa-regular fa-circle-info" />
                      </span>
                    )}
                  </td>

                  <td className="">
                    <Link to={`/profil-rodiny/${item.Recipient.Id}`}>
                      {item.Recipient.Title ? item.Recipient.Title + " " : ""}
                      {item.Recipient.FirstName
                        ? item.Recipient.FirstName + " "
                        : ""}
                      {item.Recipient.LastName}
                    </Link>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

PaymentsSent.propTypes = {
  hasMore: PropTypes.func,
  limit: PropTypes.number,
};
PaymentsSent.defaultProps = {
  hasMore: null,
  limit: null,
};

export default PaymentsSent;
