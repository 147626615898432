import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { HttpGet } from "./HTTP.js";

const resources = {
  cs: { translation: require("./localize/cs.json") },
  en: { translation: require("./localize/en.json") },
};

const fallbackLng = ["cs"];
const availableLanguages = ["cs", "en"];

const onMissingKey = (_lngs, _namespace, key, _res) => {
  // ! saveMissing in i18next config must be true for this to work !
  if (window.language === "cs") {
    HttpGet(`i18nMissing/${key}`);
  }
};

const onLanguageChange = (lng) => {
  window.language = lng;
};

i18n
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .on("missingKey", onMissingKey)
  .on("languageChanged", onLanguageChange)
  .init({
    resources,
    fallbackLng,
    whitelist: availableLanguages,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: false,
    saveMissing: true,
  });

export default i18n;
