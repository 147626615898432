import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import UserContext from "../UserContext.js";
import { Button } from "./LinkButton";
import useSWR from "swr";
import FormInput from "../Components/FormInput.js";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Styles from "./UploadDrawer.module.scss";
import { HttpPost } from "../HTTP";
import { filesizeFormat } from "../Helpers/filesize.js";
import LoadingIcon from "../Components/LoadingIcon.js";

const swalConf = {
  timer: 2000,
  toast: true,
  position: "center",
  timerProgressBar: true,
  showConfirmButton: false,
};

const limitedFileTypes = [7, 14, 6, 10];

const UploadDrawer = ({
  isOpen,
  onClose,
  files,
  maxFileSize = 10,
  onChange,
  fileTypesDescription = "",
  allowedFileTypes = [],
  onFileUpload,
  maxGroupFileSize,
  customTypes,
  groupFileSize = 0,
  useLocalSave = false,
}) => {
  const [file, setFile] = useState(null);
  const fullUser = useContext(UserContext);
  const user = fullUser.fullUser || {};
  const MySwal = withReactContent(Swal);
  const { t } = useTranslation();
  const fileSize = filesizeFormat(file?.size || 0);
  const [isLoading, setLoading] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState(
    customTypes.length === 1 ? customTypes[0].value : null
  );
  const { data: docType } = useSWR("/enums/documentProcType");

  const documentTypes = customTypes
    ? customTypes
    : docType &&
      docType
        .map((type) => ({
          value: type.value,
          label: type.label,
        }))
        .filter((type) => type.value !== 13);
  if (!isOpen) return <></>;

  const validateFile = (file) => {
    const maxSize = maxFileSize * 1024 * 1024;

    const isFileTypeValid =
      allowedFileTypes.length === 0 || allowedFileTypes.includes(file.type);

    if (file && file.size <= maxSize && isFileTypeValid) {
      return true;
    } else {
      return false;
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (
      maxGroupFileSize &&
      groupFileSize + file.size / 1024 / 1024 > maxGroupFileSize
    ) {
      MySwal.fire({
        title: t("frm-file-size-exceeded"),
        icon: "error",
        timer: 2000,
        toast: true,
        position: "center",
        timerProgressBar: true,
        showConfirmButton: false,
        zIndex: 9999,
      });
      return;
    }

    if (file && validateFile(file, allowedFileTypes)) {
      setFile(file);
    } else {
      alert(
        `${t("upload-files-incorrect-format-or-size", {
          fileSize: `${maxFileSize}MB`,
        })}`
      );
      // alert(
      //   `${t("upload-files-incorrect-format-or-size")} (${maxFileSize}MB).`
      // );
    }
  };

  const handleClose = () => {
    setFile(null);
    onClose();
  };

  function truncateFileName(name, maxLength = 25) {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  }

  const handleSelectChange = (e) => {
    const docValue = e.target.value;
    setSelectedDocType(docValue);
  };

  const handleUpload = () => {
    setLoading(true);
    if (file) {
      if (useLocalSave) {
        if (!selectedDocType) return;
        const documentTypeLabel =
          docType.find((type) => type.value === parseInt(selectedDocType))
            .label || "";
        onFileUpload({
          personId: user.Id,
          documentType: selectedDocType,
          file: file,
          documentTypeLabel: documentTypeLabel,
        });
        setFile(null);
        setSelectedDocType(null);
        setLoading(false);
        onClose();
        return;
      }
      const formData = new FormData();
      formData.append("Id", user.Id);
      formData.append("File", file);
      formData.append("DocumentType", selectedDocType);
      HttpPost(
        `recipientRequestFormFileUpload`,
        formData,
        false,
        "multipart/form-data"
      ).then((response) => {
        if (response.error_code !== 200) {
          MySwal.fire({
            title: t("frm-file-upload-failed"),
            icon: "error",
            ...swalConf,
          });
        } else {
          MySwal.fire({
            title: t("frm-file-uploaded-successfully"),
            icon: "success",
            ...swalConf,
          });
          if (!selectedDocType) return;
          const documentTypeLabel =
            docType.find((type) => type.value === parseInt(selectedDocType))
              .label || "";

          onFileUpload &&
            onFileUpload({
              personId: user.Id,
              documentType: selectedDocType,
              file: file,
              documentTypeLabel: documentTypeLabel,
            });
        }
        onClose();
        setFile(null);
        setSelectedDocType("");
        setLoading(false);
      });
    }
  };

  return (
    <div className={Styles.drawerBackdrop}>
      {isLoading && (
        <div className={Styles.loading}>
          <LoadingIcon size={48} />
        </div>
      )}
      <div className={Styles.drawerContainer}>
        <div className={Styles.close}>
          <Button onClick={handleClose} className={"is-small"}>
            {t("upload-files-close")}
          </Button>
        </div>
        <div className={Styles.dndInput}>
          <input
            id="file"
            type="file"
            onChange={handleFileChange}
            accept=".jpg, .jpeg, .png, .bmp, .pdf"
          />
          <div className={Styles.uploadButtons}>
            <i
              className="fa-regular fa-upload"
              onClick={() => document.getElementById("file").click()}
              style={{ fontSize: 50 }}
            />
            <Button
              disabled={file !== null}
              onClick={() => document.getElementById("file").click()}
            >
              {t("upload-files-upload-file")}
            </Button>
          </div>
          <div className={Styles.allowedTypes}>
            {fileTypesDescription && (
              <p>
                <b>{t("prijemce-dokumenty-formats-description")}:</b>{" "}
                {fileTypesDescription}
              </p>
            )}
            <p>
              <b>
                {t("prijemce-dokumenty-upload-description", {
                  fileSize: `${maxFileSize}MB`,
                })}
              </b>
              {/* {maxFileSize}
              MB */}
            </p>
          </div>
        </div>
        <div className={Styles.files}>
          <p className={Styles.filesHeader}>
            {t("upload-files-uploaded-file")}
          </p>
          <div className={Styles.filesContainer}>
            {file && (
              <section className={Styles.uploadedFile}>
                <ul>
                  <li>
                    <b>{t("upload-files-name")}</b>{" "}
                    {truncateFileName(file.name)}
                  </li>
                  <li>
                    <b>{t("upload-files-size")}</b> {fileSize}
                  </li>
                  <li>
                    <FormInput
                      name="documentType"
                      type="select"
                      label={`${t("frm-document-type")}:`}
                      options={[
                        {
                          value: null,
                          label: t("upload-files-choose"),
                        },
                        ...documentTypes,
                      ]}
                      isRequired={true}
                      value={selectedDocType}
                      disabled={customTypes.length === 1}
                      onChange={handleSelectChange}
                      style={{ height: 300, marginTop: 10 }}
                    />
                  </li>
                </ul>
              </section>
            )}
            {file && (
              <Button
                className={Styles.sendButton}
                onClick={handleUpload}
                disabled={selectedDocType === null}
              >
                {t("upload-files-send-file")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

UploadDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customTypes: PropTypes.array,
  groupFileSize: PropTypes.number,
  maxGroupFileSize: PropTypes.number,
  files: PropTypes.array.isRequired,
  maxFileSize: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  fileTypesDescription: PropTypes.string,
  onFileUpload: PropTypes.func,
  allowedFileTypes: PropTypes.array,
  useLocalSave: PropTypes.bool,
  useFormTypes: PropTypes.bool,
};

UploadDrawer.defaultProps = {
  groupFileSize: 0,
  useLocalSave: false,
  maxGroupFileSize: 0,
};

export default UploadDrawer;
