import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import styles from "./RenderMd.module.scss";

const RenderMD = ({ children, className }) => {
  const { t } = useTranslation();

  const mdString = t(children);

  const anyLinkRegex = new RegExp("[(.*?)](http.*://.*)", "gi");
  const daLinkRegex = new RegExp("[(.*?)](http.*://.*.dobryandel.cz.*)", "gi");
  const hasLinks = anyLinkRegex.test(mdString);
  const hasIntLinks = daLinkRegex.test(mdString);

  return (
    <ReactMarkdown
      linkTarget={hasLinks ? (hasIntLinks ? "" : "_blank") : ""}
      className={`${styles.content} ${className ? className : ""}`}
    >
      {mdString}
    </ReactMarkdown>
  );
};

RenderMD.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
};

export default RenderMD;
