import React, { useContext, useState, useMemo, useEffect } from "react";
// import PropTypes from "prop-types";
import UserContext from "../UserContext.js";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HttpPost, HttpGet } from "../HTTP.js";
import { tokenizeString } from "../Helpers/string-tokenizer.js";
import dayjs from "dayjs";
import Styles from "./NPS.module.scss";
import Loading from "../Components/Loading.js";
import { Button } from "../Components/LinkButton.js";
import FeedbackForm from "../Components/FeedbackForm.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const NPS = () => {
  const navigate = useNavigate();
  const { isLoggedIn, fullUser, updateUserData } = useContext(UserContext);
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const [queryParam] = useSearchParams();

  const emlid = queryParam.get("emlid") || null;
  const [pageReady, setPageReady] = useState(false);
  const [rating, setRating] = useState(0);
  const [isSaved, setIsSaved] = useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [ratingHover, setRatingHover] = useState(null);
  const [npsData, setNpsData] = useState({
    Value: 0,
    Date: dayjs().subtract(1, "year").format(),
  });

  const nps = fullUser?.NPS || npsData;
  const npsJustSubmited = nps?.Date
    ? dayjs().subtract(1, "minutes").isBefore(dayjs(nps.Date))
    : false;
  const stars = useMemo(() => Array.from({ length: 10 }, (v, i) => i + 1), []);

  const fetchNPS = () => {
    return new Promise((resolve, reject) => {
      HttpGet(`nps?emlid=${emlid}`)
        .then((resp) => {
          if (resp?.error_code !== 200) {
            reject(resp?.respose);
            return;
          }

          resolve(resp?.response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  useEffect(() => {
    if (!isLoggedIn) {
      fetchNPS()
        .then((resp) => {
          setNpsData(resp);
          setPageReady(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (isLoggedIn) {
      setPageReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (npsJustSubmited) {
      setRating(nps?.Value);
      setIsSaved(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [npsJustSubmited]);

  const RenderFeedbackForm = () => {
    return (
      <div className={Styles.feedbackWrap}>
        <FeedbackForm
          to="dobryandel@dobryandel.cz"
          subject="NPS - formulář"
          fields={[
            {
              id: "vzkaz",
              title: "Vzkaz",
              description: "",
              required: true,
              multiline: true,
              itemType: "text",
            },
          ]}
        />
      </div>
    );
  };

  const handleVoteSave = () => {
    MySwal.fire({
      title: t("working"),
      showCloseButton: false,
      showConfirmButton: false,
    });
    MySwal.showLoading();

    HttpPost("nps", { rating, emlid })
      .then((resp) => {
        const errorCode = resp && resp.error_code;

        if (errorCode !== 200) {
          MySwal.fire({
            text: t(`nps-error-${tokenizeString(resp && resp.error)}`),
            icon: "error",
            confirmButtonText: t("button-ok"),
          });

          return false;
        }

        setIsSaved(true);
        updateUserData();
        MySwal.fire({
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });

        navigate("/muj-ucet/nps");
      })
      .catch((_ex) => {
        MySwal.fire({
          text: t(`unhandled-error`),
          icon: "error",
          confirmButtonText: t("button-ok"),
        });
      });
  };

  if (!pageReady) return <Loading />;

  return (
    <>
      <div className={Styles.wrap}>
        <section className={Styles.header}>
          <h1 className="title">{t("nps-form-title")}</h1>
        </section>

        <div className={Styles.title}>{t("nps-titleInfo")}</div>

        <div className={Styles.ratingWrap}>
          <div className={Styles.ratingPre}>{t("nps-vote-negative")}</div>
          <div className={Styles.rating}>
            {stars.map((star) => (
              <i
                key={star}
                className={`fa-solid fa-heart ${
                  star <= rating ? Styles.active : ""
                } ${star <= ratingHover ? Styles.hover : ""}`}
                onMouseOverCapture={() => {
                  isSaved === false && setRatingHover(star);
                }}
                onMouseLeave={() => {
                  setRatingHover(null);
                }}
                onClick={() => {
                  isSaved === false && setRating(star);
                }}
              />
            ))}
          </div>
          <div className={Styles.ratingPost}>{t("nps-vote-positive")}</div>
        </div>

        {isSaved === false && (
          <div className={Styles.submit}>
            <Button onClick={handleVoteSave}>{t("nps-vote-save")}</Button>
          </div>
        )}

        {isSaved === false && (
          <div className={Styles.footnote}>
            {t("nps-vote-last", {
              date: dayjs(nps?.Date).format(t("date-full")),
              rating: nps?.Value,
            })}
          </div>
        )}
      </div>

      {isSaved === true && (
        <div className={Styles.thankyou}>
          <div className="title is-size-4">{t("nps-vote-thankyou")}</div>

          {rating <= 6 && <div>{t("nps-vote-thankyou-feedback-1-6")}</div>}

          {rating > 6 && rating <= 8 && (
            <div>{t("nps-vote-thankyou-feedback-7-8")}</div>
          )}

          {rating > 8 && <div>{t("nps-vote-thankyou-feedback-9-10")}</div>}

          <div className={Styles.spacer}></div>

          {showFeedbackForm || rating <= 6 ? (
            <RenderFeedbackForm />
          ) : (
            <Button
              onClick={() => {
                setShowFeedbackForm(true);
              }}
            >
              {t("feedback-form-show")}
            </Button>
          )}
        </div>
      )}
    </>
  );
};

// NPS.propTypes = {};
// NPS.defaultProps = {};

export default NPS;
