import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Loading from "./Loading.js";
import ImageLightbox from "./ImageLightbox.js";
import { apiImageUrl } from "../appConfig.js";
import avatarPlaceholder from "../assets/images/defaultAvatar_family.png";
import styles from "./FamilyStory.module.scss";

import { HttpGet } from "../HTTP.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const getFullName = (story) => {
  return `${story.Title ? story.Title : ""} ${
    story.FirstName ? story.FirstName : ""
  } ${story.LastName ? story.LastName : ""}`.trim();
};

const getPatientFullName = (story) => {
  return `${story.PatientTitle ? story.PatientTitle : ""} ${
    story.PatientFirstName ? story.PatientFirstName : ""
  } ${story.PatientLastName ? story.PatientLastName : ""}`.trim();
};
const getAvatar = (story) => {
  return (
    story.Images &&
    story.Images.reduce((retImg, image) => {
      if (!image.IsPrimary) return retImg;
      return image;
    }, null)
  );
};

const FamilyStory = ({ id, listAllIds }) => {
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const emptyContactsInfo = {
    Address: null,
    Emails: null,
    Phones: null,
  };

  const { data: story } = useSWR(`getRecipient/${id}/`);
  const [lightboxIdx, setLightboxIdx] = useState(false);
  const [lightboxTitle, setLightboxTitle] = useState(null);
  const [contactsAgreed, setContactsAgreed] = useState(false);
  const [contactsInfo, setContactsInfo] = useState(emptyContactsInfo);

  const lifeStory = story
    ? window && window.language === "en"
      ? story.LifeStoryEn
        ? story.LifeStoryEn
        : story.LifeStory
      : story.LifeStory
    : "";
  //console.log({ lang: window && window.language, lifeStory });

  useEffect(() => {
    if (lightboxIdx !== false && story) {
      const title = story.Images.reduce(
        (ret, item) => (item.Id === lightboxIdx ? item.Description : ret),
        null
      );
      setLightboxTitle(title);
    }
  }, [lightboxIdx, story]);

  useEffect(() => {
    if (story && story.Emails) {
      setContactsInfo((state) => {
        return { ...state, Emails: story.Emails };
      });
    }
  }, [story]);

  useEffect(() => {
    setContactsAgreed(false);
    setContactsInfo(emptyContactsInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadContactsDetails = () => {
    MySwal.fire({
      html: t("FamilyStory-contact-info"),
      icon: "info",
      showCancelButton: true,
      confirmButtonText: t("buttonAgree"),
      cancelButtonText: t("buttonCancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: t("loading"),
          timer: 10000,
          toast: true,
          position: "top-right",
          showConfirmButton: false,
        });
        Swal.showLoading();

        HttpGet(`getRecipientContact/${id}/`).then((resp) => {
          if (resp.error_code !== 200) {
            Swal.fire({
              icon: "error",
              text: t("FamilyStory-contact-load-error"),
            });
            return false;
          }

          setContactsAgreed(true);
          setContactsInfo(resp.response);
          setTimeout(() => {
            Swal.close();
          }, 1000);
          return;
        });
      }
    });
  };

  if (!story) {
    return (
      <div className={styles.familyStoryDetail}>
        <Loading />
      </div>
    );
  }

  const arrayPos = listAllIds && listAllIds.indexOf(id);
  const nextFamilyStory = listAllIds && listAllIds[arrayPos + 1];
  const prevFamilyStory = listAllIds && listAllIds[arrayPos - 1];
  const avatar = getAvatar(story);
  const moreImages = (story.Images ? story.Images : []).sort((a) =>
    a.IsPrimary ? -1 : 0
  );
  const fullName = getFullName(story);
  const patientFullName = `${t("FamilyStory-patient")}: ${getPatientFullName(
    story
  )}`;

  const handleCloseLightbox = () => {
    setLightboxIdx(false);
    setLightboxTitle("");
  };

  const handleLightboxNext = () => {
    const currIdx = moreImages.findIndex((img) => img.Id === lightboxIdx);
    const nextIdx = currIdx + 1;
    if (nextIdx < moreImages.length) {
      setLightboxIdx(moreImages[nextIdx].Id);
    }
  };

  const handleLightboxPrev = () => {
    const currIdx = moreImages.findIndex((img) => img.Id === lightboxIdx);
    const prevIdx = currIdx - 1;
    if (prevIdx >= 0) {
      setLightboxIdx(moreImages[prevIdx].Id);
    }
  };

  const getHasNext = () => {
    return (
      moreImages.findIndex((img) => img.Id === lightboxIdx) + 1 <
      moreImages.length
    );
  };

  const getHasPrev = () => {
    return moreImages.findIndex((img) => img.Id === lightboxIdx) > 0;
  };

  return (
    <section className={styles.familyStoryDetail}>
      {lightboxIdx && (
        <ImageLightbox
          img={`${apiImageUrl}${lightboxIdx}?maxWidth=1600&isWatermark=false`}
          alt={lightboxTitle}
          title={lightboxTitle}
          onClose={handleCloseLightbox}
          onNext={handleLightboxNext}
          hasNext={getHasNext}
          onPrev={handleLightboxPrev}
          hasPrev={getHasPrev}
        />
      )}

      <div className={styles.familyStoryDetailHeader}>
        <div className={styles.metabox}>
          <div className={styles.name}>
            {fullName === "demoRecipient" ? t("demo-recipient-name") : fullName}
          </div>
          <div className={styles.patientName}>{patientFullName}</div>
          <div className={styles.disease}>{t(story.TypeOfDisease)}</div>
        </div>

        <div className={styles.navbox}>
          {id !== "demo" && (
            <>
              <div className={`${styles.navigation} ${styles.prev}`}>
                {prevFamilyStory && (
                  <Link to={`/profil-rodiny/${prevFamilyStory}`}>
                    <i className={`fa fa-chevron-left`} />
                  </Link>
                )}
              </div>

              <div
                className={`${styles.navigation} ${styles.next} ${styles.isDesktop}`}
              >
                {nextFamilyStory && (
                  <Link to={`/profil-rodiny/${nextFamilyStory}`}>
                    <i className={`fa fa-chevron-right`} />
                  </Link>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.familyStoryDetailBody}>
        <div className={styles.image}>
          {avatar || (moreImages && moreImages.length > 0) ? (
            <div className={styles.main}>
              {avatar && avatar.Id ? (
                <img
                  src={`${apiImageUrl}${avatar.Id}?maxWidth=400&isWatermark=false`}
                  alt={avatar.Description}
                  title={avatar.Description}
                  onClick={() => setLightboxIdx(avatar.Id)}
                />
              ) : (
                <img src={avatarPlaceholder} alt={patientFullName} />
              )}
            </div>
          ) : null}
          {moreImages && moreImages.length > 0 && (
            <div className={styles.others}>
              {moreImages
                .filter((img) => (img.IsPrimary ? false : true))
                .map((image) => {
                  return (
                    <div key={image.Id} className={styles.imageWrap}>
                      <img
                        src={`${apiImageUrl}${image.Id}?maxWidth=100&isWatermark=false`}
                        alt={image.Description}
                        title={image.Description}
                        onClick={() => setLightboxIdx(image.Id)}
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <div
          className={styles.lifeStory}
          dangerouslySetInnerHTML={{
            __html: lifeStory,
          }}
        />
      </div>

      <div className={styles.familyStoryContacts}>
        <div className="title is-4">{t("FamilyStory-contact")}</div>

        {story.isDemo ? (
          <div>{t("FamilyStory-contact-demo-info")}</div>
        ) : (
          <>
            <div className={styles.contactsWrap}>
              {contactsInfo && contactsInfo.Address && (
                <div className={styles.address}>
                  <i className="fa-thin fa-map" />
                  <address>
                    {`${contactsInfo.Address.Street} ${contactsInfo.Address.HouseNumber}, ${contactsInfo.Address.PostalCode} ${contactsInfo.Address.City}`.trim()}
                  </address>
                </div>
              )}

              {contactsInfo && contactsInfo.Phones && (
                <div className={styles.phone}>
                  <i className="fa-thin fa-phone" />
                  {contactsInfo.Phones.map((phone) => (
                    <div key={phone.Id}>{phone.Value}</div>
                  ))}
                </div>
              )}

              {contactsInfo && contactsInfo.Emails && (
                <div className={styles.email}>
                  <i className="fa-thin fa-envelope" />
                  {contactsInfo.Emails.map((email) => (
                    <div key={email.Id}>{email.Value}</div>
                  ))}
                </div>
              )}
            </div>

            {!contactsAgreed && (
              <div className={styles.contactsInfoAgree}>
                <button
                  className="button is-rounded"
                  onClick={loadContactsDetails}
                >
                  {t("FamilyStory-contact-show")}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
};

const FamilyStorySimpleDemo = () => {
  const { data } = useSWR(`getRecipient/demo`);

  if (!data) {
    return (
      <div className={styles.familyStoryDetail}>
        <Loading />
      </div>
    );
  }

  return <FamilyStorySimple id={data.id} recipientData={data} />;
};

const FamilyStorySimple = ({ id, recipientData }) => {
  const { t } = useTranslation();

  if (!recipientData) {
    return (
      <div className={styles.familyStoryDetail}>
        <Loading />
      </div>
    );
  }

  const fullName = getFullName(recipientData);
  const patientFullName = `${t("FamilyStory-patient")}: ${getPatientFullName(
    recipientData
  )}`;

  const avatar = getAvatar(recipientData);
  return (
    <Link
      to={`/profil-rodiny/${fullName === "demoRecipient" ? "demo" : id}`}
      className={styles.familyStory}
    >
      <div className={styles.avatar}>
        {avatar && avatar.Id ? (
          <img
            src={`${apiImageUrl}${avatar.Id}?maxWidth=400&isWatermark=false`}
            alt={avatar.Description}
            title={avatar.Description}
          />
        ) : (
          <img src={avatarPlaceholder} alt={patientFullName} />
        )}
      </div>

      <div className={styles.textWrap}>
        <div className={styles.name}>
          {fullName === "demoRecipient" ? t("demo-recipient-name") : fullName}
        </div>
        <div className={styles.patientName}>{patientFullName}</div>
        <div className={styles.disease}>{recipientData.TypeOfDisease}</div>
      </div>

      <div className={styles.cta}>
        <i className={`fa fa-chevron-right ${styles.ctaIcon}`} />
      </div>
    </Link>
  );
};

FamilyStory.propTypes = {
  id: PropTypes.string,
  listAllIds: PropTypes.array,
};
FamilyStory.defaultProps = {
  id: "demo",
  listAllIds: null,
};

FamilyStorySimple.propTypes = {
  id: PropTypes.string,
  recipientData: PropTypes.object,
};
FamilyStorySimple.defaultProps = {
  id: "demo",
  recipientData: null,
};

export default FamilyStory;
export { FamilyStory, FamilyStorySimple, FamilyStorySimpleDemo };
