import slugify from "slugify";

const tokenizeString = (string) => {
  if (!string) return "";

  const newString = slugify(string, {
    replacement: "-", // replace spaces with replacement character, defaults to `-`
    lower: true, // convert to lower case, defaults to `false`
    strict: true, // strip special characters except replacement, defaults to `false`
    locale: "en", // language code of the locale to use
  });

  return newString;
};

export default tokenizeString;
export { tokenizeString };
