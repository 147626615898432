import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Style from "./LayoutClean.module.scss";

import ImgLogo from "../assets/logo.png";

const LayoutClean = ({ children, pageTitle }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {pageTitle ? pageTitle : t("header-defaultTitle")} |{" "}
          {t("dobry-andel")}
        </title>
      </Helmet>

      <section className={Style.wrapper}>
        <section className={Style.header}>
          <img src={ImgLogo} alt="" className={Style.logo} />
        </section>

        <section className={Style.content}>{children}</section>
      </section>
    </>
  );
};

LayoutClean.propTypes = {
  children: PropTypes.element.isRequired,
  pageTitle: PropTypes.string,
};
LayoutClean.defaultProps = {
  pageTitle: null,
};

export default LayoutClean;
