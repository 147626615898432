import React from "react";

const SwalIconSuccess = () => {
  return (
    <>
      <div
        className="swal2-icon swal2-success swal2-icon-show"
        style={{ display: "flex", marginBottom: "2em" }}
      >
        <div
          className="swal2-success-circular-line-left"
          style={{ backgroundColor: "rgb(255, 255, 255)" }}
        ></div>
        <span className="swal2-success-line-tip"></span>
        <span className="swal2-success-line-long"></span>
        <div className="swal2-success-ring"></div>
        <div
          className="swal2-success-fix"
          style={{ backgroundColor: "rgb(255, 255, 255)" }}
        ></div>
        <div
          className="swal2-success-circular-line-right"
          style={{ backgroundColor: "rgb(255, 255, 255)" }}
        ></div>
      </div>
    </>
  );
};

const SwalIconError = () => {
  return (
    <>
      <div
        className="swal2-icon swal2-error swal2-icon-show"
        style={{ display: "flex", marginBottom: "2em" }}
      >
        <span className="swal2-x-mark">
          <span className="swal2-x-mark-line-left"></span>
          <span className="swal2-x-mark-line-right"></span>
        </span>
      </div>
    </>
  );
};

export { SwalIconSuccess, SwalIconError };
