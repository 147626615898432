import React, { useState, useContext } from "react";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import UserContext from "../UserContext.js";
import { HttpPost } from "../HTTP.js";
import { tokenizeString } from "../Helpers/string-tokenizer.js";
import { useNavigate } from "react-router-dom";
//import dayjs from "dayjs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import RenderMd from "../Components/RenderMd.js";
import Styles from "./ConfirmOou.module.scss";

const PageConfirmOou = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setAccessToken, setUserObject } = useContext(UserContext);
  const MySwal = withReactContent(Swal);
  const [agree, setAgree] = useState(false);

  const [queryParam] = useSearchParams();
  const emlid = queryParam.get("emlid");

  const handleCheckAgree = () => {
    setAgree(!agree);
  };

  const handleSaveAgree = () => {
    MySwal.fire({
      title: t("working"),
      showCloseButton: false,
      showConfirmButton: false,
    });
    MySwal.showLoading();

    HttpPost("userProfile", {
      OOUValue: true,
      emlid,
    }).then((resp) => {
      const errorCode = resp && resp.error_code;

      if (errorCode !== 200) {
        MySwal.fire({
          text: t(`login-error-${tokenizeString(resp && resp.error)}`),
          icon: "error",
          confirmButtonText: t("login-error-ok-button"),
        });
        return;
      }
      // reload user profile and redirect to home page
      if (resp.response.scope === "donor") {
        setAccessToken(resp.response.token, resp.response.expire);
        setUserObject(
          resp.response.scope,
          resp.response.profile,
          resp.response.profile_full
        );
      }

      MySwal.fire({
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/");
    });
  };

  return (
    <LayoutBasic pageTitle={t("menu-muj-ucet")}>
      <section className={Styles.wrap}>
        <RenderMd>md-oou</RenderMd>

        <div className="has-text-centered">
          <label className="checkbox">
            <input type="checkbox" onClick={handleCheckAgree} />
            {t("page-oou-agree")}
          </label>
        </div>

        {agree && (
          <div className="has-text-centered">
            <button className="button" onClick={handleSaveAgree}>
              {t("frm-button-submit")}
            </button>
          </div>
        )}
      </section>
    </LayoutBasic>
  );
};

export default PageConfirmOou;
