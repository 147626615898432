import { apiImageUrl } from "../appConfig.js";
import defautAvatar from "../assets/images/defaultAvatar.png";
import defautAvatarMale from "../assets/images/defaultAvatar_male.png";
import defautAvatarFemale from "../assets/images/defaultAvatar_female.png";

const getUserAvatar = (user) => {
  if (!user) return defautAvatar;
  // if (user.avatar) return `${apiImageUrl}${user.avatar}`;

  // Why the hell? there is user.avatar url from API
  const image = user?.fullUser?.Images?.find((img) => img.IsPrimary);
  if (image) return `${apiImageUrl}${image.Id}?maxwidth=150&isWatermark=false`;

  if (user.gender === 1) return defautAvatarMale;
  if (user.gender === 2) return defautAvatarFemale;

  return defautAvatar; // fail-safe
};

export { getUserAvatar };
export default getUserAvatar;
