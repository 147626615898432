import React from "react";
//import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
// import Loading from "../Components/Loading.js";
import FamilyStory from "../Components/FamilyStory.js";

const ProfilRodiny = () => {
  //const id = match.params.id;
  const { id } = useParams();

  const { t } = useTranslation();

  const { data: list } = useSWR("getRecipientsByDonor/");
  const listAllIds =
    list &&
    list.map((item) => {
      return item && item.Recipient && item.Recipient.Id;
    });

  return (
    <LayoutBasic pageTitle={t("pageProfilRodiny-title")}>
      <div>
        <FamilyStory id={id} listAllIds={listAllIds} />
      </div>
    </LayoutBasic>
  );
};

ProfilRodiny.propTypes = {};

export default ProfilRodiny;
