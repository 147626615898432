/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import { useTranslation } from "react-i18next";
import { Link, Navigate } from "react-router-dom";
import Loading from "../Components/Loading.js";
import Page404 from "./404.js";

const Index = () => {
  const { t } = useTranslation();
  const redirLink = "/muj-ucet/profil";

  if (!redirLink) return <Page404 />;

  return (
    <LayoutBasic pageTitle={t("clickHereToRedirect")}>
      <>
        <Navigate to={redirLink} />

        <section style={{ marginTop: "100px" }}>
          <Loading />
        </section>
        <section style={{ textAlign: "center" }}>
          <Link to={redirLink}>{t("clickHereToRedirect")}</Link>
        </section>
      </>
    </LayoutBasic>
  );
};

export default Index;
