import React, { useContext } from "react";
import Styles from "./AdminLinkGenerator.module.scss";
import { useNavigate } from "react-router-dom";
import { HttpRawPost } from "../HTTP.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import UserContext from "../UserContext.js";

const AdminHome = () => {
  const { removeUserObject } = useContext(UserContext);
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const handleDeploy = () => {
    HttpRawPost(
      "https://api.cloudflare.com/client/v4/pages/webhooks/deploy_hooks/5f652f23-8216-42cb-995a-9c3fe6c983c7",
      {}
    )
      .then((resp) => {
        if (resp.error_code !== 200) {
          MySwal.fire({
            title: "Something went wrong...",
            icon: "error",
            timer: 15000,
            position: "center",
            timerProgressBar: true,
            showConfirmButton: true,
          });
          return false;
        }

        MySwal.fire({
          title: "Deploy started...",
          icon: "success",
          timer: 15000,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: true,
        });
      })
      .catch(() => {});
  };

  const handleLogout = () => {
    removeUserObject();
  };

  const handleNavigateLinkGenerator = () => {
    navigate("/admin/link-generator");
  };

  return (
    <div className={Styles.pageWrap}>
      <div className="columns mt-1 is-vcentered">
        <div className="column">
          <h1 className="title">Admin Tools</h1>
        </div>
        <div className="column">
          <button
            className="button is-link is-outlined is-pulled-right"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>

      <div className="columns mt-1 is-vcentered">
        <button
          className="button is-link is-outlined is-pulled-left"
          onClick={handleNavigateLinkGenerator}
        >
          Link generator
        </button>
      </div>

      <div className="columns mt-1 is-vcentered">
        <button
          className="button is-link is-outlined is-pulled-left"
          onClick={handleDeploy}
        >
          Start deploy
        </button>
      </div>
    </div>
  );
};

export default AdminHome;
