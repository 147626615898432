/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from "react";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import { useTranslation } from "react-i18next";
import UserContext from "../UserContext.js";
import { Link, Navigate } from "react-router-dom";
import Loading from "../Components/Loading.js";
import Page404 from "./404.js";

const getRedirLink = (user) => {
  const loginScope = user.loginScope;

  if (!loginScope) return "/login";
  if (loginScope === "oou") return "/zpracovani-osobnich-udaju";
  if (loginScope === "donor") return "/darce";
  if (loginScope === "recipient") return "/prijemce";

  return null;
};

const Index = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const redirLink = getRedirLink(user);

  if (!redirLink) return <Page404 />;

  return (
    <LayoutBasic pageTitle={t("clickHereToRedirect")}>
      <>
        <Navigate to={redirLink} />

        <section style={{ marginTop: "100px" }}>
          <Loading />
        </section>
        <section style={{ textAlign: "center" }}>
          <Link to={redirLink}>{t("clickHereToRedirect")}</Link>
        </section>
      </>
    </LayoutBasic>
  );
};

export default Index;
