import React, { useState } from "react";
import PropTypes from "prop-types";
import { isObject } from "lodash";
import { HttpPost } from "../HTTP.js";
import FormJson from "./FormJson.js";
import Style from "./FeedbackForm.module.scss";
import { t } from "i18next";

/**
 * It takes a list of fields and a subject and sends an email to the configured email address
 * @returns A function that returns a component
 * @var to E-mail address to send to
 * @var subject E-mail subject
 * @var fields array of variables from form to incliude in the e-mail
 */
const FeedbackForm = ({ to, subject, fields, showGdprNote }) => {
  const [submitState, setSubmitState] = useState(0); // 0, 1, 2

  const handleSubmit = (data, _files) => {
    if (!isObject(data)) {
      return false;
    }

    setSubmitState(1);
    let formData = [];
    //data.foreach((item, key) => {console.log({ key, item });});
    for (let [key, item] of Object.entries(data)) {
      const id = key.replace("formIdx-", "");
      const fieldTitle = fields.reduce(
        (ret, field) => (field.id === id ? field.title : ret),
        id
      );
      formData.push({ id, title: fieldTitle, value: item });
    }

    //console.log({ formData, data });
    /* Sending an email to the configured email address. */
    HttpPost(`emailForm`, { to: to, subject: subject, fields: formData })
      .then((resp) => {
        if (resp.error_code !== 200) {
          // show error
          setSubmitState(0);
          return true;
        }
        setSubmitState(2);
      })
      .catch(() => {
        setSubmitState(0);
      });
  };

  if (submitState === 2) {
    return (
      <div className={Style.wrap}>
        <div className={Style.successIcon}>
          <i className="fa-regular fa-envelope fa-beat" />
        </div>

        <div className={Style.successText}>{t("feedback-form-success")}</div>
      </div>
    );
  }

  return (
    <div className={Style.wrap}>
      <FormJson
        blueprint={fields}
        onSubmit={handleSubmit}
        disableSubmit={submitState !== 0}
        submitButtonLabel={t("feedback-form-submit")}
      />
      {showGdprNote && (
        <div className={Style.note}>
          <div className={Style.title}>{t("data-privacy-note-title")}</div>
          <div>{t("data-privacy-note")}</div>
        </div>
      )}
    </div>
  );
};

FeedbackForm.propTypes = {
  to: PropTypes.string,
  subject: PropTypes.string,
  fields: PropTypes.array,
  showGdprNote: PropTypes.bool,
};

FeedbackForm.defaultProps = {
  showGdprNote: false,
};

export default FeedbackForm;
