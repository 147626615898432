import React from "react";
import PropTypes from "prop-types";
import Styles from "./ContactContainer.module.scss";
import { Button } from "../Components/LinkButton";
import Tooltip from "../Components/Tooltip";
import { useTranslation } from "react-i18next";

const ContactContainer = ({
  nameHeader,
  birthDate,
  isMain,
  patientRelation,
  phoneNumber,
  email,
  schoolOrWork,
  isRelation13,
  hobbies,
  onClickDelete,
  onClickEdit,
  isPersonDisabled,
}) => {
  const { t } = useTranslation();

  const contactDetails = (
    <>
      <strong>{nameHeader}</strong>
      {phoneNumber && `, ${phoneNumber}`}
      {email && `, ${email}`}
    </>
  );

  return (
    <div className={Styles.contactContainer}>
      <div className={Styles.contactHeader}>
        <h1 className={Styles.contactTitle}>
          {isRelation13 && (
            <span className={Styles.contactName}>
              {t("frm-contact-close-person")}
            </span>
          )}
          {!isRelation13 && (
            <>
              <div className={Styles.person}>
                <span className={Styles.contactName}>{nameHeader}</span>
                <span className={Styles.birthDate}>
                  {" "}
                  ({t("frm-person-born-title")} {birthDate})
                </span>
              </div>
            </>
          )}
        </h1>
        <div className={Styles.contactButtons}>
          <Button
            className={Styles.editButton}
            onClick={onClickEdit}
            disabled={isPersonDisabled}
          >
            {t("frm-photo-edit-title")}
          </Button>
          <Button className={Styles.deleteButton} onClick={onClickDelete}>
            {t("frm-photo-delete")}
          </Button>
        </div>
      </div>
      <div className={Styles.contactInfo}>
        {isRelation13 ? (
          <p>{contactDetails}</p>
        ) : (
          <>
            <p className={Styles.isPrimary}>
              {/* <Tooltip
                content={t("frm-person-primary-explanation")}
                tooltipStyle={{ maxWidth: 300 }}
              > */}
              <span className={Styles.infoLabel}>
                {t("frm-person-primary")}:
              </span>{" "}
              {isMain ? "ano" : "ne"}
              <span
                className="tooltip top"
                data-tooltip={t("frm-person-primary-explanation")}
              >
                <i
                  className={`fa-solid fa-circle-question ${Styles.iconStyle}`}
                ></i>
              </span>
              {/* </Tooltip> */}
            </p>
            <p>
              <span className={Styles.infoLabel}>
                {t("frm-person-relation")}:
              </span>{" "}
              {patientRelation}
            </p>
            <p>
              <span className={Styles.infoLabel}>
                {t("frm-person-contacts")}:
              </span>{" "}
              {phoneNumber && email
                ? `${phoneNumber}, ${email}`
                : phoneNumber
                ? phoneNumber
                : email}
            </p>
            <p>
              <span className={Styles.infoLabel}>
                {t("frm-person-schoolOrWork")}:
              </span>{" "}
              {schoolOrWork}
            </p>
            <p>
              <span className={Styles.infoLabel}>
                {t("frm-person-hobbies")}:
              </span>{" "}
              {hobbies}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

ContactContainer.propTypes = {
  nameHeader: PropTypes.string,
  birthDate: PropTypes.string,
  isMain: PropTypes.bool,
  patientRelation: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  schoolOrWork: PropTypes.string,
  hobbies: PropTypes.string,
  isRelation13: PropTypes.bool.isRequired,
  onClickDelete: PropTypes.func,
  onClickEdit: PropTypes.func,
  isPersonDisabled: PropTypes.bool,
};

export default ContactContainer;
