import React, { Fragment, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import packInfo from "../../package.json";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import UserContext from "../UserContext.js";
import { getWebLink } from "../appConfig.js";
import RenderMD from "../Components/RenderMd.js";
import "./LayoutBasic.scss";
import NPS from "../Components/NPS.js";
import GlobalNotification from "../Components/GlobalNotification.js";
import DocumentNotification from "../Components/DocumentNotification.js";
import { Sling as Hamburger } from "hamburger-react";
import routes from "../routes.json";
import getUserAvatar from "../Helpers/avatar.js";
import ImgLogo from "../assets/logo.png";
import anonymousAvatar from "../assets/images/defaultAvatar.png";
import dayjs from "dayjs";
import LanguageSwitcher from "../Components/LanguageSwitcher.js";

const disabledRoutes = ["/prijemce/moje-udaje-kontakty"];

const blacklistedRoutesPerState = {
  1: [""],
  2: [""],
  3: ["/prijemce/nabidky-pomoci"],
  4: [""],
  5: [""],
  6: [""],
  7: [""],
  8: [""],
  9: [""],
};

const LayoutBasic = ({ children, pageTitle }) => {
  const [availableRoutes, setAvailableRoutes] = useState(routes);

  // const displayNameMaxChars = 40;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const user = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const { data: npsTrigger } = useSWR("valueStore/nps_showStart,nps_showEnd");

  const today = dayjs();
  const { pathname } = useLocation();
  const npsShowBanner =
    pathname !== "/muj-ucet/nps" &&
    today.isBefore(dayjs(npsTrigger?.nps_showEnd)) &&
    today.isAfter(dayjs(npsTrigger?.nps_showStart)) &&
    dayjs(user.fullUser?.NPS?.Date).isBefore(today.subtract(3, "months"));

  const recipientRoutes = () => {
    let _routes = routes.filter(
      (route) =>
        route.loginScope.includes(user.loginScope) || route.path === "/prijemce"
    );
    const blacklistedRoutes =
      blacklistedRoutesPerState[user.fullUser.RecipientStatusOfRequest.Id];
    if (blacklistedRoutes) {
      _routes = _routes.filter(
        (route) =>
          !blacklistedRoutes.includes(route.path) &&
          !disabledRoutes.includes(route.path)
      );
    } else {
      _routes = _routes.filter((route) => !disabledRoutes.includes(route.path));
    }

    if (
      !(
        user.fullUser.RecipientRequestStatus?.Id === 1 &&
        user.fullUser.RecipientStatusOfRequest?.Id !== 8 &&
        user.fullUser.RecipientStatusOfRequest?.Id !== 9
      )
    ) {
      _routes = _routes.filter(
        (route) => route.path !== "/prijemce/obnoveni-zadosti"
      );
    }

    if (user.fullUser.RecipientStatus?.Id === 3) {
      _routes = _routes.filter(
        (route) => route.path !== "/prijemce/nabidky-pomoci"
      );
    }

    setAvailableRoutes(_routes);
    // // RECIPIENT ACTIVE
    // if (user.fullUser.RecipientStatusOfRequest.Id === 1) {
    //   // IN PROCESS OF REFRESH
    //   if (user.fullUser.RecipientStatusOfRequest.Id === 2) {
    //     _routes = routes.filter(
    //         (route) =>
    //           route.loginScope.includes(user.loginScope) ||
    //           route.path === "/prijemce"
    //       )

    //   } else {
    //     _routes = routes.filter(
    //         (route) =>
    //           (route.loginScope.includes(user.loginScope) ||
    //             route.path === "/prijemce") &&
    //           route.path !== "/prijemce/obnoveni-zadosti"
    //       )

    //   }
    // }
    // // RECIPIENT INACTIVE
    // else if (user.fullUser.RecipientStatusOfRequest.Id === 2) {
    //   // IN PROCESS OF REFRESH
    //   if (user.fullUser.RecipientStatusOfRequest.Id === 2) {
    //     _routes = routes.filter(
    //         (route) =>
    //           (route.loginScope.includes(user.loginScope) ||
    //             route.path === "/prijemce") &&
    //           route.path !== "/prijemce/nabidky-pomoci"
    //     )
    //   } else {
    //     _routes = routes.filter(
    //         (route) =>
    //           (route.loginScope.includes(user.loginScope) ||
    //             route.path === "/prijemce") &&
    //           route.path !== "/prijemce/obnoveni-zadosti" &&
    //           route.path !== "/prijemce/nabidky-pomoci"
    //       )
    //   }
    //   if(user.fullUser.RecipientStatusOfRequest.Id !== 2) {
    //     _routes = routes.filter((item) => item.path !== "/prijemce/obnoveni-zadosti")
    //   }
    //   setAvailableRoutes(_routes)
    // }
  };

  useEffect(() => {
    if (user.fullUser && routes) {
      // set menu for recipient
      if (user.loginScope === "recipient") recipientRoutes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes, user]);

  //console.log({ user, pageNotification, npsShowBanner });
  const socialClick = (network) => {
    let url = null;
    switch (network) {
      case "facebook":
        url = "https://facebook.com/dobryandel/";
        break;
      case "instagram":
        url = "https://instagram.com/dobryandel/";
        break;
      case "twitter":
        url = "https://twitter.com/dobryandel/";
        break;
      case "youtube":
        url = "https://youtube.com/user/dobryandelnf/";
        break;
      case "linkedin":
        url = "https://www.linkedin.com/company/2498415/";
        break;
      default:
        break;
    }

    if (url) window.open(url, "_blank");
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {pageTitle ? pageTitle : t("header-defaultTitle")} |{" "}
          {t("dobry-andel")}
        </title>
      </Helmet>

      <section className="main-wrapper">
        <section className="section-page-top-header">
          <div className="header-action left">
            <a
              href={getWebLink(i18n.language)}
              className="button is-small is-rounded"
            >
              <span className="icon">
                <i className="fa fa-arrow-alt-left" />
              </span>
              <span>{t("header-backToWeb")}</span>
            </a>
          </div>

          <div className="header-action center">
            <Hamburger toggled={isMenuOpen} toggle={setMenuOpen} size={26} />
          </div>

          <div className="header-action right">
            {user.isLoggedIn ? (
              <Link
                to="/logout"
                className="button is-small is-rounded"
                onClick={() => {
                  user.removeUserObject();
                }}
              >
                <i className="fa fa-lock-alt" />
                {t("login-buttonLogout")}
              </Link>
            ) : (
              <Link to="/login" className="button is-small is-rounded">
                <i className="fa fa-lock" />
                {t("login-buttonLogin")}
              </Link>
            )}
          </div>
        </section>

        <section className="section-main-page-wrapper">
          <div className="section-page-side-spacer left"></div>

          <div className="section-page-header left">
            <Link to="/">
              <img
                src={ImgLogo}
                alt="Dobrý Anděl - logo"
                className="header-overflow-box-img logo"
              />
            </Link>
          </div>

          <div className="section-page-center">
            <nav
              className={`top-header-navigation ${
                isMenuOpen ? "opened" : "closed"
              }`}
              role="navigation"
              aria-label="hlavní navigace"
            >
              {user.isLoggedIn &&
                availableRoutes &&
                availableRoutes
                  .sort((a, b) => {
                    if (a.menuOrder > b.menuOrder) return 1;
                    if (a.menuOrder < b.menuOrder) return -1;
                    return 0;
                  })
                  .map((menuItem, menuItemIdx) => {
                    if (
                      menuItem.menuTitle === false ||
                      typeof menuItem.menuTitle === "undefined" ||
                      menuItem.menuTitle === "menu-moje-dokumenty"
                    )
                      return null;

                    if (
                      menuItem.loginScope !== "*" &&
                      menuItem.loginScope !== false
                    ) {
                      if (!menuItem.loginScope.includes(user.loginScope)) {
                        return null;
                      }
                    }

                    return (
                      <MenuItem
                        key={`${menuItemIdx}-${menuItem.path}`}
                        title={t(menuItem.menuTitle)}
                        linkTo={menuItem.path}
                        icon={menuItem.menuIcon}
                        subItems={false}
                        hideDesktop={menuItem.hideDesktop}
                        hideMobile={menuItem.hideMobile}
                      />
                    );
                  })}
              {user.loginScope !== "recipient" && <LanguageSwitcher />}{" "}
              {/* disable language switcher for recipient */}
            </nav>

            <section className="page-main-content-wrap">
              <GlobalNotification />
              <DocumentNotification />

              {npsShowBanner && (
                <div style={{ marginTop: "1em" }}>
                  <NPS />
                </div>
              )}

              {children}
            </section>
          </div>

          <div className="section-page-header right">
            {user.isLoggedIn ? (
              <Fragment>
                <Link
                  to={
                    user.loginScope === "recipient"
                      ? "/prijemce/moje-fotky"
                      : "/muj-ucet"
                  }
                >
                  <img
                    key={`${user.personalNumber}-${user.avatar}`}
                    className="header-overflow-box-img avatar"
                    src={getUserAvatar(user)}
                    alt={user.displayName}
                    onError={(e) => {
                      e.target.src = anonymousAvatar;
                    }}
                  />
                </Link>
                <div className="profile-notes">
                  <div className="andelske-cislo">{user.personalNumber}</div>
                </div>
              </Fragment>
            ) : (
              <img
                className="header-overflow-box-img avatar"
                src={anonymousAvatar}
                alt=""
              />
            )}
          </div>

          <div className="section-page-side-spacer right"></div>
        </section>

        <footer>
          <div className="footer-contact">
            <div className="block phones">
              <div className="text-block left">733 119 119</div>
              <div className="icon-block">
                <i className="fa-regular fa-phone" />
              </div>
              <div className="text-block right">733 123 450</div>
            </div>

            <div className="block email">
              <div className="center">
                <a href="mailto:andelskaposta@dobryandel.cz">
                  andelskaposta@dobryandel.cz
                </a>
              </div>
            </div>
          </div>

          <div className="footer-content">
            <div className="text-block">
              <div>
                <RenderMD>footer-content</RenderMD>
              </div>
            </div>

            <div className="social-block">
              <ul className="social">
                <li
                  className="facebook"
                  onClick={() => {
                    socialClick("facebook");
                  }}
                >
                  <i className="fa-brands fa-facebook" />
                </li>
                <li
                  className="instagram"
                  onClick={() => {
                    socialClick("instagram");
                  }}
                >
                  <i className="fa-brands fa-instagram" />
                </li>
                <li
                  className="twitter"
                  onClick={() => {
                    socialClick("twitter");
                  }}
                >
                  <i className="fa-brands fa-twitter" />
                </li>
                <li
                  className="youtube"
                  onClick={() => {
                    socialClick("youtube");
                  }}
                >
                  <i className="fa-brands fa-youtube" />
                </li>
                <li
                  className="linkedin"
                  onClick={() => {
                    socialClick("linkedin");
                  }}
                >
                  <i className="fa-brands fa-linkedin" />
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </section>

      <section className="footer-appver">{`v${packInfo.version}`}</section>
    </Fragment>
  );
};

LayoutBasic.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  pageTitle: PropTypes.string,
};
LayoutBasic.defaultProps = {
  pageTitle: null,
};

const MenuItem = ({ hideDesktop, hideMobile, icon, linkTo, title }) => {
  const currentLocation = useLocation(); //console.log(currentLocation);
  return (
    <Link
      to={linkTo}
      className={`navbar-item ${
        currentLocation.pathname === linkTo ? "is-active" : ""
      } ${hideDesktop ? "is-hidden-on-desktop" : ""} ${
        hideMobile ? "is-hidden-on-mobile" : ""
      }`}
      aria-label={title}
    >
      <i className={`fa-regular fa-${icon}`} />
      {title}
    </Link>
  );
};

MenuItem.propTypes = {
  hideDesktop: PropTypes.bool,
  hideMobile: PropTypes.bool,
  icon: PropTypes.string,
  linkTo: PropTypes.string,
  title: PropTypes.string,
};
MenuItem.defaultProps = {
  hideDesktop: false,
  hideMobile: false,
  icon: null,
  linkTo: null,
  title: null,
};

export default LayoutBasic;
