import React, { Fragment, useContext, useEffect, useState } from "react";
//import PropTypes from "prop-types";
// import { Navigate } from "react-router-dom";
// import UserContext from "../UserContext.js";
//import Swal from "sweetalert2";
//import withReactContent from "sweetalert2-react-content";
import "../i18n.js";
import { useTranslation } from "react-i18next";
import LayoutPrijemceMojeUdaje from "../Layouts/LayoutPrijemceMojeUdaje.js";
// import FileBox from "../Components/FileBox.js";
import Styles from "./PrijemceDokumenty.module.scss";
import { Button } from "../Components/LinkButton.js";
import UploadDrawer from "../Components/UploadDrawer.js";
import UserContext from "../UserContext.js";
import { HttpGet } from "../HTTP.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loading from "../Components/Loading.js";
import { formatDateTime } from "../Helpers/dateFormater.js";
import useAccountLock from "../hooks/useAccountLock.js";
// const viewTypes = [
//   {
//     children: <i className="fa-solid fa-grip" />,
//     value: "grid",
//   },
//   {
//     children: <i className="fa-solid fa-table" />,
//     value: "list",
//   },
// ];

// const tableHeaders = [
//   {
//     title: "Název",
//     value: "name",
//   },
//   {
//     title: "Type",
//     value: "type",
//   },
//   {
//     title: "Typ souboru",
//     value: "fileType",
//   },
//   {
//     title: "Platnost",
//     value: "expirationDate",
//   },
// ];

// const filesExpirationStatus = {
//   valid: "",
//   beforeExpiration: "",
//   expired: "",
// };

const PrijemceDokumenty = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const MySwal = withReactContent(Swal);
  const [files, setFiles] = useState([]);
  const [LearningTo, setLearningTo] = useState(null);
  // const [viewType, setViewType] = useState("grid");
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleLoadData();
  }, []);

  const handleLoadData = () => {
    HttpGet("recipientProfile").then((response) => {
      setIsLoading(false);
      if (response.error_code === 200) {
        const pacient = response.response.Persons.find(
          (person) => person.PersonRelation?.Id + "" === "1"
        );
        if (pacient && pacient.LearningTo) {
          setLearningTo(pacient.LearningTo);
        }
      } else {
        return MySwal.fire({
          icon: "error",
          title: "Chyba",
          text: "Nepodařilo se načíst data.",
        });
      }
    });
  };

  return (
    <>
      {isLoading && <Loading />}
      <LayoutPrijemceMojeUdaje pageTitle={t("prijemce-mojeDokumenty-title")}>
        <UploadDrawer
          allowedFileTypes={[
            "image/jpeg",
            "image/png",
            "image/bmp",
            "application/pdf",
          ]}
          customTypes={[
            {
              value: 10,
              label: "Potvrzení o studiu",
            },
          ]}
          isOpen={isUploadOpen}
          onClose={() => setIsUploadOpen(false)}
          files={files}
          onChange={(files) => setFiles(files)}
          maxFileSize={10}
        />
        <Fragment>
          <div className={Styles.header}>
            <div>
              <p className={Styles.headerTitle}>Dokumenty</p>
            </div>

            <Button onClick={() => setIsUploadOpen(true)}>
              {t("btn-upload-file")}
            </Button>
          </div>

          <div className={Styles.infoSection}>
            {LearningTo !== null && (
              <div className={Styles.fileContainer}>
                <p className={Styles.fileLabel}>
                  Vaše aktuální potvrzení o studiu je platné do:
                </p>
                <p className={Styles.fileValue}>
                  {LearningTo && formatDateTime(new Date(LearningTo), true)}
                </p>
              </div>
            )}
            {t("prijemce-mojeDokumenty-description")}
          </div>

          {/* TABLE LAYOUT */}
          {/* <table className="table is-bordered is-striped is-narrow is-fullwidth">
            <thead>
              <tr>
                {tableHeaders.map((header) => (
                  <th key={header.value}>{header.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {files.map((file, index) => (
                <tr key={index}>
                  {tableHeaders.map((header) => (
                    <td key={header.value}>{file[header.value]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table> */}
          {/* BOXES LAYOUT */}
          {/* {viewType === "list" && (
          <div className={"container"}>
            <div className="container">
              {files.map((file, index) => (
                <div className="col-3 col-md-4 col-sm-6 col-xs-12" key={index}>
                  <FileBox {...file} />
                </div>
              ))}
            </div>
          </div>
        )} */}
        </Fragment>
      </LayoutPrijemceMojeUdaje>
    </>
  );
};

export default PrijemceDokumenty;
