import React from "react";
// import PropTypes from 'prop-types';
import LayoutMujUcet from "../Layouts/LayoutMujUcet.js";
// import UserContext from "../UserContext.js";
import { useTranslation } from "react-i18next";
import Styles from "./ProfilDarceDokumenty.module.scss";

import NPS from "../Components/NPS.js";

const ProfilDarceNPS = () => {
  // const user = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <LayoutMujUcet pageTitle={t("nps-title")}>
      <section className={Styles.header}>&nbsp;</section>

      <section>
        <NPS />
      </section>
    </LayoutMujUcet>
  );
};

// ProfilDarceNPS.propTypes = {}
// ProfilDarceNPS.defaultProps = {}

export default ProfilDarceNPS;
