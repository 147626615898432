import React, { useContext } from "react";
import PropTypes from "prop-types";
import LayoutBasic from "./LayoutBasic.js";
import UserContext from "../UserContext.js";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LinkButton from "../Components/LinkButton.js";
import Styles from "./LayoutMujUcet.module.scss";

const LayoutMujUcet = ({ children, pageTitle }) => {
  const { isLoggedIn } = useContext(UserContext);
  const { t } = useTranslation();
  const { pathname } = useLocation();

  //console.log({ pathname });
  return (
    <LayoutBasic pageTitle={pageTitle}>
      <section className={Styles.wrap}>
        {isLoggedIn && (
          <div className={Styles.side}>
            <LinkButton
              to="/muj-ucet/profil"
              isActive={pathname === "/muj-ucet/profil"}
              isBlock
            >
              {t("menu-muj-ucet-uprava-udaju")}
            </LinkButton>

            <LinkButton
              to="/muj-ucet/email"
              isActive={pathname === "/muj-ucet/email"}
              isBlock
            >
              {t("menu-muj-ucet-email")}
            </LinkButton>

            <LinkButton
              to="/muj-ucet/heslo"
              isActive={pathname === "/muj-ucet/heslo"}
              isBlock
            >
              {t("menu-muj-ucet-heslo")}
            </LinkButton>

            <LinkButton
              to="/muj-ucet/dokumenty"
              isActive={pathname === "/muj-ucet/dokumenty"}
              isBlock
            >
              {t("menu-muj-ucet-dokuemnty")}
            </LinkButton>

            <LinkButton
              to="/muj-ucet/avatar"
              isActive={pathname === "/muj-ucet/avatar"}
              isBlock
            >
              {t("menu-muj-ucet-uprava-avatara")}
            </LinkButton>

            <LinkButton
              to="/muj-ucet/nps"
              isActive={pathname === "/muj-ucet/nps"}
              isBlock
            >
              {t("menu-muj-ucet-nps")}
            </LinkButton>

            {/*
          <LinkButton
            to="/muj-ucet/verejny-profil"
            isActive={pathname === "/muj-ucet/verejny-profil"}
            isBlock
          >{t("menu-muj-ucet-verejny-profil")}</LinkButton>
          */}
          </div>
        )}

        <div className={Styles.content}>{children}</div>
      </section>
    </LayoutBasic>
  );
};

LayoutMujUcet.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
};

export default LayoutMujUcet;
