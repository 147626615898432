import React, { Fragment, useContext, useState, useEffect } from "react";
//import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import UserContext from "../UserContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../i18n.js";
import InfoHeader from "../Components/InfoHeader.js";
import { useTranslation } from "react-i18next";
import LayoutBasic from "../Layouts/LayoutBasic.js";
// import AlertContainer from "../Components/AlertContainer.js";
import useSWR from "swr";

import Styles from "./PrijemceIndex.module.scss";
import { HttpGet } from "../HTTP.js";
import LoadingIcon from "../Components/LoadingIcon.js";

import { format } from "../Helpers/dateFormater.js";
import useAccountLock from "../hooks/useAccountLock.js";

// import { FormInputGroup, FormInput } from "../Components/FormInput.js";
// import Tooltip from "../Components/Tooltip.js";

const PrijemceIndex = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [recipientNearestSplit, setRecipientNearestSplit] = useState(null);
  const [requestDate, setRequestDate] = useState(null);
  const [discardDate, setDiscardDate] = useState(null);
  const MySwal = withReactContent(Swal);
  const { renderAlert } = useAccountLock({ onlyBeforeWarning: true });

  const { data: recipientFutureSplits } = useSWR(
    "/valueStore/recipient_future_split_date"
  );

  useEffect(() => {
    if (
      recipientFutureSplits &&
      recipientFutureSplits.recipient_future_split_date
    ) {
      let splits = recipientFutureSplits.recipient_future_split_date.split(",");
      const currentDate = new Date();

      const nearestFutureDate = splits.find((splitDate) => {
        const date = new Date(splitDate);
        return date > currentDate;
      });

      if (nearestFutureDate) {
        setRecipientNearestSplit(nearestFutureDate);
      }
    }
  }, [recipientFutureSplits]);

  useEffect(() => {
    if (user.fullUser) {
      HttpGet("recipientProfile", false).then((response) => {
        if (response.error_code !== 200) {
          MySwal.fire({
            title: t("error"),
            text: t("myProfile-error-body"),
            icon: "error",
            confirmButtonText: t("close"),
          });
        } else {
          setData(response.response);
        }
        setIsLoading(false);
      });
    }
    if (user?.fullUser?.RequestDate) {
      const date = new Date(user.fullUser.RequestDate);
      date.setMonth(date.getMonth() + 6);
      setRequestDate(date);
    }
    if (user?.fullUser?.DiscardDate) {
      const date = new Date(user.fullUser.DiscardDate);
      setDiscardDate(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const recipientRequestStatuses = {
    // OK
    1: {
      title: t("prijemce-requestStatus-ok"),
      description: t("prijemce-requestStatus-ok-description"),
      status: "success",
      content: () => {
        if (user.fullUser.RequestDate) {
          return (
            <div>
              <p>
                {t("prijemce-requestStatus-ok-description")}{" "}
                {requestDate &&
                  `${requestDate.getMonth() + 1}/${requestDate.getFullYear()}`}
                .
              </p>
            </div>
          );
        } else {
          return <p>{"{Missing User.fullUser.RequestData}"}</p>;
        }
      },
    },
    // IN PROCESS OF REFRESH
    2: {
      title: t("prijemce-requestStatus-vProcesuObnoveni"),
      description: t("prijemce-requestStatus-vProcesuObnoveni-description"),
      status: "info",
      content: () => (
        <div>
          <p>
            {user.fullUser.RecipientRequestStatus?.Id === 1 ? (
              <>
                {t("prijemce-requestStatus-vProcesuObnoveni-description")}{" "}
                <a href="/prijemce/obnoveni-zadosti">Obnovení žádosti</a>.
              </>
            ) : (
              <>{t("prijemce-requestStatus-dosla-description")}</>
            )}
          </p>
        </div>
      ),
    },
    // INCOMPLETE REQUEST
    3: {
      title: t("prijemce-requestStatus-nekompletniZadost"),
      description: t("prijemce-requestStatus-nekompletniZadost-description"),
      status: "warning",
      content: () => (
        <div>
          <p>{t("prijemce-requestStatus-nekompletniZadost-description")}</p>
        </div>
      ),
    },
    // PAUSED
    4: {
      title: t("prijemce-requestStatus-pozastaven"),
      description: t("prijemce-requestStatus-pozastaven-description"),
      status: "error",
      content: () => (
        <div>
          <p>{t("prijemce-requestStatus-pozastaven-description")}</p>
        </div>
      ),
    },
    // CANCELLED
    5: {
      title: t("prijemce-requestStatus-vyrazen"),
      description: t("prijemce-requestStatus-vyrazen-description"),
      status: "error",
      content: () => (
        <div>
          <p>{t("prijemce-requestStatus-vyrazen-description")}</p>
        </div>
      ),
    },
    // NO REFRESH
    6: {
      title: t("prijemce-requestStatus-neobnovovat"),
      description: t("prijemce-requestStatus-neobnovovat-description"),
      status: "success",
      content: () => {
        if (user.fullUser.DiscardDate) {
          return (
            <div>
              <p>
                {t("prijemce-requestStatus-neobnovovat-description")}{" "}
                {discardDate &&
                  `${discardDate.getMonth() + 1}/${discardDate.getFullYear()}`}
                .
              </p>
            </div>
          );
        } else {
          return <p>{"{Missing User.fullUser.RequestData}"}</p>;
        }
      },
    },
    // NEW REQUEST
    7: {
      title: t("prijemce-requestStatus-nova"),
      description: t("prijemce-requestStatus-nova-description"),
      status: "info",
      content: () => (
        <div>
          <p>{t("prijemce-requestStatus-nova-description")}</p>
        </div>
      ),
    },
    // REQUEST ACCEPTED
    8: {
      title: t("prijemce-requestStatus-dosla"),
      description: t("prijemce-requestStatus-dosla-description"),
      status: "info",
      content: () => (
        <div>
          <p>{t("prijemce-requestStatus-dosla-description")}</p>
        </div>
      ),
    },
    9: {
      title: t("prijemce-requestStatus-dosla"),
      description: t("prijemce-requestStatus-dosla-description"),
      status: "info",
      content: () => (
        <div>
          <p>{t("prijemce-requestStatus-dosla-description")}</p>
        </div>
      ),
    },
  };

  if (user.isLoggedIn === false) {
    return <Navigate to="/prijemce/prihlaseni" />;
  }
  // const testIndex = 8

  return (
    <LayoutBasic pageTitle={t("pageIndex-title")}>
      <Fragment>
        <Helmet>
          <title>{t("header-homepageTitle")}</title>
        </Helmet>

        {data && !isLoading ? (
          <div className={Styles.container}>
            <div className={Styles.MainSection}>
              {renderAlert()}
              <h1 className={Styles.Heading}>Základní informace</h1>
              <InfoHeader
                title={t("frm-personrelation-pacient")}
                content={`${data.PatientTitle ?? ""} ${data.PatientFirstName} ${
                  data.PatientLastName
                }`}
              />

              <InfoHeader
                title={t("prijemce-login-birthdate")}
                content={format(data.PatientBirthday, "DD.MM.YYYY")}
              />

              {data && data.Kind?.Id === 1 && (
                <InfoHeader
                  title={t("frm-caring-person")}
                  content={`${data.Title ?? ""} ${data.FirstName} ${
                    data.LastName
                  }`}
                />
              )}

              <InfoHeader
                title={t("frm-status-of-request")}
                className={Styles.isTooLong}
                content={
                  <div className={Styles.requestStatus}>
                    <span className={Styles.requestTitle}>
                      <b>
                        {
                          recipientRequestStatuses[
                            data.RecipientStatusOfRequest.Id
                          ].title
                        }
                      </b>{" "}
                      -{" "}
                    </span>
                    <span className={Styles.requestDescription}>
                      <i>
                        {recipientRequestStatuses[
                          data.RecipientStatusOfRequest.Id
                        ].content()}
                      </i>
                    </span>
                  </div>
                }
                marginTop="20px"
                marginBottom="20px"
              />

              {data &&
                data.RecipientStatusOfRequest.Id !== 4 &&
                data.RecipientStatusOfRequest.Id !== 5 && (
                  <InfoHeader
                    title={t("prijemceStatus-next-donation")}
                    className={Styles.isTooLong}
                    content={
                      recipientNearestSplit
                        ? format(recipientNearestSplit, "DD.MM.YYYY")
                        : t("prijemceStatus-next-donation-unknown")
                    }
                  />
                )}

              {data && data.RecipientStatusOfRequest.Id !== 5 && (
                <InfoHeader
                  title={t("prijemceStatus-payment-to")}
                  className={Styles.isTooLong}
                  content={
                    user.fullUser.Account
                      ? `${user.fullUser.Account.AccountNumberPrefix}-${user.fullUser.Account.AccountNumber}/${user.fullUser.Account.BankCode}`
                      : ""
                  }
                />
              )}

              <hr className={Styles.Row} />

              <div className={Styles.userStoryContainer}>
                <div className={Styles.headingContainer}>
                  <h1 className={Styles.Heading}>Příběh rodiny</h1>

                  <p className={Styles.Tooltip}>
                    {t("prijemce-pribeh-tooltip")}
                  </p>
                </div>
                <div className={Styles.LifeStory}>
                  {user.fullUser.LifeStory &&
                  user.fullUser.LifeStory.length !== 0 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: user.fullUser.LifeStory,
                      }}
                    />
                  ) : (
                    <span className={"text-primary"}>
                      {t("prijemce-lifestory-unknown")}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={Styles.loadingBackground}>
            <LoadingIcon size={50} />
          </div>
        )}
      </Fragment>
    </LayoutBasic>
  );
};

// Login.propTypes = {};
// Login.defaultProps = {}

export default PrijemceIndex;
