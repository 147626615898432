import React, { useState, Fragment, useContext } from "react";
//import PropTypes from "prop-types";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import UserContext from "../UserContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import "../i18n.js";
import { useTranslation } from "react-i18next";
import { HttpPost } from "../HTTP.js";
import { tokenizeString } from "../Helpers/string-tokenizer.js";

import ImgLogo from "../assets/logo.png";

import "./Login.scss";

const PasswordReset = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [username, setUsername] = useState("");
  const [verification, setVerification] = useState("");

  const [isValidating, setIsValidating] = useState(false);
  const [askForCode, setAskForCode] = useState(false);
  const user = useContext(UserContext);

  if (user.isLoggedIn === true) {
    // console.log("user is logged in...", user);
    return <Navigate to="/" />;
  }

  const handleUsernameEnter = (e) => {
    setUsername(e.target.value);
  };

  const handleCodeEnter = (e) => {
    const val =
      e.target.value.length > 6 ? e.target.value.slice(0, 6) : e.target.value;

    if (
      val === parseInt(val, 10).toString() ||
      val === "" ||
      parseInt(val, 10) === 0
    ) {
      setVerification(val);
    }
  };

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      onResetRequestSubmit();
    }
  };

  const onResetRequestSubmit = () => {
    setIsValidating(true);

    HttpPost("resetPassword", { username, verification }, false).then(
      (resp) => {
        setIsValidating(false);
        if (resp.error_code !== 200) {
          MySwal.fire({
            text: t(`err-${tokenizeString(resp.error || "unknown")}`),
            icon: "error",
            confirmButtonText: t("login-error-ok-button"),
          });
          return false;
        }

        setAskForCode(true);
      }
    );
  };

  const onResetCodeValidationSubmit = () => {
    setIsValidating(true);

    HttpPost("resetPassword", { username, verification }, false).then(
      (resp) => {
        setIsValidating(false);

        if (resp.error_code !== 200) {
          MySwal.fire({
            text: t(`err-${tokenizeString(resp.error || "unknown")}`),
            icon: "error",
            confirmButtonText: t("login-error-ok-button"),
          });
          return false;
        }
        user.setUserTempPassword({ username, tempPassword: resp.response });
        navigate("/muj-ucet/heslo");
      }
    );
  };

  return (
    <Fragment>
      <Helmet>
        <title>{t("header-passwordResetTitle")}</title>
      </Helmet>

      <div className="loginCard">
        <img src={ImgLogo} alt="Dobrý Anděl - logo" className="logo" />

        <div className="loginCardInner">
          <div>
            <h1 className="title">{t("password-reset-title")}</h1>
          </div>

          <section>
            <div className="field">
              <label className="label">{t("login-username")}</label>
              <div className={"control has-icons-left"}>
                <input
                  className="input"
                  disabled={isValidating || askForCode}
                  type="text"
                  autoComplete="username"
                  value={username}
                  placeholder={t("login-username")}
                  autoFocus
                  onChange={handleUsernameEnter}
                  onKeyDown={onInputKeyDown}
                />
                <span className="icon is-small is-left">
                  <i className="fa-regular fa-envelope" />
                </span>
              </div>
            </div>

            {askForCode && (
              <div className="field">
                <label className="label">{t("password-reset-code")}</label>
                <div className={"control has-icons-left"}>
                  <input
                    className="input"
                    disabled={isValidating}
                    type="text"
                    value={verification}
                    placeholder={t("password-reset-code")}
                    autoFocus
                    onChange={handleCodeEnter}
                    onKeyDown={onInputKeyDown}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa-regular fa-shield-check" />
                  </span>
                </div>
              </div>
            )}
          </section>

          <div className="loginButton">
            <button
              className={
                "button is-rounded" + (isValidating ? " is-loading" : "")
              }
              disabled={isValidating}
              onClick={
                askForCode ? onResetCodeValidationSubmit : onResetRequestSubmit
              }
            >
              {t(
                askForCode
                  ? "login-buttonPasswordResetValidate"
                  : "login-buttonPasswordReset"
              )}
            </button>
          </div>

          <div className="loginLinks">
            <Link to="/registrace">{t("login-buttonRegister")}</Link>
            <Link to="/login">{t("login-buttonLogin")}</Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// PasswordReset.propTypes = {};
// PasswordReset.defaultProps = {}

export default PasswordReset;
