import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./ImageLightbox.module.scss";
import { useKeyPress } from "../hooks/useKeyPress.js";

const ImageLightbox = ({
  alt,
  title,
  img,
  onClose,
  onNext,
  onPrev,
  hasNext,
  hasPrev,
}) => {
  //const keyNext = useKeyPress("ArrowRight");
  //const keyPrev = useKeyPress("ArrowLeft");
  const keyEsc = useKeyPress("Escape");

  useEffect(() => {
    //if (keyNext && onNext) onNext();
    //if (keyPrev && onPrev) onPrev();
    if (keyEsc && onClose) onClose();
  }, [keyEsc, onNext, onPrev, onClose]);

  return (
    <div className={styles.wrap}>
      <div className={styles.overlay} onClick={onClose}></div>

      <div className={styles.container}>
        <div className={styles.close} onClick={onClose}>
          <i className="fa-regular fa-window-close" />
        </div>

        {hasNext() && (
          <div className={styles.next} onClick={onNext}>
            <i className="fa-regular fa-chevron-right" />
          </div>
        )}

        {hasPrev() && (
          <div className={styles.prev} onClick={onPrev}>
            <i className="fa-regular fa-chevron-left" />
          </div>
        )}

        <div className={styles.content}>
          <img key={img} src={img} alt={alt} title={title} />
          <div>{title}</div>
        </div>
      </div>
    </div>
  );
};

ImageLightbox.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string,
  img: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  hasNext: PropTypes.func,
  hasPrev: PropTypes.func,
};

export default ImageLightbox;
