import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { registrationLink } from "../appConfig.js";
import LayoutClean from "../Layouts/LayoutClean.js";
import Loading from "../Components/LoadingIcon.js";

const PageRegister = () => {
  const { t } = useTranslation();

  useEffect(() => {
    if (window) {
      window.location.href = registrationLink;
    }
  }, []);

  return (
    <LayoutClean>
      <Helmet>
        <title>{t("page-register")}</title>
      </Helmet>

      <div style={{ textAlign: "center", marginTop: "5em" }}>
        <div style={{ marginBottom: "2em" }}>
          <Loading />
        </div>

        <div>{t("redirect-to-web")}</div>

        <div>
          <a href={registrationLink}>{registrationLink}</a>
        </div>
      </div>
    </LayoutClean>
  );
};

export default PageRegister;
