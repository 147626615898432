import React, { useState, Fragment, useContext } from "react";
//import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import UserContext from "../UserContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LanguageSwitcher from "../Components/LanguageSwitcher.js";
import "../i18n.js";
import { useTranslation } from "react-i18next";
import { HttpPost } from "../HTTP.js";
import { tokenizeString } from "../Helpers/string-tokenizer.js";
import { getWebLink } from "../appConfig.js";

import ImgLogo from "../assets/logo.png";

import "./Login.scss";

const Login = () => {
  const { t, i18n } = useTranslation();
  const MySwal = withReactContent(Swal);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authIsValidating, setAuthIsValidating] = useState(false);
  const user = useContext(UserContext);

  if (user.isLoggedIn === true) {
    // console.log("user is logged in...", user);
    return <Navigate to="/" />;
  }

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      onLoginSubmit();
    }
  };

  const onLoginSubmit = () => {
    // console.log("login", username, password);
    setAuthIsValidating(true);

    HttpPost("login", { username, password }, false).then((resp) => {
      // console.log("login request", resp);

      setAuthIsValidating(false);
      if (resp.error_code !== 200) {
        MySwal.fire({
          text: t(`err-${tokenizeString(resp.error || "unknown")}`),
          icon: "error",
          confirmButtonText: t("login-error-ok-button"),
        });
        return false;
      }

      user.setAccessToken(resp.response.token, resp.response.expire);
      user.setUserObject(
        resp.response.scope,
        resp.response.profile,
        resp.response.profile_full
      );
    });
  };

  return (
    <Fragment>
      <Helmet>
        <title>{t("header-loginTitle")}</title>
      </Helmet>

      <div className="loginCard">
        <a href={getWebLink(i18n.language)}>
          <img src={ImgLogo} alt="Dobrý Anděl - logo" className="logo" />
        </a>

        <div className="loginCardInner">
          <div>
            <h1 className="title">{t("login-title")}</h1>
          </div>

          <section>
            <div className="field">
              <label className="label">{t("login-username")}</label>
              <div className={"control has-icons-left"}>
                <input
                  className="input"
                  disabled={authIsValidating}
                  type="text"
                  autoComplete="username"
                  value={username}
                  placeholder={t("login-username")}
                  autoFocus
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  onKeyDown={onInputKeyDown}
                />
                <span className="icon is-small is-left">
                  <i className="fa-regular fa-envelope" />
                </span>
              </div>
            </div>

            <div className="field">
              <label className="label">{t("login-password")}</label>
              <div className={"control has-icons-left"}>
                <input
                  className="input"
                  disabled={authIsValidating}
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  placeholder={t("login-password")}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onKeyDown={onInputKeyDown}
                />
                <span className="icon is-small is-left">
                  <i className="fa-regular fa-lock-alt" />
                </span>
              </div>
            </div>
          </section>

          <div className="loginButton">
            <button
              className={
                "button is-rounded" + (authIsValidating ? " is-loading" : "")
              }
              disabled={authIsValidating}
              onClick={onLoginSubmit}
            >
              {t("login-buttonLogin")}
            </button>
          </div>

          <div className="loginLinks">
            <Link to="/registrace">{t("login-buttonRegister")}</Link>
            <Link to="/obnova-hesla">{t("login-buttonResetPassword")}</Link>
          </div>

          <div className="loginLang">
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// Login.propTypes = {};
// Login.defaultProps = {}

export default Login;
