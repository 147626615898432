import React from "react";
// import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
//import { Link } from "react-router-dom";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import RenderMd from "../Components/RenderMd.js";
import Styles from "./JakPomahat.module.scss";

const KomuPomaham = () => {
  const { t } = useTranslation();

  return (
    <LayoutBasic pageTitle={t("paymentInfo-title")}>
      <section className={Styles.wrap}>
        <RenderMd>md-paymentInfo</RenderMd>
      </section>
    </LayoutBasic>
  );
};

// KomuPomaham.propTypes = {};
// KomuPomaham.defaultProps = {}

export default KomuPomaham;
