import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import LayoutBasic from "./LayoutBasic.js";
import UserContext from "../UserContext.js";
import Styles from "./LayoutObnoveniZadostiV2.module.scss";
// import Sections from "../Components/Sections.js";
import { Button } from "../Components/LinkButton.js";
import Tooltip from "../Components/Tooltip.js";
import useAccountLock from "../hooks/useAccountLock.js";
// import { useNavigate } from "react-router-dom";
import ModalCard from "../Components/ModalCard.js";
import { useTranslation } from "react-i18next";

const LayoutObnoveniZadosti = ({
  children,
  pageTitle,
  data,
  onSectionClick,
  currentSection,
  prevStep,
  changeStep,
  onInjectedChangeStep,
}) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useContext(UserContext);
  const [showLockModal, setShowLockModal] = useState(false);
  const { isLocked, renderAlert, unlockDate } = useAccountLock({
    onlyBeforeWarning: false,
  });
  const { renderAlert: renderAlertOnlyBefore } = useAccountLock({
    onlyBeforeWarning: true,
  });
  // const navigate = useNavigate();

  const handleClick = (section) => {
    onSectionClick(section);
  };

  const renderItem = (style, index, clickAble, tooltipValue) => {
    return (
      <Tooltip
        content={tooltipValue}
        key={index}
        className={style}
        id={`tooltip-${index}`}
      >
        <div
          key={index}
          className={style}
          onClick={() => {
            if (clickAble) {
              handleChangeStep(index);
              onSectionClick && !isLocked && handleClick(index);
            }
          }}
        />
      </Tooltip>
    );
  };

  const handleChangeStep = (sectionToSet) => {
    if (isLocked) {
      // navigate("/prijemce");
      setShowLockModal(true);
      return;
    }
    if (currentSection === 2) {
      onInjectedChangeStep && onInjectedChangeStep(sectionToSet);
    } else {
      changeStep && changeStep(sectionToSet);
    }
  };

  return (
    <LayoutBasic pageTitle={pageTitle}>
      <ModalCard
        isActive={showLockModal}
        title={t("frm-locked-modal-title")}
        onClose={() => {
          setShowLockModal(false);
        }}
      >
        <p>
          {t("frm-locked-modal-description", {
            unlockDate: unlockDate,
          })}
        </p>
      </ModalCard>
      {currentSection !== 2 && currentSection !== 1
        ? renderAlertOnlyBefore()
        : renderAlert()}
      <section className={Styles.wrap}>
        {isLoggedIn && currentSection !== 0 && (
          <>
            {/* MOBILE NAV */}
            <div className={Styles.mobileStepperContainer}>
              <div className={Styles.stepperBoxes}>
                {data.map((section, index) => {
                  if (index === 0) return <React.Fragment key={index} />;

                  if (!section.isNextStep && !section.completed)
                    return renderItem(
                      Styles.stepperBoxDisabled,
                      index,
                      false,
                      section.title
                    );

                  if (index === currentSection)
                    return renderItem(
                      Styles.stepperBoxActive,
                      index,
                      true,
                      section.title
                    );

                  return renderItem(
                    Styles.stepperBox,
                    index,
                    true,
                    section.title
                  );
                })}
              </div>
              <div className={Styles.stepperMobileButtons}>
                <Button
                  style={{ height: 25 }}
                  disabled={currentSection <= 1}
                  onClick={() => {
                    handleChangeStep(currentSection > 0 && currentSection - 1);
                    prevStep && !isLocked && prevStep();
                  }}
                >
                  Zpět
                </Button>
                <Button
                  style={{ height: 25 }}
                  disabled={currentSection === data.length - 1 && isLocked}
                  onClick={() => handleChangeStep()}
                >
                  {currentSection === data.length - 1 ? "Odeslat" : "Další"}
                </Button>
              </div>
            </div>
            {/* PC NAV */}
            <div className={Styles.stepperContainer}>
              <Button
                disabled={currentSection <= 1}
                style={{ height: 25 }}
                onClick={() => {
                  handleChangeStep(currentSection > 0 && currentSection - 1);
                  prevStep && !isLocked && prevStep();
                }}
              >
                Zpět
              </Button>
              <div className={Styles.stepperBoxes}>
                {data.map((section, index) => {
                  if (index === 0) return <React.Fragment key={index} />;

                  if (!section.isNextStep && !section.completed)
                    return renderItem(
                      Styles.stepperBoxDisabled,
                      index,
                      false,
                      section.title
                    );

                  if (index === currentSection)
                    return renderItem(
                      Styles.stepperBoxActive,
                      index,
                      true,
                      section.title
                    );
                  // NEXT STEP STYLE
                  // if (section.isNextStep) return (
                  //   <div key={index} className={Styles.stepperBoxActive} onClick={() => handleClick(index)} />
                  // )

                  return renderItem(
                    Styles.stepperBox,
                    index,
                    true,
                    section.title
                  );
                })}
              </div>
              <Button
                disabled={currentSection === data.length - 1 && isLocked}
                style={{ height: 25 }}
                onClick={() => handleChangeStep()}
              >
                {currentSection === data.length - 1 ? "Odeslat" : "Další"}
              </Button>
            </div>
          </>
        )}
        <div className={Styles.content}>{children}</div>
      </section>
    </LayoutBasic>
  );
};

LayoutObnoveniZadosti.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
  onSectionClick: PropTypes.func.isRequired,
  currentSection: PropTypes.number,
  changeStep: PropTypes.func,
  prevStep: PropTypes.func,
  onInjectedChangeStep: PropTypes.func,
};

export default LayoutObnoveniZadosti;
