import React from "react";
import { useTranslation } from "react-i18next";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import Styles from "./403.module.scss";
import LinkButton from "../Components/LinkButton.js";

const Page403 = () => {
  const { t } = useTranslation();

  return (
    <LayoutBasic pageTitle={t("page-access-unauthorized")}>
      <section className={Styles.wrap}>
        <div className="title">{t("page-access-unauthorized")}</div>

        <div className="">{t("page-access-unauthorized-info")}</div>

        <div className={Styles.nav}>
          <LinkButton to="/">{t("menu-home")}</LinkButton>
        </div>
      </section>
    </LayoutBasic>
  );
};

export default Page403;
