import React, { useContext, useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import LayoutMujUcet from "../Layouts/LayoutMujUcet.js";
import UserContext from "../UserContext.js";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
//import dayjs from "dayjs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useFormik, FormikProvider } from "formik";
import FormInput, { FormInputGroup } from "../Components/FormInput.js";
import LoadingIcon from "../Components/LoadingIcon.js";
import { HttpGet, HttpPost } from "../HTTP.js";
import Styles from "./ProfilDarce.module.scss";

const ProfilDarceDanove = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);

  const [isLoading, setLoading] = useState(true);
  const [hasErrorLoading, setErrorLoading] = useState(false);

  const data = user.fullUser;

  const formik = useFormik({
    initialValues: { IsCompany: "false" },
  });
  const { errors, values } = formik;
  const isCompany = values?.IsCompany;

  const loadData = () => {
    return new Promise((resolve, reject) => {
      setLoading(true);

      HttpGet("userProfile", false).then((resp) => {
        const formData = resp?.response?.profile_full;
        const errorCode = resp && resp.error_code;
        // console.log({ errorCode, formData, resp });

        if (errorCode !== 200) {
          setErrorLoading(true);
          setLoading(false);
          reject(errorCode);
          return;
        }

        const isCompany = formData?.FirmName ? "true" : "false";
        // formik.setFieldValue("NameForTax", formData?.NameForTax, false);
        formik.setFieldValue("IsCompany", isCompany, false);
        setLoading(false);
        setErrorLoading(false);
      });

      resolve();
    });
  };

  const handleBackToEdit = () => {
    navigate("/muj-ucet/profil/?taxinfo");
  };

  const handleConfirm = () => {
    const isCompany = values?.IsCompany;
    const taxInfo = createPreview(isCompany);

    const payload = {
      to: "podpora@dobryandel.cz",
      subject: `Údaje pro daňové účely - ${data?.PersonalNumber}`,
      fields: [
        {
          id: "personalNumber",
          title: "Andělské číslo",
          value: data?.PersonalNumber,
        },

        {
          id: "isCompany",
          title: "Společnost",
          value: isCompany === "true" ? "ANO" : "ne",
        },

        {
          id: "nameForTax",
          title: "Údaje pro daňové účely",
          value: taxInfo.taxDisplayInfo,
        },
        {
          id: "addressLine1",
          title: " ",
          value: taxInfo.addressLine1,
        },
        {
          id: "addressLine2",
          title: " ",
          value: taxInfo.addressLine2,
        },
      ],
    };

    //console.log(isCompany, payload);return;

    HttpPost(`emailForm`, payload).then((resp) => {
      if (resp.error_code !== 200) {
        // show error
        return true;
      }
      navigate("/muj-ucet");

      MySwal.fire({
        title: t("profil-danove-udaje-confirmed"),
        icon: "success",
        timer: 5000,
        position: "center",
        timerProgressBar: true,
        showConfirmButton: true,
      });
    });
  };

  const createPreview = (isCompany) => {
    const address = data?.Address;
    const addressLine1 = `${address?.Street} ${address?.HouseNumber}`.trim();
    const addressLine2 = `${address?.PostalCode} ${address?.City}`.trim();

    const taxInfoName =
      `${data?.Title} ${data?.FirstName} ${data?.LastName}`.trim();
    const taxInfoCompany = `${data?.FirmName}${
      data?.Ic ? `, ${t("frm-ic")}: ${data?.Ic}` : ""
    }`.trim();

    const nameForTaxInput = data?.NameForTax;
    const nameForTax = nameForTaxInput ? nameForTaxInput : "";
    const taxDisplayInfo = nameForTax
      ? nameForTax
      : isCompany === "false"
      ? taxInfoName
      : taxInfoCompany;

    return { taxDisplayInfo, addressLine1, addressLine2 };
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tadDisplayInfo = createPreview(isCompany);

  return (
    <LayoutMujUcet pageTitle={t("profil-danove-udaje")}>
      <div className={Styles.formDarce}>
        {isLoading && (
          <div className={Styles.loading}>
            <LoadingIcon size={48} />
          </div>
        )}

        <h1 className="title">{t("profil-danove-udaje")}</h1>

        {hasErrorLoading && (
          <div>
            <div className="message is-danger">
              <div className="message-header">
                <p>{t("myProfile-error-title")}</p>
              </div>
              <div className="message-body">
                <p>{t("myProfile-error-body")}</p>
                <p>&nbsp;</p>
                <button
                  onClick={loadData}
                  className="button is-fullwidth is-primary is-outlined"
                >
                  {t("button-reload")}
                </button>
              </div>
            </div>
          </div>
        )}

        <div className={`${hasErrorLoading ? "hidden" : ""}`}>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <section className="mt-5">
                <FormInputGroup cols={1}>
                  <FormInput
                    name="IsCompany"
                    type="toggle"
                    labelTrue={t("profil-danove-udaje-edit-company")}
                    labelFalse={t("profil-danove-udaje-edit-fo")}
                    onChange={formik.handleChange}
                    error={errors}
                    value={formik.values.IsCompany}
                  />
                </FormInputGroup>
              </section>
            </form>
          </FormikProvider>

          <section className="mt-5">
            <h2 className="title">{t("profil-danove-udaje-nahled")}</h2>
            <p>{t("profil-danove-udaje-nahled-desc")}</p>

            <div className="columns mt-3">
              <div className="column">
                <div className="card">
                  <div className="card-content">
                    {tadDisplayInfo.taxDisplayInfo}
                    <div>{tadDisplayInfo.addressLine1}</div>
                    <div>{tadDisplayInfo.addressLine2}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`align-center ${Styles.actionButtons}`}>
              <button
                onClick={handleBackToEdit}
                className="button is-rounded"
                style={{}}
              >
                {t("profil-danove-udaje-upravit")}
              </button>

              <button onClick={handleConfirm} className="button is-rounded">
                {t("profil-danove-udaje-ok")}
              </button>
            </div>
          </section>
        </div>
      </div>
    </LayoutMujUcet>
  );
};

// ProfilDarceDanove.propTypes = {}
// ProfilDarceDanove.defaultProps = {}

export default ProfilDarceDanove;
