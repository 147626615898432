import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./Payments.module.scss";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PaymentsReceived from "../Components/PaymentsReceived.js";
import PaymentsSent from "../Components/PaymentsSent.js";
import { LinkButton, Button } from "../Components/LinkButton";

const Payments = ({ showReceived, showSent }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(12);
  const [hasMore, setHasMore] = useState(false);
  const [viewSwitch, setViewSwitch] = useState("received");

  // console.log({ showReceived, showSent, viewSwitch });
  const viewSwitchModes = [
    {
      id: "received",
      title: t("pagePaymentsReceived"),
      visible: showReceived,
    },
    { id: "sent", title: t("pagePaymentsSent"), visible: showSent },
  ];

  const loadMore = () => {
    setLimit(null);
  };

  return (
    <LayoutBasic pageTitle={t("pagePayments")}>
      <div className={styles.wrap}>
        <div className={styles.topWrap}>
          <h1 className="title">{t("menu-moje-platby")}</h1>

          <LinkButton to="/jak-pomahat">
            {t("payment-how-to-button")}
          </LinkButton>
        </div>

        <div className={styles.viewSwitch}>
          <ul>
            {viewSwitchModes.map((mode) => {
              if (!mode.visible) return null;

              return (
                <li
                  key={mode.id}
                  onClick={() => {
                    setViewSwitch(mode.id);
                  }}
                  className={`${mode.id === viewSwitch ? styles.active : ""}`}
                >
                  {mode.title}
                </li>
              );
            })}
          </ul>
        </div>

        <div className={styles.innerWrap}>
          {showReceived && (
            <div
              className={`${styles.tile} ${styles.received} ${
                styles["switch_" + viewSwitch]
              }`}
            >
              <h2 className={`subtitle ${styles.subtitle}`}>
                {t("pagePaymentsReceived")}
              </h2>
              <PaymentsReceived limit={limit} hasMore={setHasMore} />
              <div className={styles.note}>
                <i className="fa-thin fa-info-circle" />{" "}
                {t("payment-received-note")}
              </div>
            </div>
          )}

          {showSent && (
            <div
              className={`${styles.tile} ${styles.sent} ${
                styles["switch_" + viewSwitch]
              }`}
            >
              <h2 className={`subtitle ${styles.subtitle}`}>
                {t("pagePaymentsSent")}
              </h2>
              <PaymentsSent limit={limit} hasMore={setHasMore} />
              <div className={styles.note}>
                <i className="fa-thin fa-info-circle" />{" "}
                {t("payment-sent-note")}
              </div>
            </div>
          )}
        </div>

        <div className={styles.more}>
          <Button
            isOutline
            onClick={() => {
              navigate("/moje-platby/nevidim-platbu/");
            }}
          >
            {t("payment-missing")}
          </Button>
          {limit > 0 && hasMore && (
            <Button onClick={loadMore}>{t("payment-show-more")}</Button>
          )}
        </div>
      </div>
    </LayoutBasic>
  );
};

export default Payments;

Payments.propTypes = {
  showReceived: PropTypes.bool,
  showSent: PropTypes.bool,
};

Payments.defaultProps = {
  showReceived: true,
  showSent: true,
};
