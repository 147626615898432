import React, { useEffect } from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { PaymentType } from "./PaymentsHelpers.js";
import dayjs from "dayjs";
import LoadingIcon from "./LoadingIcon.js";

const PaymentsReceived = ({ limit, hasMore }) => {
  const { data, isValidating } = useSWR("getReceivedPayments/");
  const { t, i18n } = useTranslation();
  // console.log({ data });

  useEffect(() => {
    if (data && limit) {
      const dataSize = data.length;

      if (dataSize > limit) {
        if (hasMore) hasMore(true);
      }
    }
  }, [data, limit, hasMore]);

  if (isValidating) {
    return (
      <div>
        <LoadingIcon />
        {t("loading")}
      </div>
    );
  }

  return (
    <div className="table-container">
      <table className="table is-striped is-hoverable is-narrow">
        <thead>
          <tr>
            <th className="has-text-right">{t("payment-dateOfSettlement")}</th>
            <th>{t("payment-paymentType")}</th>
            <th>{t("payment-isRedistributed")}</th>
            <th>{t("payment-variableSymbol")}</th>
            <th>{t("payment-ammount")}</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              if (limit > 0 && index >= limit) return false;

              return (
                <tr
                  key={index}
                  className={
                    item.IsFuture === true ? "has-text-grey-light" : ""
                  }
                >
                  <th
                    className={`has-text-right ${
                      item.IsFuture === true ? "has-text-grey-light" : ""
                    }`}
                  >
                    {dayjs(item.DateOfSettlement).format(t("date-full"))}
                  </th>

                  <td className="has-text-centered">
                    {item.IsFuture === true ? (
                      <span
                        className="paymentIcon tooltip top"
                        data-tooltip={t("payment-future")}
                      >
                        <i className="fa-regular fa-calendar-clock" />
                      </span>
                    ) : item.IsRedistributed ? (
                      <span
                        className="paymentIcon tooltip top"
                        data-tooltip={t("payment-isRedistributed-yes")}
                      >
                        <i className="fa-regular fa-check-circle" />
                      </span>
                    ) : (
                      <span
                        className="paymentIcon tooltip top"
                        data-tooltip={t("payment-isRedistributed-pending")}
                      >
                        <i className="fa-regular fa-hourglass" />
                      </span>
                    )}
                  </td>

                  <td className="has-text-centered">
                    <PaymentType
                      paymentType={item.PaymentTypeCode}
                      account={`${item.Account.AccountNumberPrefix}-${item.Account.AccountNumber}/${item.Account.BankCode}`}
                    />
                  </td>

                  <td className="has-text-centered">{item.VariableSymbol}</td>

                  <td className="has-text-right">
                    {Intl.NumberFormat(i18n && i18n.language, {
                      style: "currency",
                      currency: "CZK",
                    }).format(item.Amount)}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

PaymentsReceived.propTypes = {
  hasMore: PropTypes.func,
  limit: PropTypes.number,
};
PaymentsReceived.defaultProps = {
  hasMore: null,
  limit: null,
};

export default PaymentsReceived;
