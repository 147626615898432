import * as Yup from "yup";
import dayjs from "dayjs";
import { t } from "i18next";

const r_atLeastOneAlphanum = /(\S).{1,}$/;

export const validationSchema = Yup.object().shape({
  LastName: Yup.string()
    .nullable()
    .test(
      "oneOfRequired",
      t("frm-last-name-company-name-error"),
      function (_item) {
        return (
          (this.parent.LastName &&
            r_atLeastOneAlphanum.test(this.parent.LastName)) ||
          (this.parent.FirmName &&
            r_atLeastOneAlphanum.test(this.parent.FirmName))
        );
      }
    ),
  FirmName: Yup.string()
    .nullable()
    .test(
      "oneOfRequired",
      t("frm-last-name-company-name-error"),
      function (_item) {
        return (
          (this.parent.LastName &&
            r_atLeastOneAlphanum.test(this.parent.LastName)) ||
          (this.parent.FirmName &&
            r_atLeastOneAlphanum.test(this.parent.FirmName))
        );
      }
    ),

  Ic: Yup.string()
    .min(4, t("frm-ic-error"))
    .max(12, t("frm-ic-error"))
    .matches(/^[0-9/]+$/iu, {
      message: t("frm-ic-error"),
      excludeEmptyString: true,
    })
    .nullable(),

  Phone: Yup.object().shape({
    Value: Yup.string()
      .min(8, t("frm-phoneNumber-error"))
      .max(24, t("frm-phoneNumber-error"))
      .matches(/^[0-9+\- ()/]+$/iu, {
        message: t("frm-phoneNumber-error"),
        excludeEmptyString: true,
      })
      .nullable(),
  }),

  Address: Yup.object().shape({
    PostalCode: Yup.string().max(12, t("frm-postalCode-error")),
  }),

  YearOfBirth: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable()
    .min(
      dayjs().subtract(100, "year").format("YYYY"),
      t("frm-birth-year-error")
    )
    .max(dayjs().format("YYYY"), t("frm-birth-year-error")),
});

export default validationSchema;

// .required(t("frm-validation-required")),
