import React, { useContext } from "react";
import UserContext from "../UserContext";
import LayoutBasic from "../Layouts/LayoutBasic";
import styles from "./RecipientRequestSent.module.scss";
import { Button } from "../Components/LinkButton";
import LinkButton from "../Components/LinkButton";
import { HttpPost } from "../HTTP";
import { useTranslation } from "react-i18next";
import RenderMD from "../Components/RenderMd";

const RecipientRequestSent = () => {
  const user = useContext(UserContext);
  const { t } = useTranslation();

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]); // remove prefix "data:application/pdf;base64,"
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const downloadBase64File = (base64Data, fileName) => {
    const link = document.createElement("a")
    link.href = `data:application/pdf;base64,${base64Data}`;
    link.download = fileName;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const getPdf = async () => {
    try {
      const blob = await HttpPost(
        "recipientRequestUserformPdf",
        { Id: user.fullUser.Id },
        false,
        "application/pdf",
        false
      );

      if (blob) {
        const base64String = await blobToBase64(blob);

        downloadBase64File(base64String, "formular-obnoveni-zadosti.pdf");
      } else {
        console.error("No PDF data received");
      }
    } catch (error) {
      console.error("Error downloading PDF", error);
    }
  };

  return (
    <LayoutBasic>
      <div className={styles.wrapper}>
        <h1 className={styles.heading}>
          {t("recipient-request-sent-heading")}
        </h1>
        <div className={styles.content}>
          <RenderMD>{t("recipient-request-sent-first-paragraph")}</RenderMD>

          <RenderMD>{t("recipient-request-sent-second-paragraph")}</RenderMD>

          <div className={styles.buttonWrapper}>
            <Button onClick={() => getPdf()}>
              {t("recipient-request-sent-download-pdf-button")}
            </Button>
          </div>

          <RenderMD>{t("recipient-request-sent-third-paragraph")}</RenderMD>

          <div className={styles.buttonWrapper}>
            <LinkButton to="/prijemce/moje-fotky">
              {t("recipient-request-sent-upload-photos-button")}
            </LinkButton>
          </div>
        </div>
      </div>
    </LayoutBasic>
  );
};

export default RecipientRequestSent;
