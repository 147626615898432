import React, { useEffect, useState, useContext } from "react";
import LayoutMujUcet from "../Layouts/LayoutMujUcet.js";
import { isArray } from "lodash";
import { useFormik, FormikProvider } from "formik";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { usePrompt } from "../Helpers/reactRouter6blocker";
import FormInput, { FormInputGroup } from "../Components/FormInput.js";
import { Button } from "../Components/LinkButton";
import UserContext from "../UserContext.js";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { HttpGet, HttpPost } from "../HTTP.js";
import LoadingIcon from "../Components/LoadingIcon.js";
import dayjs from "dayjs";
import Styles from "./ProfilDarce.module.scss";
import validationSchema from "./ProfilDarce.formValidation.js";
import { oouLink } from "../appConfig.js";

const allFields = [
  "Title",
  "FirstName",
  "LastName",
  "FirmName",
  "Ic",
  "NameForTax",
  "Phone.Value",
  "Address.Street",
  "Address.HouseNumber",
  "Address.City",
  "Address.PostalCode",
  "AgreeWithListDonors",
  "IsConcreteRecipient",
  "AgreeWithReceiveMailInfo",
  "Gender.Id",
  "YearOfBirth",
  "FamilyStatus.Id",
  "Population.Id",
  "ResourceAwareness.Id",
  "ResourceAwarenessOtherwise",
];

const ProfilDarce = () => {
  const user = useContext(UserContext);
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setLoading] = useState(true);
  const [hasErrorLoading, setErrorLoading] = useState(false);
  const [redirToTaxAfterSubmit, setRedirToTaxAfterSubmit] = useState(false);
  const [showTaxInfoBlock, setShowTaxInfoBlock] = useState(
    location?.search === "?taxinfo" ? true : false
  );

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    return new Promise((resolve, reject) => {
      setLoading(true);

      HttpGet("userProfile", false).then((resp) => {
        const formData = resp && resp.response && resp.response.profile_full;
        const errorCode = resp && resp.error_code;
        // console.log({ errorCode, formData, resp });

        if (errorCode !== 200) {
          setErrorLoading(true);
          setLoading(false);
          reject(errorCode);
          return;
        }

        const fieldsToPopulate = allFields;
        fieldsToPopulate.forEach((field) => {
          const fieldP = field.includes(".") ? field.split(".") : field;
          let value = isArray(fieldP)
            ? formData[fieldP[0]]
              ? formData[fieldP[0]][fieldP[1]]
              : ""
            : formData[field];
          if (value === true) value = "true";
          if (value === false) value = "false";

          //console.log({ field, fieldP, value, fieldsToPopulate });
          formik.setFieldValue(field, value, false);
          formik.setFieldTouched(field, false, false);

          if (field === "NameForTax") {
            if (value !== "") {
              setShowTaxInfoBlock(true);
            }
          }
        });

        user.setUserObject(
          user.loginScope,
          resp.response.profile,
          resp.response.profile_full
        );
        setLoading(false);
        setErrorLoading(false);
        resolve();
      });
    });
  };

  const saveData = (data) => {
    return new Promise((resolve, reject) => {
      data.Phone.Id = user.fullUser?.Phone?.Id;

      HttpPost("userProfile", data).then((resp) => {
        const errorCode = resp && resp.error_code;

        if (errorCode !== 200) {
          setLoading(false);

          // TODO: Push error to bugsnag ?
          // const formData = resp && resp.response;
          // console.error({ errorCode, formData, resp });

          reject(errorCode ? errorCode : resp._error);
          return;
        }

        resolve();
      });
    });
  };

  const handleSubmit = (data) => {
    //console.log("submit", { data });
    if (hasErrorLoading) return false;

    setLoading(true);

    saveData(data)
      .then(() => {
        MySwal.fire({
          title: t("frm-notification-saved"),
          icon: "success",
          timer: 2000,
          toast: true,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        loadData();
        setTimeout(() => {
          if (redirToTaxAfterSubmit) {
            navigate("/muj-ucet/danove");
            return true;
          }
        });
      })
      .catch((errorCode) => {
        MySwal.fire({
          title: t("frm-notification-save-error"),
          text: t(`myProfile-save-error-${errorCode}`),
          icon: "error",
          timer: 30000,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: true,
        });
      });
  };

  const handleDanoveUdaje = () => {
    setRedirToTaxAfterSubmit(true);
    setTimeout(() => {
      formik.submitForm();
    });
  };

  const generateOptions = (base, size, allowNull) => {
    const options = [];
    allowNull && options.push({ value: "", label: t(`frm-select-no-value`) });
    for (let idx = 1; idx < size + 1; idx++) {
      options.push({ value: idx, label: t(`${base}--${idx}`) });
    }
    return options;
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit: handleSubmit,
  });

  const { errors, isSubmitting, isValid } = formik;

  useEffect(() => {
    if (isSubmitting === true) {
      if (errors) {
        try {
          const uniqueErrors = Object.values(errors).reduce(
            (ret, item) => (ret.includes(item) ? ret : [...ret, item]),
            []
          );

          if (uniqueErrors.length > 0) {
            MySwal.fire({
              title: t("frm-notification-save-error"),
              html: uniqueErrors.join("<br/>"),
              icon: "error",
              timer: 30000,
              position: "center",
              timerProgressBar: true,
              showConfirmButton: true,
            });
          }
        } catch {
          /* */
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  //console.log(formik.values);
  return (
    <LayoutMujUcet pageTitle={t("frmTitle-public-profile")}>
      <div className={Styles.formDarce}>
        {isLoading && (
          <div className={Styles.loading}>
            <LoadingIcon size={48} />
          </div>
        )}

        {hasErrorLoading && (
          <div>
            <div className="message is-danger">
              <div className="message-header">
                <p>{t("myProfile-error-title")}</p>
              </div>
              <div className="message-body">
                <p>{t("myProfile-error-body")}</p>
                <p>&nbsp;</p>
                <button
                  onClick={loadData}
                  className="button is-fullwidth is-primary is-outlined"
                >
                  {t("button-reload")}
                </button>
              </div>
            </div>
          </div>
        )}

        <FormikProvider value={formik}>
          <form
            onSubmit={formik.handleSubmit}
            className={`${hasErrorLoading ? "hidden" : ""}`}
          >
            <section className={Styles.formBlock}>
              <h2 className="title">{t("frmTitle-public-profile")}</h2>
            </section>

            <FormInputGroup layout=".5fr 1fr 1fr">
              <FormInput
                name="Title"
                label={t("frm-title")}
                className="w-narrow"
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.Title}
              />

              <FormInput
                name="FirstName"
                label={t("frm-first-name")}
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.FirstName}
              />

              <FormInput
                name="LastName"
                label={t("frm-last-name")}
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.LastName}
              />
            </FormInputGroup>

            <FormInputGroup layout="1fr .5fr">
              <FormInput
                name="FirmName"
                icon="briefcase"
                label={t("frm-company-name")}
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.FirmName}
              />

              <FormInput
                name="Ic"
                type="text"
                label={t("frm-ic")}
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.Ic}
              />
            </FormInputGroup>

            {showTaxInfoBlock ? (
              <FormInputGroup layout="1fr" className="is-align-items-flex-end">
                <FormInput
                  name="NameForTax"
                  icon="briefcase"
                  label={t("profil-danove-udaje-edit-title")}
                  description={t("profil-danove-udaje-info")}
                  onChange={formik.handleChange}
                  error={errors}
                  value={formik.values.NameForTax}
                />

                <div
                  className="has-text-centered"
                  style={{ marginBottom: "0.75em" }}
                >
                  <Button
                    onClick={handleDanoveUdaje}
                    style={{
                      whiteSpace: "break-spaces",
                      height: "fit-content",
                    }}
                  >
                    {t("menu-muj-ucet-uprava-danove-udaje")}
                  </Button>
                </div>
              </FormInputGroup>
            ) : (
              <div>
                <Button
                  onClick={() => {
                    setShowTaxInfoBlock(true);
                  }}
                >
                  {t("profil-danove-udaje-edit")}
                </Button>
              </div>
            )}

            <section className={`${Styles.formBlock}`}>
              <h2 className="title">{t("frmTitle-contact")}</h2>
            </section>

            <FormInputGroup layout="1fr 0.5fr">
              <FormInput
                name="Phone.Value"
                icon="phone"
                label={t("frm-phone-number")}
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.Phone?.Value}
              />
            </FormInputGroup>

            <FormInputGroup layout="1fr 0.5fr">
              <FormInput
                name="Address.Street"
                icon="map-signs"
                label={t("frm-address-street")}
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.Address?.Street}
              />

              <FormInput
                name="Address.HouseNumber"
                icon="square-0"
                label={t("frm-address-number")}
                className="w-narrow"
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.Address?.HouseNumber}
              />
            </FormInputGroup>

            <FormInputGroup layout="1fr 0.5fr">
              <FormInput
                name="Address.City"
                icon="city"
                label={t("frm-address-city")}
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.Address?.City}
              />

              <FormInput
                name="Address.PostalCode"
                icon="square-0"
                label={t("frm-address-postal")}
                className="w-narrow"
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.Address?.PostalCode}
              />
            </FormInputGroup>

            <section className={`${Styles.formBlock}`}>
              <h2 className="title">{t("frmTitle-more-about-me")}</h2>
            </section>

            <FormInputGroup layout="1fr 0.5fr">
              <FormInput
                name="Gender.Id"
                type="select"
                label={t("frm-gender")}
                options={generateOptions("frm-gender", 3, true)}
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.Gender?.Id}
              />

              <FormInput
                name="YearOfBirth"
                type="number"
                icon="calendar"
                label={t("frm-birth-year")}
                min={parseInt(dayjs().subtract(100, "year").format("YYYY"), 10)}
                max={parseInt(dayjs().format("YYYY"), 10)}
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.YearOfBirth}
              />
            </FormInputGroup>

            <FormInputGroup cols={2}>
              <FormInput
                name="FamilyStatus.Id"
                type="select"
                label={t("frm-family-status")}
                options={generateOptions("frm-family-status", 5, true)}
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.FamilyStatus?.Id}
              />

              <FormInput
                name="Population.Id"
                type="select"
                label={t("frm-population")}
                options={generateOptions("frm-population", 4, true)}
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.Population?.Id}
              />
            </FormInputGroup>

            <FormInputGroup cols={2}>
              <FormInput
                name="ResourceAwareness.Id"
                type="select"
                label={t("frm-resource-awareness")}
                options={generateOptions("frm-resource-awareness", 7, true)}
                onChange={formik.handleChange}
                error={errors}
                value={formik.values.ResourceAwareness?.Id}
              />

              {parseInt(formik.values.ResourceAwareness?.Id, 10) === 6 ? (
                <FormInput
                  name="ResourceAwarenessOtherwise"
                  label={t("frm-resource-awareness-other")}
                  onChange={formik.handleChange}
                  error={errors}
                  value={formik.values.ResourceAwarenessOtherwise}
                />
              ) : null}
            </FormInputGroup>

            <section className={`${Styles.formBlock} ${Styles.oou}`}>
              <h2 className="title">
                {t("frmTitle-public-allow-publication-title")}
              </h2>

              <p>
                {t("profil-souhlasy-oouBody", {
                  oou_date: dayjs(user?.fullUser?.OOUDate).format(
                    t("date-full")
                  ),
                })}
              </p>
              <p className={Styles.oouLinks}>
                <a href={oouLink} target="_blank" rel="noopener noreferrer">
                  {t("profil-souhlasy-oouOouLink")}
                </a>
                <Link to="/muj-ucet/oou-souhlas">
                  {t("profil-souhlasy-oouRevokeBtn")}
                </Link>
              </p>
            </section>

            <section
              className={`${Styles.formBlock}`}
              style={{ marginTop: "1em" }}
            >
              <FormInputGroup cols={1}>
                <FormInput
                  name="AgreeWithListDonors"
                  type="checkbox"
                  label={t("frmTitle-public-allow-publication")}
                  className="w-block-no-margin"
                  onChange={formik.handleChange}
                  error={errors}
                  value={formik.values.AgreeWithListDonors}
                />
              </FormInputGroup>

              <FormInputGroup cols={1}>
                <p>{t("frmTitle-donation-targeting")}</p>

                <FormInput
                  name="IsConcreteRecipient"
                  type="toggle"
                  labelTrue={t("frm-donation-targeting-single")}
                  labelFalse={t("frm-donation-targeting-multiple")}
                  onChange={formik.handleChange}
                  error={errors}
                  value={formik.values.IsConcreteRecipient}
                />
              </FormInputGroup>

              <FormInputGroup cols={1}>
                <FormInput
                  name="AgreeWithReceiveMailInfo"
                  type="checkbox"
                  label={t("frmTitle-allow-newsletter")}
                  className="w-block"
                  onChange={formik.handleChange}
                  error={errors}
                  value={formik.values.AgreeWithReceiveMailInfo}
                />
              </FormInputGroup>
            </section>

            <div className="field has-text-centered">
              <div className="control">
                <Button type="submit" disabled={!isValid}>
                  {t("frm-button-submit")}
                </Button>
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>
    </LayoutMujUcet>
  );
};

export default ProfilDarce;
