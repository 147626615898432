import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import renderHTML from "html-react-parser";

import LayoutObnoveniZadostiV2 from "../Layouts/LayoutObnoveniZadostiV2.js";

import PrijemceMojeUdaje from "./PrijemceMojeUdaje.js";
import PrijemceMojeUdajeKontakty from "./PrijemceMojeUdajeKontakty.js";

import Styles from "./ObnoveniZadostiV2.module.scss";
import { useEffect } from "react";
import { HttpGet, HttpPost } from "../HTTP.js";
import UserContext from "../UserContext.js";
import FormJson from "../Components/FormJson.js";
import { useTranslation } from "react-i18next";
import { Button } from "../Components/LinkButton.js";
import {
  retrieveFilesFromIndexedDB,
  storeFilesInIndexedDB,
  deleteDatabase,
} from "../Helpers/fileStorage.js";

// import MockObnData from '../Helpers/mockObnData.json';
import UploadDrawer from "../Components/UploadDrawer.js";
import FormInput from "../Components/FormInput.js";
import Loading from "../Components/Loading.js";

import RenderMD from "../Components/RenderMd.js";

const fieldTypes = {
  financialSituation: {
    itemType: "number",
  },
  numeric: {
    itemType: "number",
  },
  paragraph: {
    itemType: "text",
    multiline: true,
  },
};

const offsetSection = 1;

export default function ObnoveniZadostiV2() {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);

  const [sections, setSections] = useState([]);
  const [plainFormData, setPlainFormData] = useState(null);
  const [lastSection, setLastSection] = useState(1 + offsetSection);
  const [isLoading, setIsLoading] = useState(true);
  const [personsCount, setPersonsCount] = useState(0);
  const [fileTypes, setFileTypes] = useState([]);
  const [currentGroupFileSize, setCurrentGroupFileSize] = useState(0);
  const [sectionData, setSectionData] = useState({
    section: 0, //current active section
    data: {}, //values
  });

  const [finalDesc, setFinalDesc] = useState({ value: "" });
  const handleFinalDescChange = (e) => setFinalDesc({ value: e.target.value });
  const [files, setFiles] = useState([]);
  const [isFileDrawerOpen, setIsFileDrawerOpen] = useState(false);

  const [availableFiles, setAvailableFiles] = useState({
    required: [],
    optional: [],
  });
  const [selectedFiles, setSelectedFiles] = useState({
    required: [],
    optional: [],
  });

  useEffect(() => {
    if (user.fullUser) {
      if (
        user.fullUser.RecipientStatusOfRequest.Id !== 4 &&
        user.fullUser.RecipientStatusOfRequest.Id !== 2
      ) {
        // MySwal.fire({
        //   title: t("frm-obn-request-already-sent"),
        //   icon: "warning",
        //   backdrop: true,
        //   confirmButtonText: "Zpět",
        // }).finally(() => {
        //   navigate("/prijemce/obnoveni-zadosti/odeslano");
        // });
        navigate("/prijemce/obnoveni-zadosti/odeslano");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.fullUser]);

  const handleNextStep = (data, sectionToSet) => {
    setIsLoading(true);
    // VALIDATION OF CURRENT PATIENT STATE
    if (data["formIdx-102"] === "false" || data["formIdx-102"] === false) {
      if (
        data["formIdx-103"] === undefined ||
        data["formIdx-103"] === null ||
        data["formIdx-103"] === ""
      ) {
        MySwal.fire({
          title: t("frm-obn-202-201-error"),
          icon: "error",
          timer: 2000,
          toast: true,
        });
        setIsLoading(false);
        return;
      }
    }
    // VALIDATION OF TABLE SUM FOR EXPENSES
    if (data["sum-938"] !== undefined && data["sum-938"] === 0) {
      setIsLoading(false);
      MySwal.fire({
        title: t("frm-obn-sum-938-error"),
        icon: "error",
        timer: 2000,
        toast: true,
      });
      return;
    }

    const validationScheme = collectAllItems(
      plainFormData.sections[sectionData.section]
    );

    if (validationScheme) {
      for (let s of validationScheme) {
        // TABLE VALIDATION
        if (s.itemType === "table") {
          for (let row of s.rows) {
            let previousCell = row.cells[0];
            for (let cell of row.cells) {
              if (
                cell.item.required &&
                (data[`formIdx-${cell.item.id}`] === "" ||
                  data[`formIdx-${cell.item.id}`] === null)
              ) {
                setIsLoading(false);
                MySwal.fire({
                  title: t("frm-validation-required"),
                  icon: "error",
                  timer: 2000,
                  html: (
                    <div>
                      <p>Vyplňte prosím pole</p>
                      <p style={{ fontWeight: "bold" }}>{previousCell.text}</p>
                    </div>
                  ),
                  toast: true,
                });
                return;
              }
              previousCell = cell.item;
            }
          }
        }
        if (
          s.required &&
          (data[`formIdx-${s.id}`] === "" || data[`formIdx-${s.id}`] === null)
        ) {
          setIsLoading(false);
          MySwal.fire({
            title: t("frm-validation-required"),
            icon: "error",
            timer: 2000,
            html: (
              <div>
                <p>Vyplňte prosím pole</p>
                <p style={{ fontWeight: "bold" }}>{s.title}</p>
              </div>
            ),
            toast: true,
          });
          return;
        }

        if (
          s.maxLength &&
          data[`formIdx-${s.id}`] &&
          data[`formIdx-${s.id}`].length > s.maxLength
        ) {
          setIsLoading(false);
          MySwal.fire({
            title: t("frm-maxlength-error"),
            icon: "error",
            timer: 2000,
            html: (
              <div>
                <p>Maximální délka pole je {s.maxLength} znaků</p>
                <p style={{ fontWeight: "bold" }}>{s.title}</p>
              </div>
            ),
            toast: true,
          });
          return;
        }

        // DATETIME VALIDATION
        if (s.itemType === "dateTime") {
          const value = data[`formIdx-${s.id}`];
          const date = new Date(value);
          const now = new Date();
          if (date.getFullYear() > now.getFullYear()) {
            setIsLoading(false);
            MySwal.fire({
              title: t("frm-validation-date"),
              icon: "error",
              timer: 2000,
              html: (
                <div>
                  <p style={{ fontWeight: "bold" }}>{s.title}</p>
                </div>
              ),
              toast: true,
            });
            return;
          }
        }
      }
    }

    const payloadData = fillFormData(plainFormData, {
      ...sectionData.data,
      "formIdx-602": finalDesc.value,
    });
    payloadData.sections[sectionData.section].completed = true;
    if (isLastSection) {
      // eslint-disable-next-line no-restricted-globals
      handleSaveData(payloadData, true, sectionToSet);
    } else {
      if (sectionData.section !== 0) {
        handleSaveData(payloadData, false, sectionToSet);
      }
      // setSectionData({
      //     section: sectionData.section + 1,
      //     data: sectionData.data
      // })
    }
    if (sectionData.section === lastSection) {
      setLastSection(lastSection + 1);
    }
    setIsLoading(false);
  };

  // Get request date and add 6 months and 15 days
  const getEndDate = () => {
    const _date = user.fullUser.RequestDate;
    const date = new Date(_date);
    date.setMonth(date.getMonth() + 6);
    date.setDate(date.getDate() + 15);
    const _endDate = date.toISOString();
    return _endDate.split("T")[0].split("-").reverse().join(". ");
  };

  function fillFormData(structure, formData) {
    // console.log(formData)
    const safeParseJSON = (jsonString) => {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        // console.error(e)
        return {};
      }
    };

    const updateQuestionValue = (questionStr, value, item) => {
      // VALIDATE TYPES
      if (!isNaN(value) && value !== false && value !== true) {
        value = parseInt(value);
      }
      if (value === "true") {
        value = true;
      }
      if (value === "false") {
        value = false;
      }

      let questionObj = safeParseJSON(questionStr);
      if (questionObj === null || typeof questionObj !== "object") {
        questionObj = {};
      }

      if (
        item &&
        item.itemType === "text" &&
        typeof value === "number" &&
        value !== "" &&
        value !== null &&
        isNaN(value) === false
      ) {
        value = value.toString();
      }

      questionObj.value = value === undefined || value === null ? "" : value;
      return JSON.stringify(questionObj);
    };

    if (Array.isArray(structure)) {
      return structure.map((element) => fillFormData(element, formData));
    }

    if (structure.tables) {
      structure.tables = structure.tables.map((table) => {
        if (table.header && table.header.cells) {
          table.header.cells = fillFormData(table.header.cells, formData);
        }
        if (table.rows) {
          table.rows = table.rows.map((row) => {
            if (row.cells) {
              row.cells = fillFormData(row.cells, formData);
            }
            return row;
          });
        }
        return table;
      });
    }

    if (structure.sections) {
      structure.sections = fillFormData(structure.sections, formData);
    }

    if (structure.items) {
      structure.items = structure.items.map((item) => {
        const formKey = `formIdx-${item.id}`;
        if (formData[formKey] !== undefined) {
          item.question = updateQuestionValue(item.question, formData[formKey]);
        }
        return item;
      });
    }

    if (
      structure.item &&
      formData[`formIdx-${structure.item.id}`] !== undefined
    ) {
      structure.item.question = updateQuestionValue(
        structure.item.question,
        formData[`formIdx-${structure.item.id}`],
        structure.item
      );
    }

    return structure;
  }

  const handlePreviousStep = () => {
    if (isFirstSection) return;
    setSectionData({
      section: sectionData.section - 1,
      data: sectionData.data,
    });
  };

  const getAllFileSizes = () => {
    let totalSize = 0;
    for (let s of availableFiles.required) {
      if (s.files) {
        for (let f of s.files) {
          totalSize += f.size;
        }
      }
    }

    for (let s of availableFiles.optional) {
      if (s.files) {
        for (let f of s.files) {
          totalSize += f.size;
        }
      }
    }
    return totalSize / 1024 / 1024;
  };

  const handleLoadData = (sectionToSet) => {
    setIsLoading(true);

    Promise.all([
      HttpGet(
        `getRecipientRequestDocuments?${new Date().toISOString()}`,
        true,
        undefined,
        false
      ),
      HttpGet(
        `getRecipientRequestUserform/${
          user.fullUser.Id
        }?${new Date().toISOString()}`,
        true,
        undefined,
        false
      ),
      HttpGet(
        `recipientProfile?${new Date().toISOString()}`,
        true,
        undefined,
        false
      ),
    ])
      .then(async ([resp1, resp2, resp3]) => {
        if (
          resp1.error_code !== 200 ||
          resp2.error_code !== 200 ||
          resp3.error_code !== 200
        ) {
          MySwal.fire({
            title: t("frm-unexpected-error"),
            icon: "error",
            timer: 2000,
            toast: true,
          });
          return;
        } else {
          const _availableFiles = {
            required: resp1.response.Data.DocumentProcTypes.filter(
              (item) => item.Key !== 13
            ),
            optional: resp1.response.Data.DocumentProcTypesOptional,
          };
          const _fileTypes = [
            ...resp1.response.Data.DocumentProcTypes,
            ...resp1.response.Data.DocumentProcTypesOptional,
          ]
            .filter((item) => item.Key !== 13)
            .map((item) => {
              return {
                value: item.Key,
                label: item.Value,
              };
            });
          const storedFiles = await retrieveFilesFromIndexedDB();
          if (storedFiles) {
            setAvailableFiles(storedFiles);
          } else {
            setAvailableFiles(_availableFiles);
          }
          setFileTypes(_fileTypes);
          const data = resp2.response.Data;
          const _sections = data.sections;

          insertAt(_sections, 1, {
            title: "Základní údaje",
            completed: true,
            key: "user-informations",
            deleteBeforeUpload: true,
            sections: [],
            table: [],
            render: () => (
              <PrijemceMojeUdaje
                useLayout={false}
                btnLabel={"Pokračovat"}
                btnOnClick={() =>
                  setSectionData({
                    section: 2,
                    data: sectionData.data,
                  })
                }
              />
            ),
          });

          setPlainFormData({ ...data, sections: _sections });
          const lastFilledSection = findLastFilledItemIndex(data);
          if (sectionToSet) {
            setSectionData({
              section: sectionToSet,
              data: {},
            });
          } else {
            if (lastFilledSection !== -1) {
              setLastSection(lastFilledSection + offsetSection);

              if (sectionData.section === 0) {
                setSectionData({
                  section: 1,
                  data: {},
                });
              } else {
                setSectionData({
                  section: sectionData.section + 1,
                  data: {},
                });
              }
            } else {
              setLastSection(2);
            }
          }

          const requestStatus = resp3.response.RecipientStatusOfRequest.Id || 0;

          const availableStatuses = [2, 4, 8, 9];

          if (!availableStatuses.includes(requestStatus)) {
            // MySwal.fire({
            //   title: t("frm-obn-request-already-sent"),
            //   icon: "warning",
            //   timer: 2000,
            //   toast: true,
            // });
            navigate("/prijemce/obnoveni-zadosti/odeslano");
          }
        }
      })
      .catch((error) => {
        console.error(error);
        MySwal.fire({
          title: t("frm-unexpected-error"),
          icon: "error",
          timer: 2000,
          toast: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Utility to ensure data is in File format
  function ensureFileObject(data, fileName, fileType) {
    if (data instanceof File) {
      return data; // Already a File object, no conversion needed
    } else if (data instanceof ArrayBuffer) {
      return new File([data], fileName, { type: fileType });
    } else {
      console.error("Unsupported data type for file conversion:", data);
      throw new Error("Unsupported data type");
    }
  }

  const handleFileUploaded = async ({
    personId,
    documentType,
    file,
    documentTypeLabel,
  }) => {
    const fileSizes = getAllFileSizes();
    const newFileSizeMB = file.size / 1024 / 1024;

    if (fileSizes + newFileSizeMB > 30) {
      MySwal.fire({
        title: t("frm-obn-file-size-error"),
        icon: "error",
        timer: 2000,
        toast: true,
      });
      return;
    }

    setCurrentGroupFileSize(fileSizes + newFileSizeMB);

    // Convert file to File object if necessary
    file = ensureFileObject(file, file.name, file.type);

    const updateFiles = (filesArray, key) => {
      const index = filesArray.findIndex(
        (item) => item.Key === parseInt(documentType)
      );
      if (index !== -1) {
        const existingItem = filesArray[index];
        const updatedItem = {
          ...existingItem,
          files: existingItem.files ? [...existingItem.files, file] : [file],
        };
        return [
          ...filesArray.slice(0, index),
          updatedItem,
          ...filesArray.slice(index + 1),
        ];
      }
      return filesArray;
    };

    const newFiles = {
      required: updateFiles(availableFiles.required, "required"),
      optional: updateFiles(availableFiles.optional, "optional"),
    };

    await storeFilesInIndexedDB(newFiles);
    const getFiles = await retrieveFilesFromIndexedDB();
    setAvailableFiles(getFiles);
    setSelectedFiles(newFiles);
  };

  function stringifyQuestions(data) {
    if (data.sections && Array.isArray(data.sections)) {
      data.sections.forEach((section) => {
        stringifyQuestions(section);
      });
    }

    if (data.items && Array.isArray(data.items)) {
      data.items.forEach((item) => {
        if (
          item.question &&
          typeof item.question === "object" &&
          item.id !== 601
        ) {
          item.question = JSON.stringify(item.question);
        }
      });
    }

    return data;
  }

  const fileValidation = () => {
    const requiredFiles = availableFiles.required;

    for (let s of requiredFiles) {
      if (!s.isSelected) {
        if (!s.files || s.files.length === 0) {
          return false;
        }
      }
    }

    return true;
  };

  // API REQEUSTS
  const handleSaveData = (data, isCompleted, sectionToSet) => {
    const newData = stringifyQuestions(data);

    newData.sections[0].completed = true;
    const payloadData = {
      ...newData,
      sections: newData.sections.filter(
        (sect) => sect.deleteBeforeUpload !== true
      ),
    };

    const newPayloadData = {
      ...payloadData,
      sections: payloadData.sections.map((section) => {
        return {
          ...section,
          items: section.items.map((item) => {
            const newValue = JSON.stringify(finalDesc);
            if (
              (typeof item.question === "string" && item.id === 602) ||
              item.id === 601
            ) {
              item = { ...item, question: newValue };
            }
            return item;
          }),
        };
      }),
    };

    const payload = {
      Completed: isCompleted,
      PersonId: user.personId,
      Id: user.fullUser.Id,
      RequestedDocuments: {
        Documents: availableFiles.required.map((item) => {
          const DeliveredOtherwise = item.isSelected
            ? item.isSelected + "" === "true"
              ? true
              : false
            : false;
          const isFileDelivered =
            (item.files && item.files.length > 0) === undefined
              ? false
              : item.files.length > 0;
          return {
            Id: item.Key,
            Delivered: isFileDelivered,
            DeliveredOtherwise: DeliveredOtherwise,
            Note: item.Value,
            Files: item.files || [],
          };
        }),
        DocumentsOptional: availableFiles.optional.map((item) => {
          const DeliveredOtherwise = item.isSelected
            ? item.isSelected + "" === "true"
              ? true
              : false
            : false;
          const isFileDelivered =
            (item.files && item.files.length > 0) === undefined
              ? false
              : item.files.length > 0;
          return {
            Id: item.Key,
            Delivered: isFileDelivered,
            DeliveredOtherwise: DeliveredOtherwise,
            Note: item.Value,
            Files: item.files || [],
          };
        }),
      },
      Data: newPayloadData,
    };

    if (isCompleted) {
      if (!fileValidation()) {
        MySwal.fire({
          title: t("frm-obn-required-files-error"),
          icon: "error",
          timer: 2000,
          toast: true,
        });
        return;
      }
      setIsLoading(true);
      MySwal.fire({
        title: t("frm-submit-obn-form-confirm"),
        text: t("frm-submit-obn-form-confirm-text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("frm-submit-obn-form-confirm-yes"),
        cancelButtonText: t("frm-submit-obn-form-confirm-no"),
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          const fileUpload = await handleUploadAllFiles();

          if (!fileUpload) {
            MySwal.fire({
              title: t("frm-obn-upload-files-error"),
              icon: "error",
              timer: 2000,
              toast: true,
            });
            setIsLoading(false);
            return;
          }

          deleteDatabase().catch((err) => console.error(err));

          HttpPost(
            `recipientRequestUserformUpdate/${user.fullUser.Id}`,
            payload,
            true,
            undefined,
            false
          ).then((resp) => {
            user.refetchUser();
            if (resp.error_code === 200) {
              setIsLoading(false);
              MySwal.fire({
                title: t("frm-obn-form-sent"),
                icon: "success",
                timer: 2000,
                toast: true,
                position: "center",
                timerProgressBar: true,
                showConfirmButton: false,
              });
              localStorage.removeItem("obn-form-stored-files");
              navigate("/prijemce/obnoveni-zadosti/odeslano");
            } else {
              setIsLoading(false);
              MySwal.fire({
                title: t("frm-obn-form-error"),
                icon: "error",
                timer: 2000,
                toast: true,
                position: "center",
                timerProgressBar: true,
                showConfirmButton: false,
              });
            }
            // handleLoadData()
            setIsLoading(false);
          });
        }
      });
    } else {
      setIsLoading(true);
      HttpPost(
        `recipientRequestUserformUpdate/${user.fullUser.Id}`,
        payload,
        true,
        undefined,
        false
      ).then((resp) => {
        if (resp.error_code === 200) {
          MySwal.fire({
            title: t("frm-obn-form-saved"),
            icon: "success",
            timer: 2000,
            toast: true,
            position: "center",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          if (isCompleted) {
            navigate("/prijemce/obnoveni-zadosti/odeslano");
          }
        } else {
          MySwal.fire({
            title: t("frm-obn-form-saved-error"),
            icon: "error",
            timer: 2000,
            toast: true,
            position: "center",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
        setIsLoading(false);
        handleLoadData(sectionToSet);
      });
    }
  };

  function findLastFilledItemIndex(data) {
    const _sections = data.sections;
    for (let s of _sections) {
      if (!s.completed) {
        return _sections.indexOf(s) - 1;
      }
    }
  }

  useEffect(() => {
    handleLoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (plainFormData) {
      const getLastSection = findLastFilledItemIndex(plainFormData);
      const lastFilledSection = getLastSection !== -1 ? getLastSection : 1;
      const newSections = handleSectionLogic(plainFormData.sections);
      setSections(newSections);
      if (lastFilledSection && plainFormData.sections[lastFilledSection]) {
        setLastSection(lastFilledSection);
      } else {
        setLastSection(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plainFormData]);

  useEffect(() => {
    if (plainFormData) {
      const newSections = handleSectionLogic(plainFormData.sections);
      setSections(newSections);
    }
    if (sectionData.section === 2) {
      const data = collectAllItems(plainFormData.sections[sectionData.section]);
      if (data) {
        setPersonsCount(data[0]?.question?.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionData.section]);

  const handleSectionLogic = (sections) => {
    const lastFilledSection =
      findLastFilledItemIndex(plainFormData) === -1
        ? offsetSection
        : findLastFilledItemIndex(plainFormData);
    sections = sections.map((section, index) => {
      return {
        title: section.title,
        key: section.key,
        render: () => {
          if (index === 1) {
            return (
              <PrijemceMojeUdajeKontakty
                useLayout={false}
                value={personsCount}
                onChange={setPersonsCount}
              />
            );
          } else {
            return null;
          }
        },
        isNextStep: lastFilledSection + 1 === index,
        completed: index <= 2 ? true : section.completed,
      };
    });

    return sections;
  };

  const handleSectionClick = (section) => {
    setIsLoading(true);
    const data = sectionData.data;
    // VALIDATION OF CURRENT PATIENT STATE
    if (data["formIdx-102"] === "false" || data["formIdx-102"] === false) {
      if (
        data["formIdx-103"] === undefined ||
        data["formIdx-103"] === null ||
        data["formIdx-103"] === ""
      ) {
        MySwal.fire({
          title: t("frm-obn-202-201-error"),
          icon: "error",
          timer: 2000,
          toast: true,
        });
        setIsLoading(false);
        return;
      }
    }
    // VALIDATION OF TABLE SUM FOR EXPENSES
    if (data["sum-938"] !== undefined && data["sum-938"] === 0) {
      setIsLoading(false);
      MySwal.fire({
        title: t("frm-obn-sum-938-error"),
        icon: "error",
        timer: 2000,
        toast: true,
      });
      return;
    }

    const validationScheme = collectAllItems(
      plainFormData.sections[sectionData.section]
    );

    if (validationScheme) {
      for (let s of validationScheme) {
        // TABLE VALIDATION
        if (s.itemType === "table") {
          for (let row of s.rows) {
            let previousCell = row.cells[0];
            for (let cell of row.cells) {
              if (
                cell.item.required &&
                (data[`formIdx-${cell.item.id}`] === "" ||
                  data[`formIdx-${cell.item.id}`] === null)
              ) {
                setIsLoading(false);
                MySwal.fire({
                  title: t("frm-validation-required"),
                  icon: "error",
                  timer: 2000,
                  html: (
                    <div>
                      <p>Vyplňte prosím pole</p>
                      <p style={{ fontWeight: "bold" }}>{previousCell.text}</p>
                    </div>
                  ),
                  toast: true,
                });
                return;
              }
              previousCell = cell.item;
            }
          }
        }
        if (
          s.required &&
          (data[`formIdx-${s.id}`] === "" || data[`formIdx-${s.id}`] === null)
        ) {
          setIsLoading(false);
          MySwal.fire({
            title: t("frm-validation-required"),
            icon: "error",
            timer: 2000,
            html: (
              <div>
                <p>Vyplňte prosím pole</p>
                <p style={{ fontWeight: "bold" }}>{s.title}</p>
              </div>
            ),
            toast: true,
          });
          return;
        }

        if (
          s.maxLength &&
          data[`formIdx-${s.id}`] &&
          data[`formIdx-${s.id}`].length > s.maxLength
        ) {
          setIsLoading(false);
          MySwal.fire({
            title: t("frm-maxlength-error"),
            icon: "error",
            timer: 2000,
            html: (
              <div>
                <p>Maximální délka pole je {s.maxLength} znaků</p>
                <p style={{ fontWeight: "bold" }}>{s.title}</p>
              </div>
            ),
            toast: true,
          });
          return;
        }

        // DATETIME VALIDATION
        if (s.itemType === "dateTime") {
          const value = data[`formIdx-${s.id}`];
          const date = new Date(value);
          const now = new Date();
          if (date.getFullYear() > now.getFullYear()) {
            setIsLoading(false);
            MySwal.fire({
              title: t("frm-validation-date"),
              icon: "error",
              timer: 2000,
              html: (
                <div>
                  <p style={{ fontWeight: "bold" }}>{s.title}</p>
                </div>
              ),
              toast: true,
            });
            return;
          }
        }
      }
    }

    const payloadData = fillFormData(plainFormData, {
      ...sectionData.data,
      "formIdx-602": finalDesc.value,
    });
    payloadData.sections[sectionData.section].completed = true;

    setSectionData({
      section: section,
      data: {},
    });
  };

  // FORM METHODS
  function collectAllItems(data) {
    let allItems = [];

    if (data.items && Array.isArray(data.items)) {
      allItems.push(...data.items);
    }

    if (data.sections && Array.isArray(data.sections)) {
      data.sections.forEach((section) => {
        allItems = allItems.concat(collectAllItems(section));
      });
    }

    if (data.tables && Array.isArray(data.tables) && data.tables.length > 0) {
      data.tables.forEach((table) => {
        if (table) {
          allItems.push({ itemType: "table", ...table });
        }
      });
    }

    allItems.forEach((item, index, array) => {
      if (item.question && typeof item.question === "string") {
        item.question = JSON.parse(item.question);
      }
      if (fieldTypes[item.itemType]) {
        array[index] = { ...item, ...fieldTypes[item.itemType] };
      }
    });

    return allItems;
  }

  const handleBegin = () => {
    setSectionData({
      data: sectionData.data,
      section: 1,
    });
  };

  const handleFileSelect = (type, group, value) => {
    const _group = availableFiles[type].find((item) => item.Value === group);
    _group.isSelected = value;
    const newFileGroup = availableFiles[type].map((item) =>
      item.Value === group ? _group : item
    );
    setAvailableFiles({ ...availableFiles, [type]: newFileGroup });
  };

  const generateSectionFiles = (items, value) => {
    const getInputs = collectAllItems(
      plainFormData.sections[plainFormData.sections.length - 1]
    );
    const descFiled = getInputs[1];
    const descFieldProps = {
      name: `formIdx-${descFiled.id}`,
      type: "textarea",
      label: descFiled.title,
      multiline: true,
      value: finalDesc.value,
      onChange: handleFinalDescChange,
    };

    const injectHTML = `
            <div style="margin-top:20px">
                ${getInputs[0].text}
            </div>
        `;
    return (
      <div>
        <p style={{ fontWeight: "bold", fontSize: "1.8em" }}>Nahrání souborů</p>
        {renderHTML(injectHTML)}

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <Button onClick={() => setIsFileDrawerOpen(true)} variant={"filled"}>
            {t("frm-obn-upload-files")}
          </Button>
        </div>

        <p style={{ fontWeight: "bold", marginTop: 20, marginBottom: 20 }}>
          Povinné dokumenty
        </p>
        <div>
          <table
            className="table is-bordered is-striped is-narrow is-fullwidth"
            key={`table-required`}
          >
            <tbody>
              {availableFiles.required.map((item, index) => (
                <tr key={`row-required-file-${index}`}>
                  <td key={`row-required-typename-${index}`}>
                    <p style={{ fontWeight: "bold" }}>{item.Value}</p>
                  </td>
                  <td
                    key={`row-required-filename-${index}`}
                    style={{ minWidth: 140 }}
                  >
                    <div>
                      {item.files &&
                        item.files.map((file, fileIdx) => (
                          <div
                            key={`required-${index}-${fileIdx}`}
                            style={{
                              display: "flex",
                              gap: 10,
                              alignItems: "center",
                            }}
                          >
                            <p>{file.name}</p>
                            <i
                              className="fa-solid fa-trash text-primary cursor-pointer"
                              onClick={() =>
                                handleRemoveFile(
                                  "required",
                                  item.Value,
                                  fileIdx
                                )
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </td>
                  <td key={`row-required-check-${index}`}>
                    <FormInput
                      name={`required-isFileSelected-${index}}`}
                      type="radio"
                      label={"Předáno jinak"}
                      onChange={(e) =>
                        handleFileSelect(
                          "required",
                          item.Value,
                          e.target.value === "true" ? true : false
                        )
                      }
                      value={item.isSelected || false}
                      checked={item.isSelected || false}
                      options={[
                        { value: true, label: "Ano" },
                        { value: false, label: "Ne" },
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p style={{ fontWeight: "bold", marginTop: 20, marginBottom: 20 }}>
          Nepovinné dokumenty
        </p>
        <div>
          <table
            className="table is-bordered is-striped is-narrow is-fullwidth"
            key={`table-optional`}
          >
            <tbody>
              {availableFiles.optional.map((item, index) => (
                <tr key={`row-optional-file-${index}`}>
                  <td key={`row-optional-typename-${index}`}>
                    <p style={{ fontWeight: "bold" }}>{item.Value}</p>
                  </td>
                  <td
                    key={`row-optional-filename-${index}`}
                    style={{ minWidth: 140 }}
                  >
                    <div>
                      {item.files &&
                        item.files.map((file, fileIdx) => (
                          <div
                            key={`optional-${index}-${fileIdx}`}
                            style={{
                              display: "flex",
                              gap: 10,
                              alignItems: "center",
                            }}
                          >
                            <p>{file.name}</p>
                            <i
                              className="fa-solid fa-trash text-primary cursor-pointer"
                              onClick={() =>
                                handleRemoveFile(
                                  "optional",
                                  item.Value,
                                  fileIdx
                                )
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </td>
                  <td key={`row-required-check-${index}`}>
                    {/* <FormInput
                                            name={`isFileSelected-${item.Value}`}
                                            type="radio"
                                            label={"Předáno jinak"}
                                            onChange={(e) => handleFileSelect('optional', item.Value, e.target.checked)}
                                            value={item.isSelected || false}
                                        /> */}
                    <FormInput
                      name={`optional-isFileSelected-${index}}`}
                      type="radio"
                      label={"Předáno jinak"}
                      onChange={(e) =>
                        handleFileSelect(
                          "optional",
                          item.Value,
                          e.target.value === "true" ? true : false
                        )
                      }
                      value={item.isSelected || false}
                      checked={item.isSelected || false}
                      options={[
                        { value: true, label: "Ano" },
                        { value: false, label: "Ne" },
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ marginTop: 40 }}>
          <FormInput {...descFieldProps} />
        </div>
      </div>
    );
  };

  const handleRemoveFile = async (type, group, index) => {
    const _group = availableFiles[type].find((item) => item.Value === group);

    if (!_group) {
      console.error("Group not found:", group);
      return;
    }

    const filteredGroup = _group.files.filter(
      (file, _index) => index !== _index
    );
    _group.files = filteredGroup;

    const newFiles = {
      ...availableFiles,
      [type]: availableFiles[type].map((item) =>
        item.Value === group ? _group : item
      ),
    };

    await storeFilesInIndexedDB(newFiles);
    setAvailableFiles(newFiles);
  };

  const handleFillFormPersonsCount = (sectionToSet) => {
    if (personsCount <= 0) {
      MySwal.fire({
        title: t("frm-obn-persons-length"),
        icon: "error",
        timer: 2000,
        toast: true,
      });
      return;
    }
    const data = fillFormData(plainFormData, { "formIdx-801": personsCount });
    data.sections[2].completed = true;
    handleSaveData(data, false, sectionToSet);
  };

  const handleUploadAllFiles = async () => {
    const convertToFile = (content, fileName, mimeType) => {
      let blob;
      if (content instanceof Blob) {
        blob = content;
      } else {
        blob = new Blob([new Uint8Array(content)], { type: mimeType });
      }
      return new File([blob], fileName, { type: mimeType });
    };

    const processFiles = (files, typeKey) => {
      if (files) {
        for (let f of files) {
          if (f.content && f.name && f.type) {
            const fileObject = convertToFile(f.content, f.name, f.type);
            allFiles.push({ type: typeKey, file: fileObject });
          } else {
            allFiles.push({ type: typeKey, file: f });
          }
        }
      }
    };
    const allFiles = [];
    for (let s of availableFiles.required) {
      processFiles(s.files, s.Key);
    }

    for (let s of availableFiles.optional) {
      processFiles(s.files, s.Key);
    }

    setIsLoading(true);
    let finalState = true;
    for (let f of allFiles) {
      const formData = new FormData();
      formData.append("Id", user.fullUser.Id);
      formData.append("File", f.file);
      formData.append("DocumentType", f.type);
      await HttpPost(
        `recipientRequestFormFileUpload`,
        formData,
        false,
        "multipart/form-data"
      ).then((resp) => {
        if (resp.error_code !== 200) {
          setIsLoading(false);
          MySwal.fire({
            title: t("frm-obn-file-upload-error"),
            icon: "error",
            timer: 2000,
            toast: true,
          });
          finalState = false;
          return;
        }
      });
    }
    return finalState;
  };

  const generateSection = (section) => {
    const data = collectAllItems(plainFormData.sections[section]);
    const _section = plainFormData.sections[section];
    if (section === 2) {
      return (
        <div>
          <PrijemceMojeUdajeKontakty
            useLayout={false}
            value={personsCount}
            onChange={(e) => setPersonsCount(e.target.value)}
          />
        </div>
      );
    }

    if (section === plainFormData.sections.length - 1) {
      return generateSectionFiles(availableFiles, selectedFiles);
    }

    if (_section.render) {
      return _section.render();
    } else if (section === 0 && data[0]) {
      const newHTMLInject = data[0].text
        .replace("</p>", "</p><br/>")
        .replace("</p><p></p><p>", "</p><br/><p>")
        .replace(
          "{DOC_REQUIRED}",
          `<div style="margin-left:30px;margin-bottom:20px">${availableFiles.required
            .map((item) => item.Value)
            .join("<br/> ")}</div>`
        )
        .replace(
          "{DOC_OPTIONAL}",
          `<div style="margin-left:30px;margin-bottom:20px;">${availableFiles.optional
            .map((item) => item.Value)
            .join("<br/> ")}</div>`
        )
        .replace("{DATE}", getEndDate());
      const updatedInject = `
            <div style="margin-top:20px">
            <h3 style="font-size: 1.8em;font-weight: bold;margin-bottom: 10px">Informace k obnovení žádosti</h3>
                ${newHTMLInject}
            </div>
        `;
      return renderHTML(updatedInject);
    } else {
      return (
        <FormJson
          blueprint={data}
          removeButtons
          isLoading={isLoading}
          // useButtonMenu
          // gobackButtonLabel={"Zpět"}
          // submitButtonLabel={isLastSection ? "Uložit" : "Pokračovat"}
          isGoBackDisabled={isFirstSection}
          // onGoBackClick={handlePreviousStep}
          onChange={(e) => setSectionData({ section: section, data: e })}
          // onSubmit={handleNextStep}
        />
      );
    }
  };

  // UTILS
  function insertAt(array, index, element) {
    array.splice(index, 0, element);
    return array;
  }

  // RENDER PROPS
  const isLastSection =
    plainFormData && sectionData.section === plainFormData.sections.length - 1;
  const isFirstSection = sectionData.section === 0;

  const handleChangeStep = (sectionToSet) => {
    handleNextStep(sectionData.data, sectionToSet);
  };

  // Revenues descriptipn - section no. 4
  const revenuesSectionDescription = (
    <div className={Styles.sectionDescription}>
      <RenderMD>{t("frm-obn-revenues-description")}</RenderMD>
    </div>
  );

  // Expenses description - section no. 5
  const expensesSectionDescription = (
    <div className={Styles.sectionDescription}>
      <RenderMD>{t("frm-obn-expenses-description")}</RenderMD>
    </div>
  );

  return (
    <LayoutObnoveniZadostiV2
      data={sections}
      onSectionClick={(e) => handleSectionClick(e)}
      pageTitle={t("prijemce-obnoveniZadosti-title")}
      currentSection={sectionData.section}
      changeStep={handleChangeStep}
      //   prevStep={handlePreviousStep}
      onInjectedChangeStep={handleFillFormPersonsCount}
    >
      {isLoading && <Loading />}
      <UploadDrawer
        allowedFileTypes={[
          "image/jpeg",
          "image/png",
          "image/bmp",
          "application/pdf",
        ]}
        isOpen={isFileDrawerOpen}
        onClose={() => setIsFileDrawerOpen(false)}
        files={files}
        onChange={(e) => setFiles(e)}
        onFileUpload={handleFileUploaded}
        groupFileSize={currentGroupFileSize}
        maxGroupFileSize={35}
        maxFileSize={10}
        customTypes={fileTypes}
        useLocalSave
      />
      <div className={Styles.Container} style={{ alignItems: "flex-start" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            marginTop: sectionData.section >= 3 ? 25 : 0,
          }}
        >
          {isLoading ? (
            "Loading..."
          ) : (
            <>
              {sectionData.section === 4 && revenuesSectionDescription}
              {sectionData.section === 5 && expensesSectionDescription}
              {generateSection(sectionData.section)}
            </>
          )}
        </div>
        {!isLoading && sectionData.section === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: 20,
            }}
          >
            <Button onClick={handleBegin}>Zahájit obnovení</Button>
          </div>
        )}
      </div>
    </LayoutObnoveniZadostiV2>
  );
}
