import { isArray } from "lodash";
import { apiUrl } from "./appConfig.js";

export const HttpRawGet = (url) => {
  // console.log("[HttpRawGet]", { url });

  return fetch(url, {
    method: "GET",
    headers: {},
  }).then(
    function (response) {
      return response.text();
    },
    function (error) {
      return error;
    }
  );
};

export const HttpRawPost = (url, jsonbody) => {
  // console.log("[HttpRawPost]", { url,jsonbody });

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: JSON.stringify(jsonbody),
  }).then(
    function (response) {
      return response.text();
    },
    function (error) {
      return error;
    }
  );
};

const getRequestUrl = (url) => {
  return `${apiUrl}${url}${url.includes("?") ? "&" : "?"}uid=${window.userId}`;
};

export const HttpDelete = (url, _quiteRequest) => {
  // console.log("[HttpDelete]", {url, userid: window.userId,token: window.userAccessToken});

  return fetch(getRequestUrl(url), {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      uid: window.userId,
      lang: window.language,
      Authorization: window.userAccessToken,
    },
  }).then(
    function (response) {
      return response.json();
    },
    function (error) {
      return { _error: 410, _error_body: error };
    }
  );
};

export const HttpGet = (url, _quiteRequest, useDomain, nocache) => {
  // console.log("[HttpGet]", {url, userid: window.userId,token: window.userAccessToken});
  const requestUrl = useDomain ? url : getRequestUrl(url);
  const headers = {
    Accept: "application/json",
    uid: window.userId,
    lang: window.language,
    Authorization: window.userAccessToken,
  };

  if (nocache) {
    headers["Cache-Control"] = "no-cache";
    headers["Pragma"] = "no-cache";
    headers["Expires"] = "0";
  }
  return fetch(requestUrl, {
    method: "GET",
    headers: headers,
  }).then(
    function (response) {
      return response.json();
    },
    function (error) {
      return { _error: 410, _error_body: error };
    }
  );
};

export const HttpPost = (url, jsonbody, _quiteRequest, type = "application/json", nocache) => {
  //if (quiteRequest !== true) window.spectadoAppData.showLoaderUI();
  let payload = type === "multipart/form-data" ? jsonbody : JSON.stringify(jsonbody);

  const headers = {
    Accept: type,
    uid: window.userId,
    lang: window.language,
    Authorization: window.userAccessToken,
  };

  if (nocache) {
    headers["Cache-Control"] = "no-cache";
    headers["Pragma"] = "no-cache";
    headers["Expires"] = "0";
  }

  return fetch(apiUrl + url, {
    method: "POST",
    headers: headers,
    body: payload,
  }).then(
    function (response) {
      if (type === "application/pdf") {
        return response.blob();
      } else {
        return response.json();
      }
    },
    function (error) {
      return { _error: 410, _error_body: error };
    }
  );
};

export const HttpUpload = (url, files, _quiteRequest) => {
  if (!isArray(files)) {
    return false;
  }

  const formData = new FormData();
  files.forEach((file, idx) => {
    formData.append(`meta[${idx}]`, file.cat);
    formData.append(`File[${idx}]`, file.file);
  });

  return fetch(apiUrl + url, {
    method: "POST",
    headers: {
      Accept: "multipart/form-data",
      uid: window.userId,
      lang: window.language,
      Authorization: window.userAccessToken,
    },
    body: formData,
  }).then(
    function (response) {
      return response.json();
    },
    function (error) {
      return { _error: 410, _error_body: error };
    }
  );
};
