import React, { Fragment, useContext, useState } from "react";
//import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import UserContext from "../UserContext.js";
import { Helmet } from "react-helmet";
import LoadingIcon from "../Components/LoadingIcon.js";
import Styles from "./PrijemceNabidkyPomoci.module.scss";
import "../i18n.js";
import { useTranslation } from "react-i18next";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import useAccountLock from "../hooks/useAccountLock.js";

const PrijemceNabidkyPomoci = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [isLoading, setLoading] = useState(true);
  const { renderAlert } = useAccountLock({ onlyBeforeWarning: true });

  if (user.isLoggedIn === false) {
    return <Navigate to="/prijemce/prihlaseni" />;
  }

  return (
    <LayoutBasic pageTitle={t("prijemce-nabidkyPomoci")}>
      {renderAlert()}
      <Fragment>
        <div className={Styles.container}>
          {isLoading && (
            <div className={Styles.loading}>
              <LoadingIcon size={48} />
            </div>
          )}

          <iframe
            title="Nabídky pomoci"
            src="https://www.dobryandel.cz/nabidky-pomoci/"
            onLoad={() => setLoading(false)}
            className={Styles.iframe}
          />
        </div>
      </Fragment>
    </LayoutBasic>
  );
};

export default PrijemceNabidkyPomoci;
