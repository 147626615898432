import React from 'react'
import PropTypes from 'prop-types'

import Styles from "./Modal.module.scss"

const Modal = ({ open, onClose, children, title }) => {
    if (!open) return <></>
    return (
        <div className={Styles.backgroundDrop}>
            <div className={Styles.container}>
                <div className={Styles.header}>
                    <p className={Styles.title}>{title ?? ""}</p>
                    <i className={"fa-solid fa-xmark text-primary cursor-pointer"} onClick={() => onClose()} />
                </div>
                <div className={Styles.content}>
                    {children}
                </div>
            </div>
        </div>
    )
}

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
}

export default Modal