/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from "react";
import { isArray, isObject } from "lodash";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { Link } from "react-router-dom";
// import Loading from "../Components/Loading.js";
import RssFeed from "../Components/RssFeed.js";
import {
  FamilyStorySimple,
  FamilyStorySimpleDemo,
} from "../Components/FamilyStory.js";
import dayjs from "dayjs";
import { blogLink } from "../appConfig.js";
import styles from "./Darce.module.scss";
import familyStyles from "../Components/FamilyStory.module.scss";

const PageDarce = () => {
  const { t } = useTranslation();

  const { data: sentPaymentsData } = useSWR("getRecipientsByDonor/");
  // const { data: receivedPaymentsData } = useSWR("getReceivedPayments/");
  const { data: donorStats } = useSWR("getStatsProfile/");

  const dateOfRedistribution = dayjs()
    .add(1, "month")
    .add(-1, "day")
    .startOf("month");

  /*
  // Filter only families from last month
  let sentPaymentsDataLastRedistribution = null;
  if (isArray(sentPaymentsData) && sentPaymentsData.length > 0) {
    const lookingForRedistributionDate = sentPaymentsData[0].RedistributionDate;
    sentPaymentsDataLastRedistribution = sentPaymentsData.filter(item => {
      return item.RedistributionDate === lookingForRedistributionDate
        ? true
        : false;
    });
  }
  */

  const sentPaymentsDataLastRedistribution =
    isArray(sentPaymentsData) && sentPaymentsData.length > 0
      ? sentPaymentsData.slice(0, 2)
      : null;

  return (
    <LayoutBasic pageTitle={t("pageIndex-title")}>
      <Fragment>
        <section className={styles.receivedPaymentsInfo}>
          {isObject(donorStats) && donorStats.paymentsToDivide > 0 ? (
            <Fragment>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("receivedPayments-toDivide", {
                    amount: donorStats.paymentsToDivide,
                    dateMonth: t(
                      "month_2pad_" + dateOfRedistribution.format("M")
                    ),
                    dateYear: dateOfRedistribution.format("YYYY"),
                  }),
                }}
              ></div>
              <Link
                to="/moje-platby/"
                className={`button is-rounded ${styles.button}`}
              >
                {t("menu-moje-platby")}
              </Link>
              <hr />
            </Fragment>
          ) : null}
        </section>

        <section className={styles.familyStories}>
          {isObject(donorStats) && donorStats.recipientsCount < 1 ? (
            <Fragment>
              <div className={"title"}>{t("FamilyStory-demo")}</div>
              <div className={styles.description}>
                {t("FamilyStory-demo_info")}
              </div>

              <section
                className={`${familyStyles.wrap} ${styles.familyStoriesWrap}`}
              >
                <FamilyStorySimpleDemo />

                {isObject(donorStats) && donorStats.paymentsSum < 1 ? (
                  <div className={styles.familyStoryCta}>
                    <div className={styles.familyStoryCtaButton}>
                      <Link to="/jak-pomahat/" className="button is-rounded">
                        {t("FamilyStory-zacitPrispivat")}
                      </Link>
                    </div>
                    <div className={styles.familyStoryCtaButton}>
                      <Link
                        to="/moje-platby/nevidim-platbu/"
                        className="button is-rounded"
                      >
                        {t("payment-missing")}
                      </Link>
                    </div>
                  </div>
                ) : null}
              </section>
            </Fragment>
          ) : (
            <Fragment>
              {sentPaymentsDataLastRedistribution ? (
                <Fragment>
                  <div className={"title"}>
                    {t("FamilyStory-lastRedistributed")}
                  </div>
                  <div className={styles.description}>
                    {t("payment-sent-note")}
                  </div>
                  <section
                    className={`${familyStyles.wrap} ${styles.familyStoriesWrap}`}
                  >
                    {sentPaymentsDataLastRedistribution.map((payment) => {
                      // console.log({ payment });
                      return (
                        <FamilyStorySimple
                          key={payment.Recipient.Id}
                          id={payment.Recipient.Id}
                          recipientData={payment.Recipient}
                        />
                      );
                    })}
                  </section>
                  <section className={styles.familyStoriesMore}>
                    <Link to="/komu-pomaham/" className="button is-rounded">
                      {t("FamilyStory-more")}
                    </Link>
                  </section>
                </Fragment>
              ) : null}
            </Fragment>
          )}
        </section>

        <section className={styles.newsFeed}>
          <div className="title">{t("newsFeed-title")}</div>
          <RssFeed limit={4} isSingleLine />

          <section className={styles.newsFeedMore}>
            <a href={blogLink} target="_blank" className="button is-rounded">
              {t("newsFeed-more")}
            </a>
          </section>
        </section>
      </Fragment>
    </LayoutBasic>
  );
};

export default PageDarce;
