import React, { useContext } from "react";
import PropTypes from "prop-types";
import LayoutBasic from "./LayoutBasic.js";
import UserContext from "../UserContext.js";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LinkButton from "../Components/LinkButton.js";
import Styles from "./LayoutMujUcet.module.scss";
import useAccountLock from "../hooks/useAccountLock.js";

const LayoutPrijemceMojeUdaje = ({ children, pageTitle }) => {
  const { isLoggedIn } = useContext(UserContext);
  const { renderAlert } = useAccountLock();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  //console.log({ pathname });
  return (
    <LayoutBasic pageTitle={pageTitle}>
      {renderAlert()}
      <section className={Styles.wrap}>
        {isLoggedIn && (
          <div className={Styles.side}>
            <LinkButton
              to="/prijemce/moje-udaje"
              isActive={pathname === "/prijemce/moje-udaje"}
              isBlock
            >
              {t("menu-moje-udaje-zakladni")}
            </LinkButton>

            <LinkButton
              to="/prijemce/moje-udaje-kontakty"
              isActive={pathname === "/prijemce/moje-udaje-kontakty"}
              isBlock
            >
              {t("menu-moje-udaje-kontakty")}
            </LinkButton>

            <LinkButton
              to="/prijemce/dokumenty"
              isActive={pathname === "/prijemce/dokumenty"}
              isBlock
            >
              {t("menu-dokumenty")}
            </LinkButton>
          </div>
        )}

        <div className={Styles.content}>{children}</div>
      </section>
    </LayoutBasic>
  );
};

LayoutPrijemceMojeUdaje.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
};

export default LayoutPrijemceMojeUdaje;
