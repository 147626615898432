import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const PaymentType = ({ paymentType, account }) => {
  // DIR,CRE,MOB,SMS
  const knownAccounts = {
    "000000-0000000000/0000": "TST",
    "000019-2235200217/0100": "SMS",
    "000000-3292222329/5500": "MOB",
    "000000-0008323672/5500": "CRE",
    "000000-0008323680/5500": "CRE",
    "000000-5170011899/5500": "CRE",
  };

  let paymentTypeDetected = knownAccounts[account];
  if (!paymentTypeDetected) {
    paymentTypeDetected = paymentType;
  }

  const { t } = useTranslation();

  switch (paymentTypeDetected) {
    case "DIR":
      return (
        <span
          className="paymentIcon tooltip top"
          data-tooltip={t("payment-transfer")}
        >
          <i className="fa-regular fa-money-bill-wave" />
        </span>
      );

    case "CRE":
      return (
        <span
          className="paymentIcon tooltip top"
          data-tooltip={t("payment-card")}
        >
          <i className="fa-regular fa-credit-card" />
        </span>
      );

    case "MOB":
      return (
        <span
          className="paymentIcon tooltip top"
          data-tooltip={t("payment-mobile")}
        >
          <i className="fa-regular fa-mobile-alt" />
        </span>
      );

    case "SMS":
      return (
        <span
          className="paymentIcon tooltip top"
          data-tooltip={t("payment-sms")}
        >
          <i className="fa-regular fa-sms" />
        </span>
      );

    case "FUT":
      return <span />;

    default:
      return (
        <span
          className="paymentIcon tooltip top"
          data-tooltip={t("payment-transfer")}
        >
          <i className="fa-regular fa-money-bill-wave" />
        </span>
      );
  }
};
PaymentType.propTypes = {
  account: PropTypes.string,
  paymentType: PropTypes.string,
};
PaymentType.defaultProps = {
  account: "000000-0000000000/0000",
  paymentType: "TST",
};

export { PaymentType };
