import React, { Fragment, useState } from "react";
// import PropTypes from "prop-types";
import { isArray } from "lodash";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { Link } from "react-router-dom";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import {
  FamilyStorySimple,
  FamilyStorySimpleDemo,
} from "../Components/FamilyStory.js";
import { Button } from "../Components/LinkButton.js";
import Loading from "../Components/Loading.js";
import styles from "./KomuPomaham.module.scss";
import familyStyles from "../Components/FamilyStory.module.scss";

const KomuPomaham = () => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState(12);
  const { data: sentPaymentsData, isValidating: sentPaymentsLoading } = useSWR(
    "getRecipientsByDonor/"
  );

  if (sentPaymentsLoading) {
    return (
      <LayoutBasic pageTitle={t("pageIndex-title")}>
        <section style={{ marginTop: "100px" }}>
          <Loading />
        </section>
      </LayoutBasic>
    );
  }

  const hasFamilyStories =
    isArray(sentPaymentsData) && sentPaymentsData.length > 0 ? true : false;

  const paymentsDataList =
    sentPaymentsData &&
    sentPaymentsData.filter(
      (currentValue, index, array) =>
        array.findIndex(
          (item) => item.Recipient.Id === currentValue.Recipient.Id
        ) === index
    );
  const totalItems = paymentsDataList ? paymentsDataList.length : 0;
  const isDemo = totalItems === 1 && paymentsDataList[0].isDemo;

  return (
    <LayoutBasic pageTitle={t("FamilyStory-lastRedistributed")}>
      <section className={styles.wrap}>
        <div className={"title"}>
          {isDemo ? t("FamilyStory-demo") : t("FamilyStory-lastRedistributed")}
        </div>
        <div className={styles.description}>
          {isDemo ? t("FamilyStory-demo_info") : t("payment-sent-note")}
        </div>

        {hasFamilyStories && !isDemo && (
          <section className={familyStyles.wrap}>
            {paymentsDataList.map((story, index) => {
              if (limit > 0 && index >= limit) return false;

              const storyId = story.Recipient.Id;
              return (
                <FamilyStorySimple
                  key={storyId}
                  id={storyId}
                  recipientData={story.Recipient}
                />
              );
            })}
          </section>
        )}

        {hasFamilyStories && isDemo && (
          <Fragment>
            <section className={familyStyles.wrap}>
              <FamilyStorySimpleDemo />

              <div className={styles.familyStoriesActions}>
                <div className={styles.familyStoryCtaButton}>
                  <Link to="/jak-pomahat/" className="button is-rounded">
                    {t("FamilyStory-zacitPrispivat")}
                  </Link>
                </div>

                <div className={styles.familyStoryCtaButton}>
                  <Link
                    to="/moje-platby/nevidim-platbu/"
                    className="button is-rounded"
                  >
                    {t("FamilyStory-nevidimSvojiPlatbu")}
                  </Link>
                </div>
              </div>
            </section>
          </Fragment>
        )}

        {totalItems > limit && (
          <div className={styles.familyStoriesActions}>
            <Button
              className="button is-primary is-outlined"
              onClick={() => {
                setLimit(0);
              }}
            >
              {t("FamilyStory-show-more")}
            </Button>
          </div>
        )}
      </section>
    </LayoutBasic>
  );
};

// KomuPomaham.propTypes = {};
// KomuPomaham.defaultProps = {}

export default KomuPomaham;
