import React from "react";
import PropTypes from "prop-types";

const FileIcon = ({ type }) => {
  let iconName;

  switch (type) {
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      iconName = "fa-file-lines";
      break;

    case "text/csv":
      iconName = "fa-file-csv";
      break;

    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      iconName = "fa-file-excel";
      break;

    case "application/pdf":
      iconName = "fa-file-pdf";
      break;

    case "image/jpeg":
    case "image/png":
    case "image/x-ms-bmp":
      iconName = "fa-file-image";
      break;

    default:
      iconName = "fa-file";
      break;
  }

  return <i className={`fa-thin ${iconName}`} />;
};

FileIcon.propTypes = {
  type: PropTypes.string,
};

export default FileIcon;
