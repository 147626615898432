import React from 'react'
import PropTypes from "prop-types"
import { Tooltip as RCTooltip } from 'react-tooltip'
const Tooltip = ({ id, children, content, placement, style, className, tooltipStyle }) => {

    return (
        <>
            <div className={className} style={style} data-tooltip-id={id}>
                {children}
            </div>
            <RCTooltip place={placement} type="dark" effect="solid" content={content} id={id} style={{ ...tooltipStyle,zIndex: 10 }} />
        </>
    )
}

Tooltip.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node.isRequired,
    content: PropTypes.string.isRequired,
    placement: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    tooltipStyle: PropTypes.object
}

Tooltip.defaultProps = {
    id: `Tooltip-id-${Math.random().toString(36).slice(2, 20)}`,
    placement: 'top',
    style: {},
    className: '',
    tooltipStyle: {}
}

export default Tooltip