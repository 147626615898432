import React from "react";
import { useTranslation } from "react-i18next";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import { SwalIconSuccess } from "../Components/SwalIcon.js";
import RenderMd from "../Components/RenderMd.js";
import Styles from "./PaymentResult.module.scss";

const PagePaymentResultSuccess = () => {
  const { t } = useTranslation();

  return (
    <LayoutBasic pageTitle={t("payment-success-title")}>
      <section className={Styles.wrap}>
        <SwalIconSuccess />

        <RenderMd>md-payment-success</RenderMd>
      </section>
    </LayoutBasic>
  );
};

export default PagePaymentResultSuccess;
