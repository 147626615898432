import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocalStorage } from "react-use-storage";
import { isObject, isEmpty } from "lodash";
import sum from "hash-sum";
import { HttpGet } from "./HTTP.js";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { t } from "i18next";
const UserContext = React.createContext({});

export const UserContextStore = ({ children }) => {
  const MySwal = withReactContent(Swal);

  const defaultUserContext = { isLoggedIn: false, profile: {} };

  const [userStored, updateUserStored] = useLocalStorage(
    "user-session",
    defaultUserContext
  );

  const [user, setUser] = useState(
    isObject(userStored) ? userStored : defaultUserContext
  );

  const [userTempPassword, setUserTempPassword] = useState(null);

  const [fullUserStored, updatefullUserStored] = useLocalStorage(
    "full-user-object",
    null
  );

  const [fullUser, setfullUser] = useState(
    isObject(fullUserStored) ? fullUserStored : null
  );

  const [accessTokenStored, updateAccessTokenStored] = useLocalStorage(
    "access-token",
    null
  );
  const [accessToken, setAccessToken] = useState(
    !isEmpty(accessTokenStored) ? accessTokenStored : null
  );

  const [accessTokenExpireStored, updateAccessTokenExpireStored] =
    useLocalStorage("access-token-expire", null);
  const [accessTokenExpire, setAccessTokenExpire] = useState(
    !isEmpty(accessTokenExpireStored) ? accessTokenExpireStored : null
  );

  // console.log({ user, pn: user.profile.personalNumber });
  window.userAccessToken = accessToken;
  window.userId = sum(user.profile.personalNumber);
  window.profile = user.profile;
  useEffect(() => {
    // hook to update the window after login!
    window.userAccessToken = accessToken;
    window.userId = sum(user.profile.personalNumber);
    window.profile = user.profile;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const handleUpdateUserData = () => {
    return new Promise((resolve, _reject) => {
      HttpGet("/userProfile")
        .then((resp) => {
          const errorCode = resp && resp.error_code;
          if (errorCode !== 200) {
            //error
            return false;
          }

          const resp_newUser = resp.response.profile;
          const resp_fullUser = resp.response.profile_full;

          const newUserContext = {
            isLoggedIn: user.isLoggedIn,
            loginScope: user.loginScope,
            profile: resp_newUser,
          };

          localStorage.setItem("user-session", JSON.stringify(newUserContext));

          setUser(newUserContext);
          updateUserStored(newUserContext);
          setfullUser(resp_fullUser);
          updatefullUserStored(resp_fullUser);

          resolve();
          return;
        })
        .catch((err) => {
          // todo
        });
    });
  };

  const removeUserObject = () => {
    setUser(defaultUserContext);
    updateUserStored(defaultUserContext);
    setfullUser(null);
    updatefullUserStored(null);
    setAccessToken(null);
    updateAccessTokenStored(null);
    updateAccessTokenExpireStored(null);
    setAccessTokenExpire(null);
  };

  const isTokenValid = (token) => {
    if (!token) return false;
    try {
      const expire = JSON.parse(atob(token.split(".")[1])).exp * 1000;
      const dtExpire = dayjs(expire);
      const dtNow = dayjs();

      if (dtNow.isAfter(dtExpire)) return false;

      // if valid, return how long till expire in seconds...
      return dtExpire.diff(dtNow, "seconds");
    } catch {
      return false;
    }
  };

  const tokenCheck = () => {
    const token = accessToken;
    if (!token) return false;

    const expire = isTokenValid(token);
    if (!expire) {
      // token expired!
      removeUserObject();
      return false;
    }

    if (expire < 299) {
      // try to refresh the token from API...
      // TODO...
    }

    return true;
  };

  // check if token is valid
  useEffect(() => {
    tokenCheck();

    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.info(":: DEBUG :: Token", {
        accessToken,
        scope: user.loginScope,
      });
    }

    const timer = setInterval(() => {
      tokenCheck();
    }, 150 * 1000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const refetchUser = () => {
    HttpGet(`recipientProfile?t=${new Date().toISOString()}`)
      .then((response) => {
        if (response.error_code !== 200) {
          return;
        } else {
          setfullUser(response.response);
          updatefullUserStored(response.response);
        }
      })
  }

  // smartsupp chat
  useEffect(() => {
    //console.log({ fullUser, user });

    if (window) {
      if (fullUser && user && user.profile) {
        window.smartsupp("name", user.profile.displayName);
        window.smartsupp("email", user.profile.email);
        window.smartsupp("variables", {
          daid: {
            label: "Andělské číslo",
            value: user.profile?.personalNumber,
          },
          name: { label: "Jméno", value: user.profile?.displayName },
          email: { label: "E-mail", value: user.profile?.email },
          isCorporateAccount: {
            label: "Firemní účet?",
            value: fullUser.IsCorporateAccount ? "ANO" : "ne",
          },
          donorStatus: {
            label: "Stav",
            value: `${fullUser.DonorStatus?.Value} (${fullUser.DonorStatus?.Id})`,
          },
          language: { label: "Jazyk webu", value: "cs" },
        });
      } else {
        window.smartsupp("name", null);
        window.smartsupp("email", null);
        window.smartsupp("variables", {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const showAlert = (msg, toast, icon) => {
    MySwal.fire({
      text: msg,
      icon: icon ? icon : "error",
      timer: 10000,
      toast,
      position: toast ? "top-right" : "center",
      timerProgressBar: true,
      showConfirmButton: !toast,
    });
  };

  const showConfirm = (msg, onConfirm, icon) => {
    MySwal.fire({
      text: msg,
      icon: icon ? icon : "question",
      position: "center",
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: t("buttonCancel"),
      confirmButtonText: t("buttonConfirm"),
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm && onConfirm();
      }
    });
  };

  return (
    <UserContext.Provider
      value={{
        isLoggedIn: user.isLoggedIn,
        loginScope: user.loginScope,
        displayName: isObject(user.profile) ? user.profile.displayName : "",
        avatar: isObject(user.profile) ? user.profile.avatar : "",
        email: isObject(user.profile) ? user.profile.email : "",
        gender: isObject(user.profile) ? user.profile.gender : -1,
        personalNumber: isObject(user.profile)
          ? user.profile.personalNumber
          : "",
        accessToken: !isEmpty(accessToken) ? accessToken : null,
        accessTokenExpire,
        fullUser: isObject(fullUser) ? fullUser : null,
        personId: user.personId ? user.personId : null,

        setUserObject: (newScope, newUser, newFullUser, personId) => {
          const newUserContext = {
            isLoggedIn: true,
            loginScope: newScope,
            profile: { ...newUser, personId: personId },
            personId: personId,
          };

          setUser(newUserContext);
          updateUserStored(newUserContext);
          setfullUser({ ...newFullUser, personId: personId });
          updatefullUserStored(newFullUser);
        },

        refetchUser,

        setUserTempPassword,
        userTempPassword,

        setAccessToken: (token, expire) => {
          setAccessToken(token);
          updateAccessTokenStored(token);
          updateAccessTokenExpireStored(expire);
          setAccessTokenExpire(expire);
        },

        removeUserObject,
        updateUserData: handleUpdateUserData,

        showAlert,
        showConfirm,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;

export default UserContext;

UserContextStore.propTypes = {
  children: PropTypes.object.isRequired,
};
