import * as Yup from "yup";
import dayjs from "dayjs";
import { t } from "i18next";

const monthToNumber = {
  leden: 1,
  únor: 2,
  březen: 3,
  duben: 4,
  květen: 5,
  červen: 6,
  červenec: 7,
  srpen: 8,
  září: 9,
  říjen: 10,
  listopad: 11,
  prosinec: 12,
};

const parseMonth = (value, originalValue) => {
  if (typeof originalValue === "string") {
    const number = monthToNumber[originalValue.toLowerCase()];
    if (number) {
      return number;
    }
  }
  return Number.isNaN(value) ? undefined : value;
};
export const validationSchema = Yup.object().shape({
  FirstName: Yup.string().required(t("frm-first-name-error")),
  LastName: Yup.string().required(t("frm-last-name-error")),
  Mail: Yup.string().when("PersonRelation", {
    is: (relation) => relation?.Id !== 13,
    then: Yup.string().nullable().email(t("frm-email-error-pattern")),
    otherwise: Yup.string().nullable()
  }),
  Phone: Yup.string().when(["PersonRelation", "Primary"], {
    is: (relation, primary) => relation?.Id === 13 || primary === true || relation?.Id === "13",
    then: Yup.string().nullable().min(9).max(16).required(t("frm-phone-error")),
    otherwise: Yup.string().nullable().min(9).max(16)
  }),
  Month: Yup.number()
    .transform(parseMonth)
    .nullable()
    .required(t("frm-birth-month-error"))
    .test(
      "valid-month",
      t("frm-birth-month-error"),
      (value) => value >= 1 && value <= 12
    ),
  Year: Yup.number()
    .nullable()
    .required(t("frm-birth-year-error"))
    .min(1900, t("frm-birth-year-min-error"))
    .max(dayjs().year(), t("frm-birth-year-max-error")),
  Gender: Yup.object(),
});
