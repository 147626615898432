import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Styles from "./404.module.scss";
import LinkButton from "../Components/LinkButton.js";

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <section className={Styles.wrap}>
      <Helmet>
        <title>{t("page-not-found")}</title>
      </Helmet>

      <h1 className={Styles.title}>
        <span>404</span> {t("page-not-found")}
      </h1>

      <div className={Styles.nav}>
        <LinkButton to="/">{t("menu-home")}</LinkButton>
      </div>
    </section>
  );
};

export default Page404;
