import React from "react";
import PropTypes from "prop-types";
import styles from "./LoadingIcon.module.scss";

const LoadingIcon = ({ size }) => {
  return (
    <span
      className={styles.wrap}
      style={{ fontSize: size ? `${size}px` : null }}
    >
      <i className="fa-regular fa-spinner-third fa-spin" />
    </span>
  );
};

LoadingIcon.propTypes = {
  size: PropTypes.number,
};
LoadingIcon.defaultProps = {
  size: null,
};

export default LoadingIcon;
