import React, { useState, useEffect, useContext } from "react";
//import PropTypes from "prop-types";
import { isArray } from "lodash";
import UserContext from "../UserContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../i18n.js";
import dayjs from "dayjs";
import { format } from "../Helpers/dateFormater.js";
import { useTranslation } from "react-i18next";
import LayoutPrijemceMojeUdaje from "../Layouts/LayoutPrijemceMojeUdaje.js";
import LoadingIcon from "../Components/LoadingIcon.js";
import { Button } from "../Components/LinkButton";
import InfoHeader from "../Components/InfoHeader.js";
import Styles from "./PrijemceMojeUdaje.module.scss";
import { HttpGet, HttpPost } from "../HTTP.js";
import { useFormik, FormikProvider } from "formik";
import FormInput, { FormInputGroup } from "../Components/FormInput.js";
import validationSchema from "./PrijemceMojeUdaje.formValidation.js";
import PropTypes from "prop-types";

import ChangesPreview from "../Components/ChangesPreview.js";

import banks from "../Helpers/banks.json";
import useAccountLock from "../hooks/useAccountLock.js";

const PrijemceMojeUdaje = ({ useLayout = true, btnLabel, btnOnClick }) => {
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const user = useContext(UserContext);
  const [isLoading, setLoading] = useState(true);
  const [loadedData, setLoadedData] = useState([]);
  const [hasErrorLoading, setErrorLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [lifeStory, setLifeStory] = useState("");
  const { isLocked } = useAccountLock();

  const [informativeConsent, setInformativeConsent] = useState(3);
  const [agreeToPublishPreliminary, setAgreeToPublishPreliminary] = useState();

  const [showUnsavedChanges, setShowUnsavedChanges] = useState(false);
  const radioOptions = [
    { label: t("frm-confirm-yes"), value: 1 },
    { label: t("frm-confirm-no"), value: 2 },
  ];

  const trans = {
    PatientBirthday: "frm-bithday",
    "Kind.Id": "frm-kind",
    PatientTitle: "frm-patient-title",
    PatientFirstName: "frm-patient-first-name",
    PatientLastName: "frm-patient-last-name",
    Title: "frm-title",
    FirstName: "frm-first-name",
    LastName: "frm-last-name",
    "Address.Street": "frm-address-street",
    "Address.HouseNumber": "frm-address-number",
    "Address.City": "frm-address-city",
    "Address.PostalCode": "frm-address-postal",
    "Account.AccountNumberPrefix": "frm-accont-number-prefix",
    "Account.AccountNumber": "frm-accont-number",
    "Account.BankCode": "frm-account-code",
  };

  const allFields = Object.keys(trans);

  const [selectedBank, setSelectedBank] = useState(null);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setLoadedData(formik.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isLocked) {
      setEditing(false);
    }
  }, [isLocked]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
      setShowUnsavedChanges(true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  const loadData = () => {
    return new Promise((resolve, reject) => {
      setLoading(true);

      HttpGet("recipientProfile", false).then((resp) => {
        const formData = resp && resp.response;
        const errorCode = resp && resp.error_code;
        // console.log({ errorCode, formData, resp });

        if (errorCode !== 200) {
          setErrorLoading(true);
          setLoading(false);
          reject(errorCode);
          return;
        }

        if (formData.AgreeToPublishPreliminary) {
          setAgreeToPublishPreliminary(
            formData.AgreeToPublishPreliminary.Value
          );
          setInformativeConsent(formData.AgreeToPublishPreliminary.Id);
        }

        if (formData.LifeStory) {
          setLifeStory(formData.LifeStory);
        }

        const fieldsToPopulate = allFields;
        fieldsToPopulate.forEach((field) => {
          const fieldP = field.includes(".") ? field.split(".") : field;
          let value = isArray(fieldP)
            ? formData[fieldP[0]]
              ? formData[fieldP[0]][fieldP[1]]
              : ""
            : formData[field];
          if (value === true) value = "true";
          if (value === false) value = "false";

          //console.log({ field, fieldP, value, fieldsToPopulate });
          formik.setFieldValue(field, value, false);
          formik.setFieldTouched(field, false, false);
        });
        setLoading(false);
        setErrorLoading(false);
        resolve();
      });
    });
  };

  useEffect(() => {
    if (!isEditing && isLocked) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, isLocked]);

  const saveData = (data) => {
    return new Promise((resolve, reject) => {
      HttpPost("recipientProfile", data).then((resp) => {
        const errorCode = resp && resp.error_code;

        if (errorCode !== 200) {
          setLoading(false);

          // TODO: Push error to bugsnag ?
          // const formData = resp && resp.response;
          // console.error({ errorCode, formData, resp });

          reject(errorCode ? errorCode : resp._error);
          return;
        }

        resolve();
      });
    });
  };

  const handleSubmit = (data) => {
    // console.log("submit", { data });
    if (hasErrorLoading) return false;
    // console.log(data);

    setLoading(true);

    const mapConsentToPayload = (consentValue) => {
      let payloadValue = { Id: consentValue, Value: "" };

      switch (consentValue) {
        case 1:
          payloadValue.Value = "Ano";
          break;
        case 2:
          payloadValue.Value = "Ne";
          break;
        default:
          payloadValue = { Id: 3, Value: "Nevyplněno" };
      }

      return payloadValue;
    };

    const recipientWithUserId = {
      LifeStory: lifeStory,
      ...data,
      Id: user.fullUser.Id,
      AgreeToPublishPreliminary: mapConsentToPayload(informativeConsent),
    };

    const payload = {
      PersonId: user.personId,
      Recipient: recipientWithUserId,
    };

    saveData(payload)
      .then(() => {
        MySwal.fire({
          title: t("frm-notification-saved"),
          icon: "success",
          timer: 2000,
          toast: true,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        loadData();
        setEditing(false);
      })
      .catch((errorCode) => {
        MySwal.fire({
          title: t("frm-notification-save-error"),
          text: t(`myProfile-save-error-${errorCode}`),
          icon: "error",
          timer: 30000,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: true,
        });
      });
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });
  const { errors, isValid } = formik;

  const handleChanges = (e) => {
    setShowUnsavedChanges(false);
    formik.setValues(e);
    handleSubmit(e);
  };

  const customHandleChange = (e) => {
    const { value } = e.target;
    setInformativeConsent(Number(value));
    formik.handleChange(e);
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleBack = () => {
    setEditing(false);
    loadData();
  };

  useEffect(() => {
    if (!formik.values.Account?.BankCode) return;
    const bank = banks.find((b) => b.value === formik.values.Account?.BankCode);
    if (bank) setSelectedBank(bank.label);
    else setSelectedBank(null);
  }, [formik.values.Account?.BankCode]);

  const renderPage = (children) => {
    if (useLayout) {
      return (
        <LayoutPrijemceMojeUdaje pageTitle={t("prijemce-mojeUdaje-title")}>
          {children}
        </LayoutPrijemceMojeUdaje>
      );
    } else {
      return children;
    }
  };

  return renderPage(
    <>
      <ChangesPreview
        open={showUnsavedChanges}
        setOpen={(e) => setShowUnsavedChanges(e)}
        changes={formik.values}
        data={loadedData}
        setChanges={(e) => formik.setFieldValue(e.field, e.value, false)}
        i18n={trans}
        onSave={(e) => handleChanges(e)}
      />

      <section className={Styles.wrap}>
        {isLoading && (
          <div className={Styles.loading}>
            <LoadingIcon size={48} />
          </div>
        )}

        {hasErrorLoading && (
          <div>
            <div className="message is-danger">
              <div className="message-header">
                <p>{t("myProfile-error-title")}</p>
              </div>
              <div className="message-body">
                <p>{t("myProfile-error-body")}</p>
                <p>&nbsp;</p>
                <button
                  onClick={loadData}
                  className="button is-fullwidth is-primary is-outlined"
                >
                  {t("button-reload")}
                </button>
              </div>
            </div>
          </div>
        )}

        <section className={Styles.headingSection}>
          <h2 className={Styles.title}>{t("frmTitle-basic-information")}</h2>
          {!isEditing || isLocked ? (
            <Button
              onClick={handleEdit}
              className={Styles.editButton}
              disabled={isLocked}
            >
              {t("profil-danove-udaje-upravit")}
            </Button>
          ) : (
            isEditing && (
              <Button
                onClick={handleBack}
                disabled={!formik.isValid}
                className={Styles.editButton}
              >
                {t("frm-return-back")}
              </Button>
            )
          )}
        </section>

        {!isEditing || isLocked ? (
          <div className={Styles.MainSection}>
            <InfoHeader
              title={t("frm-personrelation-pacient")}
              content={`${formik.values.PatientTitle ?? ""} ${
                formik.values.PatientFirstName
              } ${formik.values.PatientLastName}`}
            />
            <InfoHeader
              title={t("prijemce-login-birthdate")}
              content={format(formik.values.PatientBirthday, "DD.MM.YYYY")}
            />
            {formik.values.Kind?.Id === 1 && (
              <InfoHeader
                title={t("frm-caring-person")}
                content={`${formik.values.Title ?? ""} ${
                  formik.values.FirstName
                } ${formik.values.LastName}`}
              />
            )}
            <InfoHeader
              title={t("frm-contact-adress")}
              className={Styles.isTooLong}
              content={`${formik.values.Address?.Street} ${formik.values.Address?.HouseNumber}, ${formik.values.Address?.PostalCode}, ${formik.values.Address?.City}`}
              marginTop="30px"
            />
            <InfoHeader
              title={t("frm-account-number-title")}
              className={Styles.isTooLong}
              content={`${formik.values.Account?.AccountNumberPrefix}-${
                formik.values.Account?.AccountNumber
              }/${formik.values.Account?.BankCode} (${selectedBank ?? ""})`}
              marginTop="30px"
            />
            <InfoHeader
              title={t("frm-informative-consent")}
              className={Styles.isTooLong}
              content={agreeToPublishPreliminary}
              marginTop="30px"
            />
            <p className={Styles.informativeTextMain}>
              {t("frm-informative-consent-text")}{" "}
            </p>
          </div>
        ) : (
          <FormikProvider value={formik}>
            <form
              onSubmit={formik.handleSubmit}
              className={`${hasErrorLoading ? "hidden" : ""}`}
            >
              <FormInputGroup layout=".5fr 1fr 1fr .5fr">
                <FormInput
                  name="PatientTitle"
                  label={t("frm-patient-title")}
                  onChange={formik.handleChange}
                  error={errors}
                  value={formik.values.PatientTitle}
                  disabled={isLocked}
                />

                <FormInput
                  name="PatientFirstName"
                  label={t("frm-patient-first-name")}
                  onChange={formik.handleChange}
                  error={errors}
                  value={formik.values.PatientFirstName}
                  disabled={isLocked}
                />

                <FormInput
                  name="PatientLastName"
                  label={t("frm-patient-last-name")}
                  onChange={formik.handleChange}
                  error={errors}
                  value={formik.values.PatientLastName}
                  disabled={isLocked}
                />
                <FormInput
                  name="PatientBirthday"
                  label={t("frm-bithday")}
                  onChange={formik.handleChange}
                  disabled={true}
                  error={errors}
                  value={dayjs(formik.values.PatientBirthday).format(
                    t("date-full")
                  )}
                />
              </FormInputGroup>

              {formik.values.Kind?.Id === 1 && (
                <FormInputGroup layout=".5fr 1fr 1fr">
                  <FormInput
                    name="Title"
                    label={t("frm-title")}
                    onChange={formik.handleChange}
                    error={errors}
                    disabled={isLocked}
                    value={formik.values.Title}
                  />

                  <FormInput
                    name="FirstName"
                    label={t("frm-caringperson-firstname")}
                    onChange={formik.handleChange}
                    error={errors}
                    disabled={isLocked}
                    value={formik.values.FirstName}
                  />

                  <FormInput
                    name="LastName"
                    label={t("frm-caringperson-lastname")}
                    onChange={formik.handleChange}
                    error={errors}
                    disabled={isLocked}
                    value={formik.values.LastName}
                  />
                </FormInputGroup>
              )}

              <section className={Styles.FormBlock}>
                <h3 className={Styles.smallTitle}>{t("frm-contact-adress")}</h3>
              </section>

              <FormInputGroup layout="1fr 0.5fr">
                <FormInput
                  name="Address.Street"
                  icon="map-signs"
                  label={t("frm-address-street")}
                  disabled={isLocked}
                  onChange={formik.handleChange}
                  error={errors}
                  value={formik.values.Address?.Street}
                />

                <FormInput
                  name="Address.HouseNumber"
                  icon="square-0"
                  label={t("frm-address-number")}
                  className="w-narrow"
                  disabled={isLocked}
                  onChange={formik.handleChange}
                  error={errors}
                  value={formik.values.Address?.HouseNumber}
                />
              </FormInputGroup>

              <FormInputGroup layout="1fr 0.5fr">
                <FormInput
                  name="Address.City"
                  icon="city"
                  disabled={isLocked}
                  label={t("frm-address-city")}
                  onChange={formik.handleChange}
                  error={errors}
                  value={formik.values.Address?.City}
                />

                <FormInput
                  name="Address.PostalCode"
                  icon="square-0"
                  label={t("frm-address-postal")}
                  className="w-narrow"
                  onChange={formik.handleChange}
                  disabled={isLocked}
                  error={errors}
                  value={formik.values.Address?.PostalCode}
                />
              </FormInputGroup>

              <section className={Styles.FormBlock}>
                <h3 className={Styles.smallTitle}>
                  {t("frm-account-number-title")}
                </h3>
              </section>

              <FormInputGroup layout="0.5fr 1fr 0.5fr">
                <FormInput
                  name="Account.AccountNumberPrefix"
                  icon="square-0"
                  label={t("frm-accont-number-prefix")}
                  disabled={isLocked}
                  onChange={formik.handleChange}
                  error={errors}
                  value={formik.values.Account?.AccountNumberPrefix}
                />

                <FormInput
                  name="Account.AccountNumber"
                  icon="square-0"
                  label={t("frm-accont-number")}
                  disabled={isLocked}
                  onChange={formik.handleChange}
                  error={errors}
                  value={formik.values.Account?.AccountNumber}
                />

                <FormInput
                  name="Account.BankCode"
                  icon="square-0"
                  label={t("frm-account-code")}
                  className="w-narrow"
                  onChange={formik.handleChange}
                  error={errors}
                  value={formik.values.Account?.BankCode}
                  autocomplete={true}
                  disabled={isLocked}
                  options={banks}
                  onAutocomplete={(val) =>
                    formik.setFieldValue("Account.BankCode", val.value)
                  }
                  type={"text"}
                  tooltip={selectedBank ?? ""}
                />
              </FormInputGroup>

              <section className={Styles.FormBlock}>
                <h3 className={Styles.smallTitle}>
                  {t("frm-informative-consent")}
                </h3>
                <FormInput
                  name="AgreeToPublishPreliminary"
                  onChange={customHandleChange}
                  error={errors}
                  disabled={isLocked}
                  type={"radio"}
                  options={radioOptions}
                  value={informativeConsent}
                  checked={informativeConsent}
                />
              </section>

              <p className={Styles.informativeText}>
                {t("frm-informative-consent-text")}{" "}
              </p>

              <div className="field has-text-centered">
                <div className="control">
                  <Button type="submit" disabled={!isValid}>
                    {t("frm-button-submit")}
                  </Button>
                </div>
              </div>
            </form>
          </FormikProvider>
        )}
        {/* {btnOnClick && btnLabel && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <Button onClick={btnOnClick} className={Styles.editButton}>
              {btnLabel}
            </Button>
          </div>
        )} */}
      </section>
    </>
  );
};

PrijemceMojeUdaje.propTypes = {
  useLayout: PropTypes.bool,
  btnLabel: PropTypes.string,
  btnOnClick: PropTypes.func,
};

PrijemceMojeUdaje.defaultProps = {
  useLayout: true,
};

export default PrijemceMojeUdaje;
