import React from "react";
import PropTypes from "prop-types";
import Styles from "./InfoHeader.module.scss";

const InfoHeader = ({
  title,
  content,
  marginTop = "0px",
  className,
  marginBottom = "0px",
}) => {
  if (!title) return <></>

  return (
    <div
      className={`${Styles.nameHeader} ${className || ""}`}
      style={{ marginTop, marginBottom, alignItems: "baseline" }}
    >
      <p className={Styles.Head}>{title}:</p>
      <div className={Styles.Main}>{content}</div>
    </div>
  )
}

InfoHeader.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  className: PropTypes.string,
};

export default InfoHeader;
