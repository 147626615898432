const storageKey = 'obn-form-stored-files';

function openDatabase() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('FilesDatabase', 1);

        request.onupgradeneeded = function (event) {
            const db = event.target.result;
            db.createObjectStore(storageKey, { keyPath: 'id' });
        };

        request.onerror = function (event) {
            reject('Database error: ' + event.target.errorCode);
        };

        request.onsuccess = function (event) {
            resolve(event.target.result);
        };
    });
}
export async function storeFilesInIndexedDB(filesData) {
    const db = await openDatabase();

    const updatedFiles = { ...filesData, id: 1 };

    for (const fileGroup of ['required', 'optional']) {
        if (updatedFiles[fileGroup]) {
            for (const file of updatedFiles[fileGroup]) {
                if (file.files && file.files.length > 0) {
                    const convertedFiles = await Promise.all(file.files.map(async (uploadedFile) => {
                        if (uploadedFile.content instanceof ArrayBuffer) {
                            return {
                                name: uploadedFile.name,
                                type: uploadedFile.type,
                                content: uploadedFile.content
                            };
                        } else if (uploadedFile instanceof File || uploadedFile instanceof Blob) {
                            return {
                                name: uploadedFile.name,
                                type: uploadedFile.type,
                                content: await uploadedFile.arrayBuffer()
                            };
                        } else {
                            console.error('Error: Uploaded file is not properly formatted.', uploadedFile);
                            return null;
                        }
                    }));
                    file.files = convertedFiles.filter(file => file !== null);
                } else {
                    file.files = [];
                }
            }
        }
    }

    return new Promise((resolve, reject) => {
        const transaction = db.transaction([storageKey], 'readwrite');
        const store = transaction.objectStore(storageKey);
        const request = store.put(updatedFiles);

        request.onsuccess = () => resolve(true);
        request.onerror = () => reject('Error storing files.');
    });
}
export async function retrieveFilesFromIndexedDB() {
    const db = await openDatabase();
    const transaction = db.transaction([storageKey], 'readonly');
    const store = transaction.objectStore(storageKey);
    const request = store.get(1);

    return new Promise((resolve, reject) => {
        request.onsuccess = (event) => {
            const result = event.target.result;
            if (result) {
                const updatedFiles = { ...result };
                for (const fileGroup of ['required', 'optional']) {
                    if (updatedFiles[fileGroup]) {
                        for (const file of updatedFiles[fileGroup]) {
                            if (file.files && file.files.length > 0) {
                                const convertedFiles = file.files.map((fileInfo) => {
                                    if (fileInfo.content instanceof ArrayBuffer) {
                                        return new File([fileInfo.content], fileInfo.name, { type: fileInfo.type });
                                    } else {
                                        return fileInfo;
                                    }
                                });
                                file.files = convertedFiles;
                            } else {
                                file.files = [];
                            }
                        }
                    }
                }
                resolve(updatedFiles);
            } else {
                resolve(null);
            }
        };
        request.onerror = () => reject('Error retrieving files.');
    });
}

export async function deleteDatabase() {
    return new Promise((resolve, reject) => {
        const closeAndDelete = async () => {
            const db = await openDatabase();
            db.close();
            const deleteRequest = indexedDB.deleteDatabase('FilesDatabase');

            deleteRequest.onsuccess = function () {
                console.log("Database deleted successfully");
                resolve(true);
            };

            deleteRequest.onerror = function (event) {
                console.error("Database deletion failed: ", event.target.errorCode);
                reject('Database deletion failed: ' + event.target.errorCode);
            };

            deleteRequest.onblocked = function () {
                console.error("Database deletion is blocked");
                reject('Database deletion is blocked');
            };
        };

        closeAndDelete().catch(reject);
    });
}

