import React from 'react'
import PropTypes from 'prop-types'

import Styles from "./ConfirmWindow.module.scss"
import { Button } from './LinkButton'
import FormInput, { FormInputGroup } from './FormInput'
import { useTranslation } from 'react-i18next'

const ConfirmWindow = ({ open, onClose, title, description, onConfirm, value, setValue, placeholder }) => {
    const { t } = useTranslation()
    const handleConfirmClick = () => {
        setValue("")
        onConfirm()
    }

    if (open)
        return (
            <div className={Styles.container}>
                <div className={Styles.window}>
                    <p className={Styles.title}>{title}</p>
                    <p className={Styles.description}>{description}</p>
                    <FormInputGroup layout="1fr">
                        <FormInput
                            name="description"
                            type={"textarea"}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            style={{ marginTop: 30 }}
                            placeholder={placeholder}
                        />
                    </FormInputGroup>
                    <div className={Styles.buttons}>
                        <Button onClick={() => handleConfirmClick()} disabled={value.length <= 0}>Potvrdit</Button>
                        <Button onClick={() => onClose()}>Zrušit</Button>
                    </div>
                </div>
            </div>
        )
}

ConfirmWindow.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
}

ConfirmWindow.defaultProps = {
    title: "",
    description: "",
    placeholder: ""
}

export default ConfirmWindow