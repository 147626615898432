import React, { useState } from "react";
// import PropTypes from "prop-types";
//import PropTypes from "prop-types";
//import { Navigate } from "react-router-dom";
//import Swal from "sweetalert2";
//import withReactContent from "sweetalert2-react-content";
import "../i18n.js";
import { useTranslation } from "react-i18next";
import PaymentsHistory from "../Components/PaymentsHistory.js";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import Styles from "./PrijemceHistoriePrispevku.module.scss";
import { Button } from "../Components/LinkButton.js";
import useSWR from "swr";
import Loading from "../Components/Loading.js";
import useAccountLock from "../hooks/useAccountLock.js";

const PrijemceHistoriePrispevku = () => {
  const { t } = useTranslation();
  const { renderAlert } = useAccountLock({ onlyBeforeWarning: true });

  const { data: payments, isValidating } = useSWR(`getRecipientPayment`);
  const data = payments && payments.api.Data ? payments.api.Data : [];

  const [hasMore, setHasMore] = useState(true);
  const [limit, setLimit] = useState(10);

  const loadMore = () => {
    setLimit(null);
  };

  return (
    <LayoutBasic pageTitle={t("pagePaymentsHistory")}>
      <div className={Styles.wrap}>
        {renderAlert()}
        <div>
          <h1 className="title">{t("menu-historie-prispevku")}</h1>
        </div>

        {isValidating && (
          <div className={Styles.loading}>
            <Loading />
          </div>
        )}
        <div className={Styles.contentWrapper}>
          <div className={Styles.divWrap}>
            <PaymentsHistory limit={limit} hasMore={setHasMore} data={data} />
          </div>

          <div className={Styles.buttonWrapper}>
            {limit > 0 && hasMore && data.length !== 0 && (
              <Button onClick={loadMore}>
                {t("payment-show-all-payments")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </LayoutBasic>
  );
};

export default PrijemceHistoriePrispevku;
