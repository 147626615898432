const filesizeFormat = (size) => {
  let filesize = size;
  let filesizeUnit = 0;
  const units = ["B", "kB", "MB", "GB", "TB", "PB"];

  while (filesize > 1023) {
    filesize = filesize / 1024;
    filesizeUnit++;
  }

  return `${Math.round(filesize * 10) / 10} ${units[filesizeUnit || 0]}`;
};

export { filesizeFormat };
