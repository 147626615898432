import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import Styles from "./CheckList.module.scss"
import FormInput from './FormInput'

const CheckList = ({ items, onChange, selectedItems }) => {
    const [checkedItems, setCheckedItems] = useState([])

    const onClick = (item) => {
        const isItemChecked = checkedItems.some(checkedItem => checkedItem.Key === item.Key);
    
        if (isItemChecked) {
            setCheckedItems(checkedItems.filter(checkedItem => checkedItem.Key !== item.Key));
        } else {
            setCheckedItems([...checkedItems, item]);
        }
    };

    useEffect(() => {
        onChange(checkedItems)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedItems])

    useEffect(() => {
        if(selectedItems) setCheckedItems(selectedItems)
    }, [selectedItems])

    return (
        <div className={Styles.container}>
            {items && items.map((item, index) => (
                <div className={Styles.itemContainer} key={index} onClick={() => onClick(item)}>
                    <FormInput
                        name={`frm-files-${item.Key}`}
                        type={"checkbox"}
                        value={checkedItems.some(checkedItem => checkedItem.Key === item.Key)}
                        onChange={() => null}
                    />
                    <p>{item.Value}</p>
                </div>
            ))}

        </div>
    )
}

CheckList.propTypes = {
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedItems: PropTypes.array,
}


export default CheckList