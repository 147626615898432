const saveAsFile = (content, contentType, fileName) => {
  const blob = new Blob([content], { type: contentType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", () => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

export { saveAsFile };
export default saveAsFile;
