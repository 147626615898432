const format = (date, format) => {
    if (!date) {
        return ""
    }
    const dateObject = new Date(date)
    const year = dateObject.getFullYear()
    const month = dateObject.getMonth() + 1
    const day = dateObject.getDate()
    const hours = dateObject.getHours()
    const minutes = dateObject.getMinutes()
    const seconds = dateObject.getSeconds()
    const milliseconds = dateObject.getMilliseconds()
    const formattedDate = format
        .replace("YYYY", year)
        .replace("MM", month)
        .replace("DD", day)
        .replace("hh", hours)
        .replace("mm", minutes)
        .replace("ss", seconds)
        .replace("SSS", milliseconds)
    return formattedDate
}

const formatDateTime = (date, useDateOnly = false) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    if (useDateOnly) return `${day}. ${month}. ${year}`
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};


export { format, formatDateTime }