import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Tooltip from "../Components/Tooltip.js";
import { useSearchParams } from "react-router-dom";
import Styles from "./Sections.module.scss";
import { useTranslation } from 'react-i18next';

const Section = ({ section, depth = 0 }) => {
    const { t } = useTranslation()
    const [, setSearchParams] = useSearchParams();

    const handleSectionClick = (section) => {
        setSearchParams({ section: section.key });
    }
    const [showOthers, setShowOthers] = useState(false);
    if (!section.title && section.sections && section.sections.length === 0) return <></>;

    const showExpand = section.sections &&
        section.sections.length > 0 &&
        section.sections.some((item) => item.title !== "");

    if (!section.title) {
        return (
            <div className={Styles.mainSectionContainer} onClick={() => setShowOthers(!showOthers)}>
                {section.sections && section.sections.map(subsection => (
                    <Section key={subsection.key} section={subsection} isFirstLevel={false} depth={depth + 1} />
                ))}
            </div>
        )
    } else {
        return (
            <div className={Styles.sectionContainer}>
                {section.title && (
                    <>
                        {section.title.length > 34 - 5 * depth ? (
                            <Tooltip content={section.title} placement="top" id={`form-tooltip-${section.title}`}>
                                <div className={`${Styles.sectionButton} depth-${depth}`}>
                                    <p onClick={() => handleSectionClick(section)} style={{ width: section.sections.length === 0 ? "100%" : "90%" }}>
                                        {t(`frm-section-${section.title}`)}
                                    </p>
                                    {showExpand &&
                                        <i
                                            onClick={() => setShowOthers(!showOthers)}
                                            className={`cursor-pointer fas fa-chevron-${showOthers ? "up" : "down"}`}
                                        />
                                    }
                                </div>
                            </Tooltip>
                        ) : (
                            <div className={`${Styles.sectionButton} depth-${depth}`}>
                                <p onClick={() => handleSectionClick(section)} style={{ width: section.sections.length === 0 ? "100%" : "90%" }}>
                                    {section.title}
                                </p>
                                {showExpand &&
                                    <i
                                        onClick={() => setShowOthers(!showOthers)}
                                        className={`cursor-pointer fas fa-chevron-${showOthers ? "up" : "down"}`}
                                    />
                                }
                            </div>
                        )}
                    </>
                )}
                {showOthers && section.sections.map(subsection => (
                    <Section key={subsection.key} section={subsection} isFirstLevel={false} depth={depth + 1} />
                ))}
            </div>
        );
    }
};

const Sections = ({ data }) => {
    return (
        <div>
            <Section section={data} />
        </div>
    );
};



Section.propTypes = {
    section: PropTypes.object,
    depth: PropTypes.number,
};

Sections.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Sections;
