import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import LayoutObnoveniZadosti from "../Layouts/LayoutObnoveniZadosti";
import FormJson from "../Components/FormJson";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import mockData from "../Helpers/formData.json";
import { useSearchParams } from "react-router-dom";
import UserContext from "../UserContext.js";
import { HttpGet, HttpPost } from "../HTTP";
import Modal from "../Components/Modal.js";
import { Button } from "../Components/LinkButton.js";
import CheckList from "../Components/CheckList.js";
import Styles from "./ObnoveniZadosti.module.scss";
import InfoHeader from "../Components/InfoHeader.js";
import { format } from "../Helpers/dateFormater.js";
import LinkButton from "../Components/LinkButton.js";

const fieldTypes = {
  financialSituation: {
    itemType: "number",
  },
  numeric: {
    itemType: "number",
  },
  paragraph: {
    itemType: "text",
    multiline: true,
  },
};

const ObnoveniZadosti = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(true);
  const [isFileLoading, setIsFileLoading] = useState(true);
  const [data, setData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [formJSON, setFormJSON] = useState([]);
  const [showFileSelectModal, setShowFileSelectModal] = useState(false)
  const [fullSave, setFullSave] = useState(false);
  const [searchParams] = useSearchParams();
  const [selectedFiles, setSelectedFiles] = useState({
    required: [],
    optional: [],
  });

  const [availableFiles, setAvailableFiles] = useState({
    required: {
      label: "frm-checklist-files-required",
      items: [],
      onChange: (e) =>
        setSelectedFiles({ optional: selectedFiles.optional, required: e }),
    },
    optional: {
      label: "frm-checklist-files-optional",
      items: [],
      onChange: (e) =>
        setSelectedFiles({ required: selectedFiles.required, optional: e }),
    },
  });

  const handleUpdateSelectFiles = (e, index) => {
    const newFiles = selectedFiles;
    newFiles[index] = e;
    setSelectedFiles(newFiles);
  };

  useEffect(() => {
    if (user && user.personId) {
      HttpGet(`getRecipientRequestDocuments`).then((resp) => {
        if (resp.error_code === 200) {
          setAvailableFiles({
            required: {
              label: "frm-checklist-files-required",
              items: resp.response.Data.DocumentProcTypes,
              onChange: (e) => handleUpdateSelectFiles(e, "required"),
              // onChange: (e) => console.log(e)
            },
            optional: {
              label: "frm-checklist-files-optional",
              items: resp.response.Data.DocumentProcTypesOptional,
              onChange: (e) => handleUpdateSelectFiles(e, "optional"),
              // onChange: (e) => console.log(e)
            },
          });
        }
        setIsFileLoading(false);
      }); 
      HttpGet(`getRecipientRequestUserform/${user.fullUser.Id}`).then((resp) => {
        if (resp.error_code === 200) {
          const data = resp.response.Data
          const form = [];
          for (let s of data.sections) {
            form.push(...collectAllItems(s));
          }
          setFormJSON(form);
          setIsLoading(false);
          setOriginalData(mockData);
        }
      })
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const loadData = () => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);

      HttpGet("recipientProfile", false)
        .then((resp) => {
          const formData = resp && resp.response;
          const errorCode = resp && resp.error_code;
          console.log({ errorCode, formData, resp });

          if (errorCode !== 200) {
            setIsLoading(false);
            reject(errorCode);
          } else {
            setData(formData);
            setIsLoading(false);
            resolve(formData);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          reject(error);
        });
    });
  };

  function collectAllItems(data) {
    let allItems = [];

    if (data.items && Array.isArray(data.items)) {
      allItems.push(...data.items);
    }

    if (data.sections && Array.isArray(data.sections)) {
      data.sections.forEach((section) => {
        allItems = allItems.concat(collectAllItems(section));
      });
    }

    if (data.tables && Array.isArray(data.tables) && data.tables.length > 0) {
      data.tables.forEach((table) => {
        if (table) {
          allItems.push({ itemType: "table", ...table });
        }
      });
    }

    allItems.forEach((item, index, array) => {
      if (item.question && typeof item.question === "string") {
        item.question = JSON.parse(item.question);
      }
      if (fieldTypes[item.itemType]) {
        array[index] = { ...item, ...fieldTypes[item.itemType] };
      }
    });

    return allItems;
  }

  useEffect(() => {
    if (searchParams.get("section") && formJSON) {
      const section = findSectionByKey(
        originalData,
        searchParams.get("section")
      );
      if (section) handleSectionClick(section);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, formJSON]);

  const saveData = (data) => {
    return new Promise((resolve, reject) => {
      HttpPost(`recipientRequestUserformUpdate/${user.personId}`, data).then(
        (resp) => {
          const errorCode = resp && resp.error_code;

          if (errorCode !== 200) {
            setIsLoading(false);

            reject(errorCode ? errorCode : resp._error);
            return;
          }
          resolve();
        }
      );
    });
  };

  const handleSubmit = (data, files) => {
    if(fullSave) {
      setShowFileSelectModal(true)
      return 
    }
    const exportDatav2 = fillFormData(originalData, data);
    setIsLoading(true);
    const payload = {
      Completed: fullSave,
      PersonId: user.personId,
      Id: user.fullUser.Id,
      RequestedDocuments: {
        Documents: selectedFiles.required,
        DocumentsOptional: selectedFiles.optional,
      },
      data: exportDatav2,
    };
  
    saveData(payload)
      .then(() => {
        MySwal.fire({
          title: t("frm-notification-saved"),
          icon: "success",
          timer: 2000,
          toast: true,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        setIsLoading(false);
      })
      .catch((errorCode) => {
        MySwal.fire({
          title: t("frm-notification-save-error"),
          text: t(`myProfile-save-error-${errorCode}`),
          icon: "error",
          timer: 30000,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: true,
        });
      });
  };

  function findSectionByKey(section, key) {
    if (section.key === key) {
      return section;
    }

    if (section.sections && section.sections.length > 0) {
      for (let subsection of section.sections) {
        let found = findSectionByKey(subsection, key);
        if (found) {
          return found;
        }
      }
    }

    return null;
  }

  const handleSectionClick = (section) => {
    const findFirstInput = (currentSection) => {
      if (currentSection.items && currentSection.items.length > 0) {
        return document.querySelector(
          `[name="formIdx-${currentSection.items[0].id}"]`
        );
      } else if (
        currentSection.sections &&
        currentSection.sections.length > 0
      ) {
        for (let nestedSection of currentSection.sections) {
          const input = findFirstInput(nestedSection);
          if (input) {
            return input;
          }
        }
      }
      return null;
    };

    const input = findFirstInput(section);

    if (input) {
      input.focus();
      input.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  function fillFormData(structure, formData) {
    const safeParseJSON = (jsonString) => {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        return {};
      }
    };

    const updateQuestionValue = (questionStr, value) => {
      let questionObj = safeParseJSON(questionStr);
      if (questionObj === null || typeof questionObj !== "object") {
        questionObj = {};
      }
      questionObj.value = value;
      return JSON.stringify(questionObj);
    };

    if (Array.isArray(structure)) {
      return structure.map((element) => fillFormData(element, formData));
    }

    if (structure.tables) {
      structure.tables = structure.tables.map((table) => {
        if (table.header && table.header.cells) {
          table.header.cells = fillFormData(table.header.cells, formData);
        }
        if (table.rows) {
          table.rows = table.rows.map((row) => {
            if (row.cells) {
              row.cells = fillFormData(row.cells, formData);
            }
            return row;
          });
        }
        return table;
      });
    }

    if (structure.sections) {
      structure.sections = fillFormData(structure.sections, formData);
    }

    if (structure.items) {
      structure.items = structure.items.map((item) => {
        const formKey = `formIdx-${item.id}`;
        if (formData[formKey] !== undefined) {
          item.question = updateQuestionValue(item.question, formData[formKey]);
        }
        return item;
      });
    }

    if (
      structure.item &&
      formData[`formIdx-${structure.item.id}`] !== undefined
    ) {
      structure.item.question = updateQuestionValue(
        structure.item.question,
        formData[`formIdx-${structure.item.id}`]
      );
    }

    return structure;
  }

  return (
    <LayoutObnoveniZadosti
      pageTitle={t("prijemce-obnoveniZadosti-title")}
      data={originalData}
      onSectionClick={handleSectionClick}
    >
      <section className={Styles.headingSection}>
        <h2 className={Styles.title}>{t("frmTitle-basic-information")}</h2>
        <LinkButton className={Styles.linkButton} to={"/prijemce/moje-udaje"}>
          {t("profil-danove-udaje-upravit")}
        </LinkButton>
      </section>

      <div className={Styles.MainSection}>
        <InfoHeader
          title={t("frm-personrelation-pacient")}
          content={`${data.PatientFirstName} ${data.PatientLastName}`}
        />
        <InfoHeader
          title={t("prijemce-login-birthdate")}
          content={format(data.PatientBirthday, "DD.MM.YYYY")}
        />
        {data.Kind?.Id === 1 && (
          <InfoHeader
            title={t("frm-caring-person")}
            content={`${data.FirstName} ${data.LastName}`}
          />
        )}
        <InfoHeader
          title={t("frm-contact-adress")}
          content={`${data.Address?.Street} ${data.Address?.HouseNumber}, ${data.Address?.PostalCode}, ${data.Address?.City}`}
          marginTop="30px"
        />
        <InfoHeader
          title={t("frm-account-number-title")}
          content={`${data.Account?.AccountNumberPrefix}-${data.Account?.AccountNumber}/${data.Account?.BankCode}`}
          marginTop="30px"
        />
      </div>

      <Fragment>
        <Modal open={showFileSelectModal} onClose={() => setShowFileSelectModal(false)} title={t("frm-loading-title")} >
            <p>{t("prijemce-obnoveniZadosti-submit-description")}</p>
          <div style={{marginTop:20, display:'flex', alignItems:'center', justifyContent:"space-between"}}>
            <div>
            <p style={{marginBottom:10}}>{t("prijemce-obnoveniZadosti-submit-required-docs")}</p>
            <CheckList items={availableFiles.required.items} onChange={(e) => setSelectedFiles({...selectedFiles, required: e})} />
            </div>
            <div>
            <p style={{marginBottom:10}}>{t("prijemce-obnoveniZadosti-submit-optional-docs")}</p>
            <CheckList items={availableFiles.optional.items} onChange={(e) => setSelectedFiles({...selectedFiles, optional: e})}  />
            </div>
          </div>
          <div style={{display:'flex', justifyContent:"flex-end"}}>
            <Button>{t("prijemce-obnoveniZadosti-submit-full")}</Button>
          </div>
        
        </Modal>
        <section>
          <FormJson
            blueprint={formJSON}
            allowFiles={false}
            validate={fullSave}
            allowFileCheckList={false}
            // fileCheckListLabel={t("frm-checklist-files")}
            // fileCheckListItems={[
            //   availableFiles.required,
            //   availableFiles.optional,
            // ]}
            // fileCheckListChange={(files) => handleFileSelectChange(files)}
            submitCheckbox={{
              name: "fullSave",
              label: "Přeji si odeslat finální verzi formuláře.",
              value: fullSave,
              onChange: (e) => setFullSave(e.target.checked),
            }}
            onSubmit={handleSubmit}
            disableSubmit={isLoading || isFileLoading}
            isLoading={isLoading}
          />
        </section>
      </Fragment>
    </LayoutObnoveniZadosti>
  );
};

export default ObnoveniZadosti;
