import React, { useContext, useState } from "react";
// import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
// import { Link } from "react-router-dom";
import { Button } from "../Components/LinkButton.js";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import ModalCard from "../Components/ModalCard.js";
import CardPayment from "../Components/CardPayment.js";
import RenderMD from "../Components/RenderMd.js";
import UserContext from "../UserContext.js";
import Styles from "./JakPomahat.module.scss";

const JakPomahat = () => {
  const defaultVS = "11111111";
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const vs = (user && user.personalNumber) || defaultVS;
  const { data } = useSWR(
    "/valueStore/bankAccount,bankIban,bankSwift,bankIbanName,bankIbanAddress,bankIbanBankName,bankIbanBankAddress"
  );

  const generateQrUrl = (qrVs) => {
    if (data && data.bankAccount) {
      const accountNumber = data.bankAccount.split("/");

      return `https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=${
        accountNumber[0]
      }&bankCode=${accountNumber[1]}&currency=CZK&vs=${
        qrVs || "11111111"
      }&message=www.dobryandel.cz`;
    }

    return "/loading.gif";
  };

  return (
    <LayoutBasic pageTitle={t("jakPomahat-title")}>
      <>
        <ModalCard
          isActive={showModal === "qr-code"}
          title={t("jakPomahat-modalTitleQr")}
          onClose={() => {
            setShowModal(false);
          }}
        >
          <div className={Styles.qrcode}>
            <img
              className={Styles.qrcode}
              src={generateQrUrl(vs)}
              alt="QR platba"
            />
          </div>
        </ModalCard>

        <ModalCard
          isActive={showModal === "card-payment"}
          title={t("jakPomahat-modalCardPayment")}
          onClose={() => {
            setShowModal(false);
          }}
        >
          <div className={Styles.cardPayment}>
            <CardPayment />
          </div>
        </ModalCard>

        <section className={Styles.wrap}>
          <div className="title">{t("jakPomahat-title")}</div>

          <div className={Styles.platbaWrap}>
            <div className={Styles.item}>
              <div className={`subtitle ${Styles.subtitle}`}>
                {t("jakPomahat-platba-banka")}
              </div>

              <div className={Styles.domestic}>
                <div className="table-container">
                  <table className="table is-striped is-fullwidth is-narrow">
                    <tbody>
                      <tr>
                        <th>{t("jakPomahat-accountNumber")}</th>
                        <td>{data?.bankAccount}</td>
                      </tr>
                      <tr>
                        <th>{t("jakPomahat-vs")}</th>
                        <td>
                          {vs === defaultVS ? t("payment-vs-anonym") : vs}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className={Styles.buttons}>
                  <Button onClick={() => setShowModal("qr-code")}>
                    {t("jakPomahat-showQr")}
                  </Button>

                  <Button onClick={() => setShowModal("card-payment")}>
                    {t("jakPomahat-showCardPay")}
                  </Button>
                </div>
              </div>

              <div className="mt-4">
                <RenderMD className={Styles.footnote}>
                  jakPomahat-platba-karta-info-note
                </RenderMD>
              </div>
            </div>

            <div className={Styles.item}>
              <div className={`subtitle ${Styles.subtitle}`}>
                {t("jakPomahat-platba-banka-foreign")}
              </div>

              <div className="table-container">
                <table className="table is-striped is-fullwidth is-narrow">
                  <tbody>
                    <tr>
                      <th>{t("jakPomahat-iban-name")}</th>
                      <td>{data?.bankIbanName}</td>
                    </tr>
                    <tr>
                      <th>{t("jakPomahat-iban-address")}</th>
                      <td>{data?.bankIbanAddress}</td>
                    </tr>
                    <tr>
                      <th>{t("jakPomahat-iban")}</th>
                      <td>{data?.bankIban}</td>
                    </tr>
                    <tr>
                      <th>{t("jakPomahat-swift")}</th>
                      <td>{data?.bankSwift}</td>
                    </tr>
                    <tr>
                      <th>{t("jakPomahat-refCislo")}</th>
                      <td>{vs === defaultVS ? t("payment-vs-anonym") : vs}</td>
                    </tr>
                    <tr>
                      <th>{t("jakPomahat-iban-bank-name")}</th>
                      <td>{data?.bankIbanBankName}</td>
                    </tr>
                    <tr>
                      <th>{t("jakPomahat-iban-bank-address")}</th>
                      <td>{data?.bankIbanBankAddress}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/*<div className="subtitle">{t("jakPomahat-platba-karta")}</div>*/}
        </section>
      </>
    </LayoutBasic>
  );
};

// KomuPomaham.propTypes = {};
// KomuPomaham.defaultProps = {}

export default JakPomahat;
