import React from "react";
// import Bugsnag from "@bugsnag/js";
import { Link } from "react-router-dom";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import { useTranslation } from "react-i18next";
import useAnimation from "../hooks/useAnimation.js";
import FeedbackForm from "../Components/FeedbackForm.js";

// import { HttpGet } from "../HTTP.js";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";

const Index = () => {
  const { t } = useTranslation();
  // const MySwal = withReactContent(Swal);
  const animation1 = useAnimation("elastic", 3500, 1200);

  return (
    <LayoutBasic pageTitle="TEST page">
      <div>
        <h1 className="title" style={{ fontSize: 64 - 24 * animation1 }}>
          {t("header-homeTitle")}
        </h1>

        <Link to="/">home</Link>

        <div style={{ maxWidth: "450px" }}>
          <FeedbackForm
            subject="NPS - formulář"
            fields={[
              {
                id: "vzkaz",
                title: "Vzkaz",
                description: "",
                required: true,
                multiline: true,
                itemType: "text",
              },
              {
                id: "email",
                title: "E-mailová adresa",
                description: "",
                required: false,
                multiline: false,
                itemType: "text",
              },
            ]}
          />
        </div>
      </div>
    </LayoutBasic>
  );
};

export default Index;
