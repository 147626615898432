import React from "react";
//import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// import { isObject } from "lodash";
import "./LanguageSwitcher.scss";

import { ReactComponent as IconFlagCS } from "../assets/flags/cz.svg";
import { ReactComponent as IconFlagEN } from "../assets/flags/uk.svg";

const LanguageSwitcher = () => {
  const disabled = false; // remove when language switch should shown
  const { i18n } = useTranslation();

  // const currentLang = isObject(i18n) ? i18n.language : null;
  const allLangs = [
    { code: "cs", flag: IconFlagCS },
    { code: "en", flag: IconFlagEN },
  ];
  //console.log(currentLang, allLangs);

  if (disabled) return <></>;

  return (
    <div className={`navbar-item navbar-language`}>
      {allLangs.map((lang) => {
        const IconFlag = lang.flag;
        return (
          <div
            key={lang.code}
            aria-label={lang.code}
            className={`languageItem`}
            onClick={() => {
              i18n.changeLanguage(lang.code);
            }}
          >
            <IconFlag />
          </div>
        );
      })}
    </div>
  );
};

export default LanguageSwitcher;

// LanguageSwitcher.propTypes = {};
// LanguageSwitcher.defaultProps = {};
