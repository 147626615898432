import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { isArray } from "lodash";
import styles from "./FormInput.module.scss";
import { Field } from "formik";
import PhoneInput from "react-phone-number-input";

const FormInputGroup = ({ children, cols, layout, className }) => {
  //console.log({ children, type: typeof children });
  return (
    <div
      className={`${styles.inlineFields} ${styles[`cols-${cols}`] || ""} ${className || ""
        }`}
      style={layout && { gridTemplateColumns: layout }}
    >
      {children}
    </div>
  );
};
FormInputGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  cols: PropTypes.number,
  layout: PropTypes.string,
  className: PropTypes.string,
};

const FormInput = ({
  name,
  placeholder,
  type,
  label,
  labelTrue,
  labelFalse,
  description,
  className,
  error,
  hasSelectedValue,
  icon,
  readOnly,
  multiple,
  options,
  onChange,
  onBlur,
  value,
  disabledControls,
  min,
  max,
  tooltipStyle,
  disabled,
  style,
  props,
  autocomplete,
  onAutocomplete,
  tooltip,
  descriptionPlacement = "top",
  descriptionStyle = {},
  useBooleanToggle,
}) => {
  const nameP = name.includes(".") ? name.split(".") : name;
  const errorString = isArray(nameP)
    ? error && error[nameP[0]] && error[nameP[0]][nameP[1]]
    : error && error[name];
  const hasError = errorString ? true : false;
  const [avaibleOptions, setAvaibleOptions] = useState(options ?? []);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (options && autocomplete) {
      setAvaibleOptions(
        options.filter(
          (item) => item.value.includes(value) && item.value !== value
        )
      );
    }
  }, [value, options, autocomplete]);

  const handleAutocompleteBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
      onBlur();
    }, 100);
  };

  if (autocomplete) {
    return (
      <div
        className={`field ${styles.field} ${className ? (styles[className] ? styles[className] : className) : ""
          }`}
      >
        <label htmlFor={name} className={`label ${styles.label}`}>
          {label}
        </label>

        <div
          className={`control ${icon ? "has-icons-left" : ""} ${hasError ? "has-icons-right" : ""
            }`}
        >
          {icon && (
            <span className={`icon ${styles.icon} is-small is-left`}>
              <i className={`fa-regular fa-${icon}`} />
            </span>
          )}

          <input
            name={name}
            type={type}
            placeholder={placeholder}
            readOnly={readOnly}
            className={`input ${hasError ? "is-danger" : ""} ${styles.input}`}
            onChange={onChange}
            onBlur={handleAutocompleteBlur}
            onFocus={() => setIsFocused(true)}
            value={value || ""}
            min={type === "number" ? min : null}
            max={type === "number" ? max : null}
          />
        </div>
        {isFocused && avaibleOptions.length > 0 && (
          <div className={styles.autocompleteOptions}>
            {avaibleOptions.map((item, index) => (
              <div
                key={index}
                className={styles.optionContainer}
                onClick={() => onAutocomplete(item)}
              >
                <p className={styles.optionValue}>{item.label}</p>
              </div>
            ))}
          </div>
        )}
        {tooltip && <p className={styles.inputTooltip}>{tooltip}</p>}
      </div>
    );
  }

  if (type === "checkbox") {
    //console.log({ name, value });
    return (
      <div
        className={`field ${disabled ? styles.disabled : ""} ${styles.field} ${className ? (styles[className] ? styles[className] : className) : ""
          }`}
        {...props}
      >
        <label htmlFor={name} className={`checkbox ${styles.checkbox}`}>
          {/*
          <input
            type="checkbox"
            name={name}
            id={name}
            data-value={JSON.stringify(value)}
            value={value === "true" || value === true ? true : false}
            defaultChecked={value === "true" || value === true ? true : false}
          />
      */}
          <Field
            type="checkbox"
            name={name}
            id={`${name}`}
            value="true"
            checked={value === "true" || value === true ? true : false}
            style={style}
            onChange={onChange}
            disabled={disabled}
            onBlur={onBlur}
          // value={value || false}
          />
          <span dangerouslySetInnerHTML={{ __html: label }} />
          {tooltip && (
            <span
              className="tooltip top"
              style={{ maxWidth: 200 }}
              data-tooltip={tooltip}
            >
              <i className={`fa-solid fa-circle-question ${tooltipStyle}`}></i>
            </span>
          )}
        </label>
        {hasError && <p className="help is-danger">{errorString}</p>}
      </div>
    );
  }

  if (type === "toggle") {
    return (
      <div>
        {label && (
          <div
            className={`field ${styles.field} ${styles.fieldNoMargin} ${className
              ? styles[className]
                ? styles[className]
                : className
              : ""
              }`}
          >
            <label htmlFor={name} className={`label ${styles.label}`}>
              {label}
            </label>

            {description && (
              <div className={`help ${styles.help}`}>{description}</div>
            )}
          </div>
        )}
        <div
          role="group"
          aria-labelledby={name}
          className={`${styles.toggle} ${className ? styles[className] : ""}`}
        >
          <label htmlFor={`${name}-false`} className={`radio ${styles.radio}`}>
            <Field
              type="radio"
              name={name}
              id={`${name}-false`}
              checked={value === "false"}
              value={useBooleanToggle ? false : "false"}
              disabled={disabled}
            />
            <span>{labelFalse}</span>
          </label>
          <label htmlFor={`${name}-true`} className={`radio ${styles.radio}`}>
            <Field type="radio" name={name} id={`${name}-true`} checked={value === "true"}
              value={useBooleanToggle ? true : "true"} />
            <span>{labelTrue}</span>
          </label>
        </div>
        {hasError && (
          <div>
            <p className="help is-danger">{errorString}</p>
          </div>
        )}
      </div>
    );
  }

  if (type === "textarea") {
    return (
      <div
        className={`field ${styles.field} ${className ? (styles[className] ? styles[className] : className) : ""
          }`}
      >
        <label htmlFor={name} className={`label ${styles.label}`}>
          {label}
        </label>
        {description && (
          <div className={`help ${styles.help}`}>{description}</div>
        )}
        <div
          className={`control ${icon ? "has-icons-left" : ""} ${hasError ? "has-icons-right" : ""
            }`}
        >
          <textarea
            name={name}
            readOnly={readOnly}
            className="textarea"
            placeholder={placeholder}
            onChange={onChange}
            style={style}
            onBlur={onBlur}
            value={value || ""}
            disabled={disabled}
          ></textarea>
        </div>

        {hasError && <p className="help is-danger">{errorString}</p>}
      </div>
    );
  }
  return (
    <div
      className={`field ${styles.field}  ${className ? (styles[className] ? styles[className] : className) : ""
        }`}
    >
      {label && (
        <label htmlFor={name} className={`label ${styles.label}`}>
          {label}
        </label>
      )}

      {description && descriptionPlacement === "top" && (
        <div className={`help ${styles.help}`} style={descriptionStyle}>
          {description}
        </div>
      )}

      <div
        className={`control ${icon ? "has-icons-left" : ""} 
        ${hasError ? "has-icons-right" : ""} `}
        style={
          descriptionPlacement === "right"
            ? { display: "flex", alignItems: "center" }
            : {}
        }
      >
        {type === "multiselect" && (
          <div className={`multiselect`}>
            {options &&
              options.map((item) => {
                return (
                  <div key={item.value}>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name={name}
                        value={item.value}
                        readOnly={readOnly}
                        checked={
                          value && value.includes(item.value.toString())
                            ? true
                            : false
                        }
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={disabled}
                      />
                      {item.label}
                    </label>
                  </div>
                );
              })}
          </div>
        )}

        {type === "select" && (
          <div
            className={`select ${styles.select} ${multiple && "is-multiple"}`}
          >
            <select
              name={name}
              readOnly={readOnly}
              className={`input ${hasError ? "is-danger" : ""}`}
              multiple={multiple}
              size={multiple ? options && options.length : undefined}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              value={value || (multiple ? [] : "")}
            >
              {options &&
                options.map((item) => {
                  const isOptionDisabled =
                    (item.value === null || item.value === "null") &&
                    hasSelectedValue;
                  return (
                    <option
                      key={item.value}
                      value={item.value}
                      disabled={isOptionDisabled}
                    >
                      {item.label}
                    </option>
                  );
                })}
            </select>
          </div>
        )}

        {type === "radio" && (
          <div
            className={`radio-buttons ${styles.radioButtons} ${hasError ? "is-danger" : ""
              }`}
          >
            {options &&
              options.map((item) => (
                <label key={item.value} className={styles.radioLabel}>
                  <input
                    type="radio"
                    name={name}
                    value={item.value}
                    className={styles.radioInput}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={disabled}
                    checked={value === null ? null : value === item.value}
                  />
                  {item.label}
                </label>
              ))}
          </div>
        )}

        {type === "date" && (
          <input
            name={name}
            type="date"
            placeholder={placeholder}
            readOnly={readOnly}
            className={`input ${hasError ? "is-danger" : ""} ${styles.input}`}
            onChange={onChange}
            disabled={disabled}
            onBlur={onBlur}
            value={value || ""}
          />
        )}

        {type === "phone" && (
          <PhoneInput
            name={name}
            className={`input ${hasError ? "is-danger" : ""} ${styles.input}`}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            value={value || ""}
            defaultCountry="CZ"
          />
        )}

        {(type === "input" ||
          type === "number" ||
          type === "text" ||
          !type) && (
            <input
              name={name}
              type={type === "number" ? "number" : "text"}
              placeholder={placeholder}
              readOnly={readOnly}
              className={`input ${disabled ? styles.disabled : ""} ${hasError ? "is-danger" : ""
                } ${styles.input}`}
              onChange={onChange}
              onKeyDown={(event) => {
                if (disabledControls && type === "number" && event.key === "e") {
                  event.preventDefault();
                }
                if (disabledControls && type === "number" && (event.key === "ArrowUp" || event.key === "ArrowDown")) {
                  event.preventDefault();
                }
              }}
              onWheel={(e) => e.target.blur()}
              onBlur={onBlur}
              disabled={disabled}
              value={value || ""}
              min={type === "number" ? min : null}
              max={type === "number" ? max : null}
              style={style}
            />
          )}

        {icon && (
          <span className={`icon ${styles.icon} is-small is-left`}>
            <i className={`fa-regular fa-${icon}`} />
          </span>
        )}

        {description && descriptionPlacement === "right" && (
          <div className={`help ${styles.help}`} style={descriptionStyle}>
            {description}
          </div>
        )}

        {hasError && (
          <span className={`icon ${styles.icon} is-small is-right is-danger`}>
            <i className="fa fa-exclamation-triangle" />
          </span>
        )}
      </div>

      {description && descriptionPlacement === "bottom" && (
        <div className={`help ${styles.help}`} style={descriptionStyle}>
          {description}
        </div>
      )}

      {hasError && <p className="help is-danger">{errorString}</p>}
    </div>
  );
};

FormInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
  icon: PropTypes.string,
  label: PropTypes.string,
  labelTrue: PropTypes.string,
  labelFalse: PropTypes.string,
  description: PropTypes.string,
  hasSelectedValue: PropTypes.bool,
  name: PropTypes.string,
  tooltipStyle: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  readOnly: PropTypes.bool,
  multiple: PropTypes.bool,
  autocomplete: PropTypes.bool,
  type: PropTypes.string,
  min: PropTypes.number,
  disabledControls: PropTypes.bool,
  max: PropTypes.number,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  props: PropTypes.object,
  tooltip: PropTypes.string,
  onAutocomplete: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  descriptionPlacement: PropTypes.oneOf(["top", "bottom", "right"]),
  descriptionStyle: PropTypes.object,
  useBooleanToggle: PropTypes.bool,

  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
  ]),
};
FormInput.defaultProps = {
  className: null,
  error: null,
  icon: null,
  disabledControls: false,
  label: null,
  labelTrue: null,
  tooltip: null,
  hasSelectedValue: false,
  labelFalse: null,
  tooltipStyle: null,
  autocomplete: false,
  description: null,
  name: null,
  placeholder: null,
  options: null,
  readOnly: false,
  multiple: false,
  onAutocomplete: () => null,
  type: "text",
  min: null,
  max: null,
  style: {},
  disabled: false,
  props: {},
  onBlur: () => null,
  descriptionPlacement: "top",
  descriptionStyle: {},
  useBooleanToggle: false,
};

export default FormInput;
export { FormInput, FormInputGroup };
