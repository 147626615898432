import React, { useContext } from "react";
// import PropTypes from 'prop-types';
import LayoutMujUcet from "../Layouts/LayoutMujUcet.js";
import UserContext from "../UserContext.js";
//import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
//import dayjs from "dayjs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import getUserAvatar from "../Helpers/avatar.js";
import { filesizeFormat } from "../Helpers/filesize.js";
import { HttpUpload, HttpDelete } from "../HTTP.js";
import Styles from "./ProfilAvatar.module.scss";
import { isArray } from "lodash";

const ProfilDarceDanove = () => {
  //const navigate = useNavigate();
  const user = useContext(UserContext);
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);

  const allowedFileTypes = ["image/jpeg", "image/png"];
  const allowedFileSize = 250 * 1024;

  const handleAvatarUpload = (e) => {
    const file = e.target.files[0];
    const type = file.type;
    const size = file.size;

    if (!allowedFileTypes.includes(type)) {
      // ERROR - unsopported file type
      MySwal.fire({
        text: t(`profile-avatar-unsupported-file-type`),
        icon: "error",
        confirmButtonText: t("login-error-ok-button"),
      });
      return false;
    }

    if (allowedFileSize < size) {
      // error over size limit
      MySwal.fire({
        text: t(`frm-file-oversize`, {
          maxsize: filesizeFormat(allowedFileSize),
        }),
        icon: "error",
        confirmButtonText: t("login-error-ok-button"),
      });
      return false;
    }

    MySwal.fire({
      text: t(`profile-avatar-uploading`),
      showCloseButton: false,
      showConfirmButton: false,
    });
    MySwal.showLoading();

    const oldAvatars = user?.fullUser?.Images;
    if (isArray(oldAvatars)) {
      oldAvatars.forEach((avatar) => {
        if (!avatar.IsPrimary) {
          HttpDelete(`userProfileAvatar/${avatar.Id}`);
        }
      });
    }

    HttpUpload(`userProfileAvatar`, [{ file, cat: "avatar" }])
      .then((resp) => {
        MySwal.fire({
          text: t(`profile-avatar-upload-${resp.response}`),
          icon: "success",
        });
        handleRefresh();
      })
      .catch((resp) => {
        MySwal.fire({
          text: t(`profile-avatar-upload-${resp.response}`),
          icon: "error",
        });
        handleRefresh();
      });
  };

  const handleRefresh = () => {
    user.updateUserData();
  };

  return (
    <LayoutMujUcet pageTitle={t("profil-uprava-avatara")}>
      <div className={Styles.wrap}>
        <div className={Styles.box}>
          <h1 className="title">{t("profil-uprava-avatara")}</h1>
          <div>
            <img
              key={`${user.personalNumber}-${user.avatar}`}
              className="header-overflow-box-img avatar"
              src={getUserAvatar(user)}
              alt={user.displayName}
            />
          </div>

          <div>
            <form>
              <label
                htmlFor="avatar-upload-field"
                className={`button is-rounded is-light ${Styles.file}`}
              >
                {t("frm-button-file-select")}
              </label>
              <input
                type="file"
                id="avatar-upload-field"
                value=""
                onChange={handleAvatarUpload}
                className={`hidden`}
              />
            </form>
          </div>
        </div>
      </div>
    </LayoutMujUcet>
  );
};

// ProfilDarceDanove.propTypes = {}
// ProfilDarceDanove.defaultProps = {}

export default ProfilDarceDanove;
