import React, { Suspense, Fragment, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { createRoot } from "react-dom/client";
import packInfo from "../package.json";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { SWRConfig } from "swr";
import { HttpGet } from "./HTTP.js";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import UserContext, { UserContextStore } from "./UserContext.js";
import "./i18n.js";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/cs";
import "dayjs/locale/en";
import "./index.scss";
import routes from "./routes.json";
import imgLoading from "./assets/animations/loading.gif";
import Page404 from "./Pages/404.js";
import Page403 from "./Pages/403.js";
import PageLogin from "./Pages/Login.js";
import PrijemceLogin from "./Pages/PrijemceLogin.js";

const getRouteComponent = (name) => {
  try {
    return require(`./Pages/${name}.js`).default;
    // return React.lazy(() => import(`./Pages/${name}.js`));
  } catch {
    console.error(`Page component with name "${name}" was not found!`);
    return Page404;
  }
};

const AuthorizedRoute = ({ loginScope, children }) => {
  const { isLoggedIn, loginScope: userLoginScope } = useContext(UserContext);
  const [queryParam] = useSearchParams();
  const { pathname } = useLocation();
  const emlid = queryParam.get("emlid");
  const allowEmlId =
    loginScope.includes("emlid") && !isEmpty(emlid) ? true : false;

  //console.log({ allowEmlId, loginScope, isLoggedIn });

  if (loginScope !== "*" && !allowEmlId && loginScope !== false) {
    if (isLoggedIn !== true) {
      if (pathname.includes("prijemce")) {
        return <PrijemceLogin />;
      } else {
        return <PageLogin />;
      }
    }

    if (!loginScope.includes(userLoginScope)) {
      return <Page403 />;
    }
  }

  return children;
};
AuthorizedRoute.propTypes = {
  loginScope: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  children: PropTypes.object.isRequired,
};
AuthorizedRoute.defaultProps = {
  loginScope: "*",
};

function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return children;
}
//const ScrollToTop = withRouter(_ScrollToTop);

const App = () => {
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n && i18n.language);

  /*
  // <Profiler id="App" onRender={profilerOnRender}>
  const profilerOnRender = (
    id,
    phase,
    actualDuration,
    baseDuration,
    startTime,
    commitTime
  ) => {
    console.group(`${id}'s ${phase} phase:`);
    console.log(`Actual duration: ${actualDuration}`);
    console.log(`Base duration: ${baseDuration}`);
    console.log(`Start time: ${startTime}`);
    console.log(`Commit time: ${commitTime}`);
    console.groupEnd();
  };
  */

  return (
    <Fragment>
      <Helmet>
        <title>{t("header-defaultTitle")}</title>
      </Helmet>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          fetcher: (query) => HttpGet(query).then((resp) => resp.response),
          onError: (err, key, config) => {
            Bugsnag.notify({ err, key, config });
          },
        }}
      >
        <BrowserRouter>
          <ScrollToTop>
            <Suspense
              fallback={
                <section id="app">
                  <div
                    style={{
                      width: "100%",
                      height: "100vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={imgLoading} alt={t("loading")} />
                    {t("loading")}
                  </div>
                </section>
              }
            >
              <Routes>
                {routes &&
                  routes.map((route) => {
                    const RouteComponent = getRouteComponent(route.component);
                    //console.log({ p: route.path, RouteComponent, route });

                    return (
                      <Route
                        key={`${route.path}-${route.component}`}
                        path={route.path}
                        exact={route.exact || false}
                        element={
                          // Good! Do your composition here instead of wrapping <Route>.
                          // This is really just inverting the wrapping, but it's a lot
                          // more clear which components expect which props.
                          <AuthorizedRoute
                            loginScope={route.loginScope || false}
                          >
                            <RouteComponent params={route.params} />
                          </AuthorizedRoute>
                        }
                      />
                    );
                  })}
                <Route path="*" element={<Page404 />} />
              </Routes>
            </Suspense>
          </ScrollToTop>
        </BrowserRouter>
      </SWRConfig>
    </Fragment>
  );
};

Bugsnag.start({
  apiKey: "d2073e831d756e62533171144fa732d9", //Devskim: ignore DS173237
  plugins: [new BugsnagPluginReact()],
  appVersion: packInfo.version,
  enabledReleaseStages: ["production", "staging"],
  // releaseStage: 'staging',
  onError: (event) => {
    if (window && window.profile) {
      event.setUser(
        window.profile.personalNumber,
        window.profile.email,
        window.profile.displayName
      );
    }
  },
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const container = document.getElementById("app");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ErrorBoundary>
    <UserContextStore>
      <App />
    </UserContextStore>
  </ErrorBoundary>
);
