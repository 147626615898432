import React, { useEffect, Fragment, useState, useRef } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useFormik, FormikProvider } from "formik";
import FormInput, { FormInputGroup } from "./FormInput.js";
import { filesizeFormat } from "../Helpers/filesize.js";
import FileIcon from "./FileIcon.js";
import { Button } from "./LinkButton.js";
import Style from "./FormJson.module.scss";
import { t } from "i18next";
import Loading from "./Loading.js";
import useDebounce from "../hooks/useDebounce.js";
import CheckList from "./CheckList.js";
import renderHTML from "html-react-parser";
// TODO: file upload - https://www.npmjs.com/package/react-use-upload

const FormJson = ({
  blueprint,
  validate,
  allowFiles,
  allowedFileTypes,
  allowedFileSize,
  fileCategories,
  onSubmit,
  disableSubmit,
  submitButtonLabel,
  useButtonMenu,
  onGoBackClick,
  gobackButtonLabel,
  onChange,
  isLoading,
  submitCheckbox,
  isGoBackDisabled,
  allowFileCheckList,
  fileCheckListLabel,
  fileCheckListItems,
  fileCheckListChange,
  removeButtons,
}) => {
  const MySwal = withReactContent(Swal);
  const [files, setFiles] = useState([]);
  const fileCatRef = useRef();

  const handleAddFile = (e) => {
    try {
      const type = e.target.files[0].type;
      const size = e.target.files[0].size;
      //console.log({ type, size, allowedFileSize, allowedFileTypes });

      if (allowedFileTypes) {
        if (!allowedFileTypes.includes(type)) {
          // ERROR - unsopported file type
          MySwal.fire({
            text: t(`frm-file-unsupported-file-type`),
            icon: "error",
            confirmButtonText: t("login-error-ok-button"),
          });
          return false;
        }
      }

      if (allowedFileSize > 0) {
        if (allowedFileSize < size) {
          // error over size limit
          MySwal.fire({
            text: t(`frm-file-oversize`, {
              maxsize: filesizeFormat(allowedFileSize),
            }),
            icon: "error",
            confirmButtonText: t("login-error-ok-button"),
          });
          return false;
        }
      }

      setFiles([
        ...files,
        {
          cat: fileCategories ? fileCatRef.current.value : -1,
          file: e.target.files[0],
        },
      ]);
    } catch (err) {
      //show error...
    }
  };

  const handleRemoveFile = (idxRemove) => {
    setFiles(files.filter((_item, idx) => (idx === idxRemove ? false : true)));
  };

  const hasErrorLoading = false;
  //const [hasErrorLoading,setErrorLoading] = useState(false);

  useEffect(() => {
    //loadData...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (data) => {
    onSubmit && onSubmit(data, files);
  };

  let frmInitialValues = {};
  let tableSumRows = [];
  blueprint.forEach((item, tableIndex) => {
    if (item.itemType === "table") {
      tableSumRows[tableIndex] = { sums: [], res: null };
      item.rows.forEach((row) => {
        row.cells.forEach((cell, idx) => {
          if (idx === 1) tableSumRows[tableIndex].sums.push(cell.item);
          const frmId = `formIdx-${cell.item.id}`;
          let value = "";
          if (cell.item.question && cell.item.question.length > 0) {
            const question = JSON.parse(cell.item.question);
            value = question.value;
          }
          frmInitialValues[frmId] = value;
        });
      });
      item.footer.cells.forEach((cell) => {
        if (cell.item.required && cell.item.question.length > 0) {
          const frmId = `sum-${cell.item.id}`;
          tableSumRows[tableIndex].res = cell.item;
          frmInitialValues[frmId] = "";
        }
      });
    }

    const frmId = `formIdx-${item.id}`;
    frmInitialValues[frmId] = item.question ? item.question.value : "";
    if (item.itemType === "list") {
      frmInitialValues[`${frmId}-more`] = "";
    }
  });

  const handleValidate = (values) => {
    const errors = {};
    blueprint.forEach((item) => {
      const frmId = `formIdx-${item.id}`;
      const value = values[frmId];

      if (item.required === true) {
        if (!value) {
          errors[frmId] = t("frm-validation-required");
        }
      }
      // console.log({ value, item });
    });

    return errors;
  };

  const formik = useFormik({
    initialValues: frmInitialValues,
    onSubmit: handleSubmit,
    validate: validate ? handleValidate : null,
    enableReinitialize: true,
  });

  const debouncedFieldValue = useDebounce(formik.values, 500);

  useEffect(() => {
    if (debouncedFieldValue !== "") {
      for (let table of tableSumRows) {
        if (table) {
          if (!table.res) return;
          let inputsSum = 0;
          for (let sum of table.sums) {
            let id = `formIdx-${sum.id}`;
            let value = formik.values[id];
            if (value && value !== "" && !isNaN(value)) {
              inputsSum += parseInt(value);
            }
          }
          let id = `sum-${table.res.id}`;
          formik.setFieldValue(id, inputsSum);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFieldValue]);

  const { errors, isValid, isSubmitting } = formik;

  useEffect(() => {
    if (isSubmitting && !isValid) {
      MySwal.fire({
        text: t(`frm-validation-required-field-missing`),
        icon: "error",
        confirmButtonText: t("login-error-ok-button"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, isSubmitting]);

  const fieldTypes = {
    financialSituation: {
      itemType: "number",
    },
    numeric: {
      itemType: "number",
    },
    paragraph: {
      itemType: "text",
      multiline: true,
    },
  };

  useEffect(() => {
    if (onChange) onChange(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  // console.log(formik.values)
  const renderInput = (
    item,
    type,
    itemKey,
    props = {},
    className = "",
    controlDisabled
  ) => {
    props = {
      ...props,
      required: item.required,
      descriptionStyle:
        item.description.length < 3
          ? { textAlign: "left", marginBottom: "0px", paddingLeft: "5px" }
          : { textAlign: "left", marginBottom: "0px" },
    };
    let style = {};

    // if (item.required) {
    //   style = { border: "1px solid #E11E0F" }
    // }
    // eslint-disable-next-line react/prop-types
    if (props.style) {
      // eslint-disable-next-line react/prop-types
      style = { ...style, ...props.style };
      props = { ...props, style: style };
    }

    if (fieldTypes[type] && fieldTypes[type].itemType) {
      item = { ...item, itemType: fieldTypes[type].itemType };
      type = fieldTypes[type].itemType;
    }

    if (itemKey === "formIdx-104") {
      const injectHTML = `<div style="margin-bottom:20px" key="${itemKey}">${item.text}</div>`;
      return renderHTML(injectHTML);
    }

    if (type === "text") {
      if (item.multiline === true) {
        return (
          <FormInput
            key={itemKey}
            type="textarea"
            name={itemKey}
            description={item.description}
            label={item.title}
            onChange={formik.handleChange}
            style={style}
            error={errors}
            value={formik.values[itemKey]}
            {...props}
          />
        );
      }

      return (
        <FormInput
          key={itemKey}
          type="text"
          name={itemKey}
          style={style}
          description={item.description}
          label={item.title}
          onChange={formik.handleChange}
          error={errors}
          value={formik.values[itemKey]}
          {...props}
        />
      );
    }

    if (type === "dateTime") {
      // todo...   formát např: „2016-06-16T00:00:00Z“
      return (
        <FormInput
          key={itemKey}
          type="date"
          name={itemKey}
          style={style}
          description={item.description}
          label={item.title}
          onChange={formik.handleChange}
          error={errors}
          value={formik.values[itemKey]}
          {...props}
        />
      );
    }

    if (type === "boolean") {
      return (
        <FormInput
          key={itemKey}
          type="toggle"
          name={itemKey}
          label={item.title}
          style={style}
          labelTrue={item.question.trueText ?? "Ano"}
          labelFalse={item.question.falseText ?? "Ne"}
          description={item.description}
          onChange={formik.handleChange}
          error={errors}
          value={formik.values[itemKey] + ""}
          useBooleanToggle
          {...props}
        />
      );
    }
    if (type === "number" || type === "numeric") {
      return (
        <FormInput
          key={itemKey}
          type="number"
          name={itemKey}
          min={0}
          disabledControls={controlDisabled}
          description={item.description}
          style={style}
          label={item.title}
          onChange={formik.handleChange}
          error={errors}
          className={className}
          value={formik.values[itemKey] + ""}
          {...props}
        />
      );
    }

    if (type === "list") {
      return (
        <Fragment key={itemKey}>
          <FormInput
            key={itemKey}
            type="multiselect"
            name={itemKey}
            label={item.title}
            style={style}
            description={item.description}
            options={item.question.items?.map((opt, optidx) => {
              return { value: optidx, label: opt.title };
            })}
            onChange={formik.handleChange}
            error={errors}
            value={formik.values[itemKey]}
            {...props}
          />

          <>
            {item.question.items?.reduce((ret, question, questionIdx) => {
              const questionSelected = formik.values[itemKey];
              if (!questionSelected) return false;
              if (
                question.hasText &&
                questionSelected.includes(String(questionIdx))
              ) {
                return true;
              }
              return ret;
            }, false) && (
              <FormInput
                key={`${itemKey}-more`}
                type="textarea"
                name={`${itemKey}-more`}
                label={t("frm-field-more-text")}
                className={Style.inputMoreText}
                style={style}
                onChange={formik.handleChange}
                error={errors}
                value={formik.values[`${itemKey}-more`]}
                {...props}
              />
            )}
          </>
        </Fragment>
      );
    }
  };

  const rowStyles = (idx) => {
    if (idx === 0) return { flex: 1, maxWidth: 200 };
    if (idx === 1) return { width: "30%", maxWidth: "30%" };
    if (idx === 2) return { width: "30%", maxWidth: "30%" };
  };

  return (
    <FormikProvider value={formik}>
      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            zIndex: 50,
            background: "rgba(0,0,0,0.8)",
            position: "fixed",
            left: 0,
            top: 0,
          }}
        >
          <Loading />
        </div>
      )}
      <form
        onSubmit={formik.handleSubmit}
        className={`${hasErrorLoading ? "hidden" : ""}`}
      >
        <FormInputGroup layout="1fr" className={Style.formGroup}>
          {blueprint.map((item) => {
            const itemKey = `formIdx-${item.id}`;
            const type = item.itemType;

            if (type && type !== "table") {
              return renderInput(item, type, itemKey);
            }

            if (type === "table") {
              return (
                <table
                  className="table is-bordered is-striped is-narrow is-fullwidth"
                  key={`table-${item.key}`}
                  style={{ tableLayout: "fixed" }}
                >
                  <thead>
                    <tr>
                      {item.header.cells.map((header, idx) => (
                        <th
                          key={`header-${header.item.id}`}
                          style={rowStyles(idx)}
                        >
                          {header.item.text}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {item.rows.map((row, rowIndex) => (
                      <tr key={`row-${rowIndex}-${item.id}`}>
                        {row.cells.map((cell, idx) => (
                          <td
                            key={`cell-${cell.item.id}`}
                            style={rowStyles(idx)}
                          >
                            {cell.item.itemType === "paragraph" ? (
                              <p>{cell.item.text}</p>
                            ) : (
                              renderInput(
                                cell.item,
                                cell.item.itemType,
                                `formIdx-${cell.item.id}`,
                                {
                                  descriptionPlacement:
                                    cell.item.description.length < 3
                                      ? "right"
                                      : "bottom",
                                  style: {
                                    textAlign:
                                      cell.item.itemType === "numeric"
                                        ? "right"
                                        : "left",
                                  },
                                },
                                "",
                                true
                              )
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                    <tr>
                      {item.footer.cells.map((footer, idx) => {
                        if (
                          footer.item.required &&
                          footer.item.question.length > 0
                        ) {
                          return (
                            <td
                              key={`footer-${footer.item.id}`}
                              style={rowStyles(idx)}
                            >
                              {renderInput(
                                footer.item,
                                "number",
                                `sum-${footer.item.id}`,
                                {
                                  readOnly: true,
                                  style: {
                                    fontWeight: "bold",
                                    backgroundColor: "#f9f9f9",
                                  },
                                }
                              )}
                            </td>
                          );
                        }
                        return (
                          <td
                            key={`footer-${footer.item.id}`}
                            id={`footer-${footer.item.id}`}
                          >
                            <div className={"table-footer-content"}>
                              {footer.item.text}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              );
            }

            return <div key={itemKey}>{item.itemType}</div>;
          })}
        </FormInputGroup>

        {allowFiles && (
          <section className={Style.addFilesWrap}>
            <label className={`label ${Style.filesLabel}`}>Přílohy</label>
            <div className="help">{t("frm-file-help")}</div>
            <div className="help">{t("frm-file-help-formats")}</div>
            {/* <div className={`help ${Style.fileshelp}`}>xxx</div> */}

            <div className={Style.filesBlock}>
              {files.map((item, idx) => {
                return (
                  <div
                    key={`file-${idx}-${item.file.name}`}
                    className={Style.fileItem}
                  >
                    <span className={Style.fileItemIcon}>
                      <FileIcon type={item.file.type} />
                    </span>
                    <span className={Style.fileItemName}>{item.file.name}</span>
                    <span className={Style.fileItemSize}>
                      {filesizeFormat(item.file.size)}
                    </span>

                    {fileCategories && (
                      <span className={Style.fileItemCat}>
                        {fileCategories.reduce(
                          (ret, cat) =>
                            parseInt(cat.value, 10) === parseInt(item.cat, 10)
                              ? cat.label
                              : ret,
                          "?"
                        )}
                      </span>
                    )}

                    <span>
                      <button
                        type="button"
                        className="button is-rounded is-small is-primary"
                        onClick={() => handleRemoveFile(idx)}
                      >
                        <i className="fa-regular fa-trash single-icon" />
                      </button>
                    </span>
                  </div>
                );
              })}
            </div>

            <div className={Style.fileUploadBlock}>
              {fileCategories && (
                <div className={`select ${Style.fileCatSelect}`}>
                  <select ref={fileCatRef} name="upload-files-categories">
                    {fileCategories.map((cat) => (
                      <option key={cat.value} value={cat.value}>
                        {cat.label}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className={`${Style.fileField}`}>
                <label
                  htmlFor="file-upload-field"
                  className={`button is-rounded is-light`}
                >
                  {t("frm-button-file-select")}
                </label>
                <input
                  type="file"
                  id="file-upload-field"
                  value=""
                  onChange={handleAddFile}
                  className={`hidden`}
                />
              </div>
            </div>
          </section>
        )}
        <div>
          {allowFiles && (
            <>
              {allowFileCheckList && (
                <p className={"font-bold text-big mb-3"}>
                  {fileCheckListLabel}
                </p>
              )}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {allowFileCheckList &&
                  fileCheckListItems.map((list, idx) => (
                    <div key={`checklist-${idx}`}>
                      <p className={`font-bold mb-4`}>{t(list.label)}</p>
                      <CheckList items={list.items} onChange={list.onChange} />
                    </div>
                  ))}
              </div>
            </>
          )}

          {submitCheckbox.label && (
            <FormInput
              type="checkbox"
              name={submitCheckbox.name}
              label={submitCheckbox.label}
              onChange={submitCheckbox.onChange}
              value={submitCheckbox.value}
            />
          )}
          {useButtonMenu ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 20,
              }}
            >
              <Button
                type="button"
                onClick={() => onGoBackClick && onGoBackClick()}
                disabled={isGoBackDisabled}
              >
                {gobackButtonLabel}
              </Button>
              <Button type="submit" disabled={disableSubmit}>
                {disableSubmit && (
                  <i className="fa-thin fa-spinner-third fa-spin"></i>
                )}
                {submitButtonLabel}
              </Button>
            </div>
          ) : (
            <>
              {!removeButtons && (
                <Button type="submit" disabled={disableSubmit}>
                  {disableSubmit && (
                    <i className="fa-thin fa-spinner-third fa-spin"></i>
                  )}
                  {submitButtonLabel}
                </Button>
              )}
            </>
          )}
        </div>
      </form>
    </FormikProvider>
  );
};

FormJson.propTypes = {
  blueprint: PropTypes.array.isRequired,
  allowFiles: PropTypes.bool,
  allowedFileTypes: PropTypes.array,
  allowedFileSize: PropTypes.number,
  fileCategories: PropTypes.array,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  allowFileCheckList: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  submitButtonLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  fileCheckListChange: PropTypes.func,
  submitCheckbox: PropTypes.object,
  fileCheckListLabel: PropTypes.string,
  fileCheckListItems: PropTypes.array,
  validate: PropTypes.bool,
  useButtonMenu: PropTypes.bool,
  onGoBackClick: PropTypes.func,
  isGoBackDisabled: PropTypes.bool,
  gobackButtonLabel: PropTypes.string,
  removeButtons: PropTypes.bool,
};
FormJson.defaultProps = {
  submitCheckbox: {},
  validate: true,
  allowFiles: false,
  allowedFileTypes: null,
  fileCheckListChange: () => null,
  allowedFileSize: null,
  fileCheckListItems: [],
  fileCategories: null,
  useButtonMenu: false,
  isGoBackDisabled: false,
  gobackButtonLabel: t("frm-button-left"),
  onGoBackClick: () => null,
  allowFileCheckList: false,
  disableSubmit: false,
  fileCheckListLabel: t("frm-file-checklist-label"),
  submitButtonLabel: t("frm-button-submit"),
  isLoading: false,
};

export default FormJson;
