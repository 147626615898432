import * as Yup from "yup";
import dayjs from "dayjs";
import { t } from "i18next";

export const validationSchema = Yup.object()
  .shape({
    FirstName: Yup.string().nullable().required(t("frm-first-name-error")),
    LastName: Yup.string().nullable().required(t("frm-last-name-error")),
    PatientFirstName: Yup.string()
      .nullable()
      .required(t("frm-patient-first-name-error")),
    PatientLastName: Yup.string()
      .nullable()
      .required(t("frm-patient-last-name-error")),

    Address: Yup.object()
      .shape({
        Street: Yup.string().required(t("frm-street-required")),
        HouseNumber: Yup.string().required(t("frm-houseNumber-required")),
        City: Yup.string().required(t("frm-city-required")),
        PostalCode: Yup.string()
          .max(6, t("frm-postalCode-error"))
          .required(t("frm-postalCode-required")),
      })
      .required(t("frm-validation-required")),

    Account: Yup.object()
      .shape({
        AccountNumberPrefix: Yup.string().matches(
          /^\d{0,6}$/,
          t("frm-accountNumberPrefix-pattern-error")
        ),
        AccountNumber: Yup.string()
          .matches(/^\d{1,10}$/, t("frm-accountNumber-pattern-error"))
          .required(t("frm-accountNumber-required")),
        BankCode: Yup.string()
          .matches(/^\d{1,4}$/, t("frm-bankCode-pattern-error"))
          .required(t("frm-bankCode-required")),
      })
      .notRequired(),

    Phone: Yup.object()
      .shape({
        Value: Yup.string()
          .min(8, t("frm-phoneNumber-error"))
          .max(24, t("frm-phoneNumber-error"))
          .matches(/^[0-9+\- ()/]+$/iu, {
            message: t("frm-phoneNumber-error"),
            excludeEmptyString: true,
          })
          .nullable(),
      })
      .required(t("frm-validation-required")),

    YearOfBirth: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable()
      .min(
        dayjs().subtract(100, "year").format("YYYY"),
        t("frm-birth-year-error")
      )
      .max(dayjs().format("YYYY"), t("frm-birth-year-error")),
  })
  .required();

export default validationSchema;

// .required(t("frm-validation-required")),
