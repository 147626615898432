export const apiUrl = process.env.REACT_APP_URL_API;
export const selfUrl = process.env.REACT_APP_URL_SELF;
export const apiImageUrl = process.env.REACT_APP_URL_IMG;
export const apiDocUrl = process.env.REACT_APP_URL_DOC;
export const oouLink = "https://www.dobryandel.cz/oou/";
export const webLink = "https://www.dobryandel.cz/";
export const registrationLink =
  "https://www.dobryandel.cz/profil-dobreho-andela/registrace/";
export const blogLink = "https://www.dobryandel.cz/rubrika/andelsky-blog/";

export const getWebLink = (lang = "cs") => {
  return lang === "en" ? `${webLink}/en/` : webLink;
};
