import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const PaymentsHistory = ({ limit, hasMore, data }) => {
  const { t, i18n } = useTranslation();
  // console.log({ data });

  useEffect(() => {
    if (data && limit) {
      const dataSize = data.length;

      if (dataSize > limit) {
        if (hasMore) hasMore(true);
      }
    }
  }, [data, limit, hasMore]);

  return (
    <div className="table-container">
      <table className="table is-striped is-hoverable is-narrow">
        <thead>
          <tr>
            <th className="has-text-left">{t("payment-date-contirution")}</th>
            <th className="has-text-right">{t("payment-ammount")}</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length === 0 && (
            <tr>
              <th>
                <p style={{ fontWeight: "normal" }}>{t("no-payments-found")}</p>
              </th>
            </tr>
          )}
          {data &&
            data.length > 0 &&
            data.map((item, index) => {
              if (limit > 0 && index >= limit) return false;

              return (
                <tr key={index}>
                  <th className="has-text-left">
                    {dayjs(item.RedistributionDate).format(
                      t("date-month-year")
                    )}
                  </th>
                  <td className="has-text-right">
                    {Intl.NumberFormat(i18n && i18n.language, {
                      style: "currency",
                      currency: "CZK",
                    }).format(item.Amount)}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

PaymentsHistory.propTypes = {
  limit: PropTypes.number,
  hasMore: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

PaymentsHistory.defaultProps = {
  hasMore: null,
  limit: null,
};

export default PaymentsHistory;
