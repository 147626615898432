import React from "react";
import { useTranslation } from "react-i18next";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import RenderMd from "../Components/RenderMd.js";
import { SwalIconError } from "../Components/SwalIcon.js";
import { LinkButton } from "../Components/LinkButton.js";
import Styles from "./PaymentResult.module.scss";

const PagePaymentResultError = () => {
  const { t } = useTranslation();

  return (
    <LayoutBasic pageTitle={t("payment-error-title")}>
      <section className={Styles.wrap}>
        <SwalIconError />

        <RenderMd>md-payment-error</RenderMd>

        <div className={Styles.cta}>
          <LinkButton to="/jak-pomahat">
            {t("payment-how-to-button")}
          </LinkButton>
        </div>
      </section>
    </LayoutBasic>
  );
};

export default PagePaymentResultError;
