import React, { useState, useRef, useContext } from "react";
import Styles from "./AdminLinkGenerator.module.scss";
import { HttpPost } from "../HTTP.js";
import UserContext from "../UserContext.js";
import Papa from "papaparse";
import { saveAsFile } from "../Helpers/saveAsFile.js";

const AdminLinkGenerator = () => {
  const { removeUserObject } = useContext(UserContext);
  const targetListRef = useRef();
  const [selectedLinkType, setSelectedLinkType] = useState(null);
  const [generatedLinks, setGeneratedLinks] = useState(null);

  const linkTypes = [
    { id: "oou", title: "OOU" },
    { id: "nps", title: "NPS" },
    { id: "adepti", title: "Adepti" },
    { id: "hlasovani", title: "Hlasovaní" },
    { id: "obnoveni-andelstvi", title: "Obnovení andělství" },
  ];

  const handleGenerateLinks = () => {
    const donors = targetListRef.current.value?.split("\n");

    HttpPost("linkGenerator", { donors, type: selectedLinkType })
      .then((resp) => {
        if (resp.error_code !== 200) {
          return false;
        }

        setGeneratedLinks(resp.response);
      })
      .catch(() => {});
  };

  const exportCsv = () => {
    const csvExport = Papa.unparse(generatedLinks, { delimiter: "," });
    saveAsFile(csvExport, "text/csv", `export.csv`);
  };

  const handleLogout = () => {
    removeUserObject();
  };

  return (
    <div className={Styles.pageWrap}>
      <div className="columns mt-1 is-vcentered">
        <div className="column">
          <h1 className="title">Link Generator</h1>
        </div>
        <div className="column">
          <button
            className="button is-link is-outlined is-pulled-right"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>

      <div className="columns mt-1 is-vcentered">
        <div className="column is-one-third">
          <nav className="panel">
            <p className="panel-heading">Typ linku</p>
            {linkTypes.map((linkType) => {
              const isActive = linkType.id === selectedLinkType;
              return (
                <span
                  key={linkType.id}
                  className={`panel-block ${isActive ? "is-active" : ""} ${
                    Styles.selectorItem
                  }`}
                  onClick={() => {
                    setSelectedLinkType(linkType.id);
                  }}
                >
                  <span className="panel-icon">
                    <i
                      className={`fa-regular ${
                        isActive ? "fa-circle-check" : "fa-circle"
                      }`}
                    ></i>
                  </span>
                  {linkType.title}
                </span>
              );
            })}
          </nav>
        </div>

        <div className="column">
          <div className="panel">
            <p className="panel-heading">
              Andělská čísla (jedna hodnota na řádek)
            </p>
            <div className="panel-block">
              <textarea
                ref={targetListRef}
                className="textarea"
                style={{ height: "190px" }}
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
        <button className="button is-primary" onClick={handleGenerateLinks}>
          Generuj odkazy &gt;&gt;
        </button>

        {generatedLinks && (
          <button className="button is-secondary" onClick={exportCsv}>
            Uložit jako CSV
          </button>
        )}
      </div>

      <div className="mt-5">
        <div className="panel">
          <p className="panel-heading">Odkazy</p>

          <div className="panel-block">
            <table className="table is-bordered is-striped is-narrow is-fullwidth">
              <thead>
                <tr>
                  {generatedLinks &&
                    Object.keys(generatedLinks[0]).map((itemValue, itemIdx) => (
                      <th key={itemIdx}>{itemValue}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {generatedLinks &&
                  generatedLinks.map((item, idx) => (
                    <tr key={`${idx}-${item.emlid}`}>
                      {Object.values(item).map((itemValue, itemIdx) => (
                        <td key={itemIdx}>{itemValue}</td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLinkGenerator;
