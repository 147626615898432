import React, { useContext } from "react";
//import PropTypes from "prop-types";
import LinkButton from "../Components/LinkButton.js";
import { isEmpty } from "lodash";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import UserContext from "../UserContext.js";

const GlobalNotification = () => {
  const user = useContext(UserContext);
  const { t } = useTranslation();

  const { data: pageNotification } = useSWR(
    "valueStore/header_web_notice,header_web_notice_text",
    { refreshInterval: 300 * 1000 }
  );

  //console.log(user);

  return (
    <div>
      {pageNotification?.header_web_notice && (
        <div
          className={`notification is-${pageNotification?.header_web_notice}`}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: pageNotification?.header_web_notice_text,
            }}
          />
        </div>
      )}

      {user &&
        user.isLoggedIn &&
        user.loginScope === "donor" &&
        isEmpty(user.email) && (
          <div className={`notification is-warning`}>
            <i className="fa fa-regular fa-warning" />
            <span>{t("notification-no-email")}</span>
            <LinkButton to="/muj-ucet/email" isSmall>
              {t("menu-muj-ucet-uprava-udaju")}
            </LinkButton>
          </div>
        )}
    </div>
  );
};

//GlobalNotification.propTypes = {};

export default GlobalNotification;
