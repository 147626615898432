import React, { useContext } from "react";
// import PropTypes from 'prop-types';
import LayoutMujUcet from "../Layouts/LayoutMujUcet.js";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Button } from "../Components/LinkButton.js";
import UserContext from "../UserContext.js";
import { HttpPost } from "../HTTP.js";
import { tokenizeString } from "../Helpers/string-tokenizer.js";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import RenderMd from "../Components/RenderMd.js";
import Styles from "./ProfilDarceOouSouhlas.module.scss";
import { oouLink } from "../appConfig.js";

const ProfilDarceOouSouhlas = () => {
  const user = useContext(UserContext);
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const handleOouRevoke = () => {
    MySwal.fire({
      title: t("profil-souhlasy-oouRevokeBtn"),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("buttonConfirm"),
      cancelButtonText: t("buttonCancel"),
    })
      .then(({ isConfirmed }) => {
        if (isConfirmed) {
          MySwal.fire({
            title: t("working"),
            showCloseButton: false,
            showConfirmButton: false,
          });
          MySwal.showLoading();

          HttpPost("userProfile", { OOUValue: false })
            .then((resp) => {
              const errorCode = resp && resp.error_code;

              if (errorCode !== 200) {
                MySwal.fire({
                  text: t(`login-error-${tokenizeString(resp && resp.error)}`),
                  icon: "error",
                  confirmButtonText: t("login-error-ok-button"),
                });
                return;
              }
              // logout
              user.removeUserObject();

              /*
            // reload user profile and redirect to home page
            user.setAccessToken(resp.response.token, resp.response.expire);
            user.setUserObject( resp.response.scope, resp.response.profile, resp.response.profile_full );
            */

              MySwal.fire({
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              navigate("/logout");
            })
            .catch((err) => {
              //err
            });
        }
      })
      .catch((err) => {});
  };

  return (
    <LayoutMujUcet pageTitle={t("profil-souhlasy-title")}>
      <section className={Styles.header}>
        <h1 className="title">{t("profil-souhlasy-title")}</h1>
      </section>

      <section className={Styles.oou}>
        <p>
          {t("profil-souhlasy-oouBody", {
            oou_date: dayjs(user?.fullUser?.OOUDate).format(t("date-full")),
          })}
        </p>

        <p>
          <a href={oouLink} target="_blank" rel="noopener noreferrer">
            {t("profil-souhlasy-oouOouLink")}
          </a>
        </p>
      </section>

      <section className={Styles.oouRevoke}>
        <RenderMd>md-oou-revoke</RenderMd>
        <div className="has-text-centered">
          <Button onClick={handleOouRevoke}>
            {t("profil-souhlasy-oouRevokeBtn")}
          </Button>
        </div>
      </section>
    </LayoutMujUcet>
  );
};

// ProfilDarceDokumenty.propTypes = {}
// ProfilDarceDokumenty.defaultProps = {}

export default ProfilDarceOouSouhlas;
