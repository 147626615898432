import React, { useContext, useState } from "react";
// import PropTypes from 'prop-types';
import LayoutMujUcet from "../Layouts/LayoutMujUcet.js";
import { useNavigate, Navigate } from "react-router-dom";
// import { Button } from "../Components/LinkButton.js";
import UserContext from "../UserContext.js";
import { HttpPost } from "../HTTP.js";
import { tokenizeString } from "../Helpers/string-tokenizer.js";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import PasswordStrengthBar from "react-password-strength-bar";
import withReactContent from "sweetalert2-react-content";
import Styles from "./PasswordChange.module.scss";

const PasswordChange = () => {
  const user = useContext(UserContext);
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const username =
    user && user.userTempPassword && user.userTempPassword.username
      ? user.userTempPassword.username
      : (user && user.personalNumber) || "";
  const isResetMode =
    user && user.userTempPassword && user.userTempPassword.username
      ? true
      : false;
  const [isValidating, setIsValidating] = useState(false);
  const [oldPassword, setOldPassword] = useState(
    (user && user.userTempPassword && user.userTempPassword.tempPassword) || ""
  );
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      handlePasswordChange();
    }
  };

  const isNewPasswordValid =
    newPassword === newPasswordConfirm || newPassword === "" ? true : false;

  const handlePasswordChange = () => {
    if (newPassword.length < 6) {
      MySwal.fire({
        text: t(`err-minimum-length-for-newpassword-can-be-6-characters`),
        icon: "error",
        confirmButtonText: t("login-error-ok-button"),
      });
      return false;
    }

    if (newPassword !== newPasswordConfirm) {
      MySwal.fire({
        text: t(`err-new-password-mismatched`),
        icon: "error",
        confirmButtonText: t("login-error-ok-button"),
      });
      return false;
    }

    setIsValidating(true);

    HttpPost(
      "changePassword",
      { username, oldPwd: oldPassword, newPwd: newPassword },
      false
    ).then((resp) => {
      setIsValidating(false);

      if (resp.error_code !== 200) {
        MySwal.fire({
          text: t(`err-${tokenizeString(resp.error || "unknown")}`),
          icon: "error",
          confirmButtonText: t("login-error-ok-button"),
        });
        return false;
      }

      user.setUserTempPassword(null);
      if (user.isLoggedIn) {
        navigate("/muj-ucet");
      } else {
        navigate("/");
      }

      MySwal.fire({
        title: t("password-change-ok"),
        icon: "success",
        timer: 2000,
        toast: true,
        position: "center",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    });
  };

  if (!user || (!user.isLoggedIn && !user.userTempPassword)) {
    return <Navigate to="/login" />;
  }

  return (
    <LayoutMujUcet pageTitle={t("password-change-title")}>
      <section className={Styles.wrap}>
        <section className={Styles.passwordForm}>
          <section className={Styles.header}>
            <h1 className="title">{t("password-change-title")}</h1>
          </section>

          <div className="field">
            <label className="label">{t("password-change-old")}</label>
            <div className={"control has-icons-left"}>
              <input
                className="input"
                disabled={isValidating || isResetMode}
                type="password"
                name="current-password"
                autoComplete="current-password"
                value={oldPassword}
                placeholder={t("password-change-old")}
                autoFocus={!isResetMode ? true : false}
                onChange={(e) => setOldPassword(e.target.value)}
                onKeyDown={onInputKeyDown}
              />
              <span className="icon is-small is-left">
                <i className="fa-regular fa-unlock-alt" />
              </span>
            </div>
          </div>

          <hr />

          <div className="field">
            <div className={"control has-icons-left"}>
              <input
                className={`input ${isNewPasswordValid && " is-success"}`}
                disabled={isValidating}
                type="password"
                autoComplete="new-password"
                name="new-password"
                value={newPassword}
                placeholder={t("password-change-new")}
                autoFocus={isResetMode ? true : false}
                onChange={(e) => setNewPassword(e.target.value)}
                onKeyDown={onInputKeyDown}
              />
              <span className="icon is-small is-left">
                <i className="fa-regular fa-lock-alt" />
              </span>
            </div>
            <PasswordStrengthBar
              password={newPassword}
              minLength={6}
              shortScoreWord={t("password-rating-short")}
              scoreWords={[
                t("password-rating-1"),
                t("password-rating-2"),
                t("password-rating-3"),
                t("password-rating-4"),
                t("password-rating-5"),
              ]}
            />
          </div>

          <div className="field">
            <div className={"control has-icons-left"}>
              <input
                className={`input ${
                  isNewPasswordValid ? " is-success" : " is-danger"
                }`}
                disabled={isValidating}
                type="password"
                value={newPasswordConfirm}
                placeholder={t("password-change-new-confirm")}
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
                onKeyDown={onInputKeyDown}
              />
              <span className="icon is-small is-left">
                {newPassword === newPasswordConfirm ? (
                  <i className="fa-regular fa-check-square" />
                ) : (
                  <i className="fa-regular fa-exclamation-square" />
                )}
              </span>
            </div>
          </div>

          <div className="loginButton">
            <button
              className={`button is-rounded ${isValidating && " is-loading"}`}
              disabled={isValidating}
              onClick={handlePasswordChange}
            >
              {t("login-buttonChangePassword")}
            </button>
          </div>
        </section>
      </section>
    </LayoutMujUcet>
  );
};

// PasswordChange.propTypes = {}
// PasswordChange.defaultProps = {}

export default PasswordChange;
