import React from "react"
import PropTypes from "prop-types";

import Styles from "./AlertContainer.module.scss"

const AlertContainer = ({ title, description, status, style, children }) => {

    const variants = {
        error: {
            className: Styles.alertContainerError
        },
        warning: {
            className: Styles.alertContainerWarning
        },
        info: {
            className: Styles.alertContainerInfo
        },
        success: {
            className: Styles.alertContainerSuccess
        }
    }
    return (
        <div className={Styles.alertContainer + " " + variants[status].className} style={style}>
            <p className={Styles.title}>{title}</p>
            {children ? children : (
                <p className={Styles.description}>{description}</p>
            )}
        </div>
    )
}

AlertContainer.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    children: PropTypes.element,
    status: PropTypes.string,
    style: PropTypes.object
}

AlertContainer.defaultProps = {
    status: "info",
    style: {}
}

export default AlertContainer