import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import LayoutBasic from "./LayoutBasic.js";
import UserContext from "../UserContext.js";
import { Squash as Hamburger } from "hamburger-react";
import Styles from "./LayoutMujUcet.module.scss";
import Sections from "../Components/Sections.js";

const LayoutObnoveniZadosti = ({
  children,
  pageTitle,
  data,
  onSectionClick,
}) => {
  const { isLoggedIn } = useContext(UserContext);
  const [isOpen, setOpen] = useState(false);

  const handleClick = (section) => {
    onSectionClick(section);
    setOpen(false);
  }

  return (
    <LayoutBasic pageTitle={pageTitle}>
      <section className={Styles.wrap}>
        {isLoggedIn && (
          <>
            <div className={Styles.hamburgerToggle}>
              <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>
            <div
              className={`${Styles.customSide} ${isOpen ? Styles.isOpen : ""}`}
            >
              {data && <Sections data={data} />}
            </div>
          </>
        )}

        <div className={Styles.content}>{children}</div>
      </section>
    </LayoutBasic>
  );
};

LayoutObnoveniZadosti.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
  onSectionClick: PropTypes.func.isRequired,
};

export default LayoutObnoveniZadosti;
