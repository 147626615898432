import React, { useContext } from "react";
// import PropTypes from 'prop-types';
import LayoutMujUcet from "../Layouts/LayoutMujUcet.js";
import UserContext from "../UserContext.js";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { apiDocUrl } from "../appConfig.js";
import Styles from "./ProfilDarceDokumenty.module.scss";
import RenderMd from "../Components/RenderMd.js";

const ProfilDarceDokumenty = () => {
  const user = useContext(UserContext);
  const { t } = useTranslation();

  const documents = user && user.fullUser && user.fullUser.Documents;

  return (
    <LayoutMujUcet pageTitle={t("dokumenty-title")}>
      <section className={Styles.header}>
        <h1 className="title">{t("dokumenty-title")}</h1>
        <RenderMd>dokumenty-titleInfo</RenderMd>
      </section>

      {documents && documents.length > 0 ? (
        <section className={Styles.list}>
          <table className="table is-striped is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th>{t("dokument-datum")}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {documents.map((doc) => {
                if (doc.DocumentType === 2) {
                  const rok = doc.Name.split("_")[0];
                  const docLink = `${apiDocUrl}?id=${doc.Id}`;

                  return (
                    <tr key={`${doc.Id}`}>
                      <td>{dayjs(doc.Created).format(t("date-full-long"))}</td>
                      <td>{t("dokument-danovePotvrzeni", { rok })}</td>
                      <td>
                        <a
                          href={docLink}
                          className="button is-small is-rounded"
                        >
                          <span className="icon">
                            <i className="fa-regular fa-cloud-download" />
                          </span>
                          <span>{t("button-download")}</span>
                        </a>
                      </td>
                    </tr>
                  );
                }

                return null;
              })}
            </tbody>
          </table>
        </section>
      ) : (
        <div className="notification is-warning">
          {t("str_zadny_dokument_ke_stazeni")}
        </div>
      )}
    </LayoutMujUcet>
  );
};

// ProfilDarceDokumenty.propTypes = {}
// ProfilDarceDokumenty.defaultProps = {}

export default ProfilDarceDokumenty;
