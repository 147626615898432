import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const CountDown = ({ targetDate, format = "HH:mm:ss", callBack, diff = 30, style = {} }) => {
    const [timeLeft, setTimeLeft] = useState('');

    const formatTime = (difference) => {
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return format
            .replace("HH", hours.toString().padStart(2, '0'))
            .replace("mm", minutes.toString().padStart(2, '0'))
            .replace("ss", seconds.toString().padStart(2, '0'));
    };

    useEffect(() => {
        const interval = setInterval(() => {
            let endDate = new Date(targetDate);
            endDate.setMinutes(endDate.getMinutes() + diff);

            const now = new Date();
            const difference = endDate - now;

            if (difference <= 0) {
                callBack && callBack();
                clearInterval(interval);
                setTimeLeft('00:00:00');
                return;
            }

            setTimeLeft(formatTime(difference));
        }, 1000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetDate, format, callBack]);

    return <span style={style}>{timeLeft}</span>;
}

CountDown.propTypes = {
    targetDate: PropTypes.string.isRequired,
    diff: PropTypes.number,// number of minutes
    format: PropTypes.string,
    callBack: PropTypes.func,
    style: PropTypes.object
};

CountDown.defaultProps = {
    format: "HH:mm:ss",
    diff: 30,
    style: {}
};

export default CountDown;
