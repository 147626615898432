import React, { useContext } from "react";
//import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import UserContext from "../UserContext.js";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const DocumentNotification = () => {
  const user = useContext(UserContext);
  const { t } = useTranslation();

  const documents = user && user.fullUser && user.fullUser.Documents;
  const lastLogin = user?.fullUser?.PreviousLogin; // "2022-01-13T15:20:02.283"
  const lastDocument = documents
    ? documents?.reduce(
        (ret, item) =>
          !ret || dayjs(item.Created).isAfter(dayjs(ret)) ? item.Created : ret,
        null
      )
    : null;

  //console.log(lastLogin, lastDocument, user, documents);

  if (!lastDocument || !lastLogin) return <></>;
  if (dayjs(lastLogin).isAfter(dayjs(lastDocument))) return <></>;

  return (
    <Link to="/muj-ucet/dokumenty" className="clean-link">
      <div>
        <div className={`notification is-info`}>
          <span>{t("dokument-new-document")}</span>
        </div>
      </div>
    </Link>
  );
};

//DocumentNotification.propTypes = {};

export default DocumentNotification;
