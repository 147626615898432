import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getWebLink } from "../appConfig.js";
import LayoutClean from "../Layouts/LayoutClean.js";
import Loading from "../Components/LoadingIcon.js";

const PageLogout = () => {
  const { t, i18n } = useTranslation();
  const redirUrl = getWebLink(i18n.language);

  useEffect(() => {
    if (window) {
      window.location.href = redirUrl;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutClean>
      <div style={{ textAlign: "center", marginTop: "5em" }}>
        <div style={{ marginBottom: "2em" }}>
          <Loading />
        </div>

        <div>{t("redirect-to-web")}</div>

        <div>
          <a href={redirUrl}>{redirUrl}</a>
        </div>
      </div>
    </LayoutClean>
  );
};

export default PageLogout;
