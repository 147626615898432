import React from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import styles from "./RssFeed.module.scss";
import Loading from "./Loading.js";
import DefaultImg from "../assets/favicon.png";

const RssFeed = ({ limit }) => {
  const { data } = useSWR("feed/web/");
  // console.log(data);

  if (!data) return <Loading />;

  return (
    <div data-rss-limit={limit}>
      <div className={styles.rssFeedWrap}>
        {data.map((item, itemIndex) => {
          if (itemIndex >= limit) return null;

          return (
            <div key={item.title} className={styles.feedItemWrap}>
              <a href={item.url} className={styles.feedItem}>
                <div className={styles.feedItemDetail}>
                  <div className={styles.image}>
                    <img
                      src={item.enclosureUrl ? item.enclosureUrl : DefaultImg}
                      alt={item.title}
                    />
                  </div>

                  <div className={styles.textblock}>
                    <div className={`title ${styles.title}`}>{item.title}</div>
                    <div className={styles.perex}>{item.content}</div>
                  </div>

                  <div className={styles.cta}>
                    <i className={`fa fa-chevron-right ${styles.ctaIcon}`} />
                  </div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RssFeed;

RssFeed.propTypes = {
  limit: PropTypes.number,
};
RssFeed.defaultProps = {
  limit: 10,
};
